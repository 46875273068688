import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ProductService} from '../../services/product.service';
import * as Models from '../../models/models';
import {finalize, filter, tap} from 'rxjs/operators';
import {isNonNull} from '../../helpers/is-non-null.helper';
import {ClearStoreService} from '../services/clear-store.service';
import {Store} from './store';

@Injectable()
export class ProductStore extends Store {

  private productSource: BehaviorSubject<Models.ILoanProduct> = new BehaviorSubject<Models.ILoanProduct>(null);
  private isFirstLoading: boolean = false;

  constructor(private productService: ProductService,
              public clearStoreService: ClearStoreService) {
    super(clearStoreService);
  }

  public get(): Observable<Models.ILoanProduct> {
    if (!this.productSource.value && !this.isFirstLoading) {
      this.loadFirstTime();
    }
    return this.productSource.asObservable().pipe(
      filter(isNonNull) // убираем начальное значение в productSource (null)
    );
  }

  public set(product: Models.ILoanProduct) {
    this.productSource.next(product);
  }

  public getStorageSubject(): BehaviorSubject<any> {
    return this.productSource;
  }

  private loadFirstTime() {
    this.isFirstLoading = true;
    this.productService.getActualProduct().pipe(
      tap(product => this.set(product)),
      finalize(() => this.isFirstLoading = false)
    ).subscribe();
  }

}
