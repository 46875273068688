import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {IFormFieldError} from '../../../models/models';

export enum ToastType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info'
}

export interface ToasterComponentData {
  type: ToastType;
  title: string;
  content: {
    message?: string,
    loggerCode?: string,
    errors?: IFormFieldError[]
  };
  component?: any;
  componentInputs?: any;
}

class ToasterComponent {
  type: ToastType;
  title: string;
  content: {message?: string};
}

@Component({
  selector: 'app-toaster-widget',
  templateUrl: './toaster-widget.component.html',
  styleUrls: ['./toaster-widget.component.sass'],
})
export class ToasterWidgetComponent {

  constructor(
    private snackBarRef: MatSnackBarRef<ToasterComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: ToasterComponentData) {
  }

  public get classes(): string {
    return `toaster ${this.data.type}`;
  }

  public close(): void {
    this.snackBarRef.dismiss();
  }
}
