import {Component, OnInit} from '@angular/core';
import * as Models from '../../../../../../../libs/models/models';
import {LanguageStore} from '@libs/shared/storage/language.store';
import {ContactConfig} from "../../../configs/contact-config";
import {Router} from "@angular/router";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {
  public year: number = new Date().getFullYear();

  public phone: Models.IContact = ContactConfig.phones[0];
  public lang: string;

  constructor(private langStore: LanguageStore,
              public router: Router) {
  }

  public ngOnInit(): void {
    this.langStore.getLang().subscribe(lang => {
      this.lang = lang;
    });
  }

  public info = {
    ua: `<ul class="list">
                 <li class="list__item">
                    <a class="list__link" target="_blank" href="https://www.zecredit.com.ua/about-us">
                        Про нас
                    </a>
                 </li>
                 <li class="list__item">
                    <a class="list__link" target="_blank" href="https://www.zecredit.com.ua/how-works">
                      Як користуватися
                    </a>
                 </li>
                 <li class="list__item">
                    <a class="list__link" target="_blank" href="https://www.zecredit.com.ua/questions">
                       Довідка
                    </a>
                 </li>
                 <li class="list__item">
                    <a class="list__link" target="_blank" href="https://www.zecredit.com.ua/contacts">
                        Контакти
                    </a>
                 </li>
             </ul>`,
    ru: `<ul class="list">
                 <li class="list__item">
                    <a class="list__link" target="_blank" href="https://www.zecredit.com.ua/about-us">
                        О нас
                    </a>
                 </li>
                 <li class="list__item">
                    <a class="list__link" target="_blank" href="https://www.zecredit.com.ua/how-works">
                      Как пользоваться
                    </a>
                 </li>
                 <li class="list__item">
                    <a class="list__link" target="_blank" href="https://www.zecredit.com.ua/questions">
                       Справка
                    </a>
                 </li>
                 <li class="list__item">
                    <a class="list__link" target="_blank" href="https://www.zecredit.com.ua/contacts">
                        Контакты
                    </a>
                 </li>
            </ul>`
  };

}
