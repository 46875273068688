import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as Config from '../../../../configs/general-config';
import {filter, switchMap, takeUntil, tap} from 'rxjs/internal/operators';
import {UnsubscribeService} from '../../../../shared/services/unsubscribe.service';
import {VerificationActionType} from '../../../../enums/verification-action-type.enum';
import {VerificationType} from '../../../../enums/verification-type.enum';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {ClientStore} from '../../../../shared/storage/client.store';
import {TokenResponse} from '../../../../models/token-response.model';
import {ClientModel} from '../../../../models/client.model';
import {UserVerificationService} from '../../../../services/user-verification.service';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {MaskApplierService} from 'ngx-mask';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GroupType} from '../../../../types/group.type';
import {CountdownContainerComponent} from '../../../../shared/components/countdown-container/countdown-container.component';

@Component({
  selector: 'app-auth-auto-confirm',
  templateUrl: './auth-auto-confirm.component.html',
  styleUrls: ['./auth-auto-confirm.component.sass'],
  providers: [UnsubscribeService],
})
export class AuthAutoConfirmComponent implements OnInit {
  @Input()
  public group: GroupType;

  @Input()
  public phone: string;

  @Input()
  public withCaptcha: boolean;

  @Input()
  public showPhone: boolean = true;

  @Output()
  public readonlytimeGone = new EventEmitter<boolean>();

  @Output()
  public readonly verifyWayEvent = new EventEmitter<VerificationType>();

  @Output()
  public readonly editPhoneEvent: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Output()
  public readonly showPhoneEditorEvent: EventEmitter<void> =
    new EventEmitter<void>();

  @Output()
  public readonly timeGoneEvent: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Output()
  public readonly redirectClientEvent: EventEmitter<ClientModel> =
    new EventEmitter<ClientModel>();

  public form: FormGroup;
  public path = Config.StaticDataConfig.redirectPathByClientStage;
  public action = VerificationActionType.LOGIN;
  public client: ClientModel;
  public verificationType: VerificationType = VerificationType.PHONE;
  public breakpointTimeOut: boolean = false;
  public timeOut: boolean = false;
  private redirectDone: boolean = false;
  public initialState: {
    count: number;
    breakpoint?: number;
    diameter?: number;
    strokeWidth?: number;
  } = {
    count: 60,
    breakpoint: 10,
    diameter: 140,
    strokeWidth: 10,
  };

  @ViewChild(CountdownContainerComponent)
  private counterContainer: CountdownContainerComponent;

  constructor(
    private formBuilder: FormBuilder,
    public verificationService: UserVerificationService,
    public destroyStream$: UnsubscribeService,
    public authService: AuthenticationService,
    public router: Router,
    public clientStore: ClientStore,
    public recaptchaV3Service: ReCaptchaV3Service,
    public route: ActivatedRoute,
    public maskService: MaskApplierService,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.initForm();
    this.verificationClientIvr();
  }

  public verificationClientPhoneCall(): void {
    this.verificationService
      .getServerSentEvent(this.phone, this.action, VerificationType.IVR)
      .pipe(
        switchMap((token) =>
          this.authService.loginUserByVerificationToken(token, this.phone)
        ),
        tap((tokenResp: TokenResponse) => this.authService.setToken(tokenResp)),
        switchMap(() => this.clientStore.getClient()),
        takeUntil(this.destroyStream$)
      )
      .subscribe(
        (client: ClientModel) => {
          this.client = client;
          this.redirectDone = true;
          this.redirectClientEvent.emit(client);
        },
        (error) => console.error(error)
      );
  }

  public editPhone(): void {
    this.editPhoneEvent.emit(true);
  }

  public verificationClientPhoneCallWithCaptcha(): void {
    this.recaptchaV3Service
      .execute('login')
      .pipe(
        switchMap((reCaptchaToken: string) =>
          this.verificationService.getServerSentEventWithCaptcha(
            this.phone,
            VerificationActionType.LOGIN,
            reCaptchaToken,
            VerificationType.IVR
          )
        ),
        switchMap((token) =>
          this.authService.loginUserByVerificationToken(token, this.phone)
        ),
        tap((tokenResp: TokenResponse) => this.authService.setToken(tokenResp)),
        switchMap(() => this.clientStore.getClient()),
        takeUntil(this.destroyStream$)
      )
      .subscribe(
        (client: ClientModel) => {
          this.client = client;
          this.redirectClientEvent.emit(client);
        },
        (error) => console.error(error)
      );
  }

  public verificationClientIvr(): void {
    this.recaptchaV3Service
      .execute('login')
      .pipe(
        switchMap((reCaptchaToken: string) =>
          this.verificationService.getIvrCall(
            this.phone,
            VerificationActionType.LOGIN,
            reCaptchaToken,
            VerificationType.IVR
          )
        ),
        switchMap((token) =>
          this.verificationService.getServerSentEventIvr(token)
        ),
        switchMap((token) =>
          this.authService.loginUserByVerificationToken(token, this.phone)
        ),
        tap((tokenResp: TokenResponse) => this.authService.setToken(tokenResp)),
        switchMap(() => this.clientStore.getClient()),
        takeUntil(this.destroyStream$)
      )
      .subscribe(
        (client: ClientModel) => {
          this.client = client;
          this.redirectDone = true;
          this.redirectClientEvent.emit(client);
        },
        (error) => console.error(error)
      );
  }

  public breakpointOutHandler($event: boolean): void {
    this.breakpointTimeOut = $event;
  }

  public timeOutEventHandler($event: boolean): void {
    this.timeOut = $event;
  }

  public get phoneNumber(): string {
    const masks: {[key: string]: string} = {
      AVANS: '+00 000 000 00 00',
      STARFIN: '+00(000) 000 00 00',
      SUNCREDIT: '+00(000) 000 00 00',
      BASE: '00000 000 0000',
    };
    return this.maskService.applyMask(this.phone.toString(), masks[this.group]);
  }

  public wayVerificationHandler(type): void {
    // do not change order
    this.verificationType = type;
    this.verifyWayEvent.emit(type);
    this.counterContainer.restartTimer();
    this.timeOut = false;
    this.breakpointTimeOut = false;
    this.changeDetector.detectChanges();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      agreement: this.formBuilder.group({
        agree: [true, Validators.requiredTrue],
        marketingConsent: [true],
      }),
    });

    this.form.controls.agreement
      .get('agree')
      .valueChanges.pipe(filter(Boolean), takeUntil(this.destroyStream$))
      .subscribe((value: boolean) => {
        if (this.redirectDone) {
          this.redirectClientEvent.emit(this.client);
        }
      });
  }
}
