import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {AuthLoginComponent} from './auth-login.component';
import {AuthRepeatModule} from '../auth-repeat/auth-repeat.module';
import {AuthPhoneModule} from '../auth-phone/auth-phone.module';
import {AuthConfirmModule} from '../auth-confirm/auth-confirm.module';
import {AuthAutoConfirmModule} from '../auth-auto-confirm/auth-auto-confirm.module';
import {AuthAgreementModule} from '../auth-agreement/auth-agreement.module';
import {AuthPhoneEditModalModule} from '../auth-phone-edit-modal/auth-phone-edit-modal.module';
import {MatDialogModule} from '@angular/material/dialog';


@NgModule({
  declarations: [AuthLoginComponent],
  imports: [
    CommonModule,
    TranslateModule,
    AuthRepeatModule,
    AuthPhoneModule,
    AuthConfirmModule,
    AuthAutoConfirmModule,
    AuthAgreementModule,
    MatDialogModule,
    AuthPhoneEditModalModule
  ],
  exports: [AuthLoginComponent]
})
export class AuthLoginModule {
}
