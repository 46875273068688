import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GroupType} from '../types/group.type';

export interface IEnvConfig {
  production: boolean;
  name: string;
  group: GroupType;
  hostUrl: string;
  isTestPaymentEnabled: boolean;
  isInnValidationEnabled: boolean;
  recaptchaKEY_CODE: string;
  cookieDomain: string;
  WEBSITE_URL: string;
  LOGO: string;
  LOGO_WHITE: string;
  CONTACT: string;
  partnerLink: string;
  otherCompanyRedirect_cab: string;
  otherCompanyRedirect_form: string;
}

@Injectable({
  providedIn: 'root',
})
export class CustomEnvironmentService {
  private _environment: IEnvConfig;

  get environment() {
    return this._environment;
  }

  constructor(
    @Inject('environment') appEnvironment: IEnvConfig,
    private _http: HttpClient
  ) {
    this._environment = appEnvironment;
  }
}
