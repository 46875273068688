import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NgxMaskModule} from 'ngx-mask';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSliderModule} from '@angular/material/slider';
import {HttpClient} from '@angular/common/http';
import {ModalModule} from 'ngx-bootstrap/modal';
import {FooterComponent} from './components/footer/footer.component';
import {TranslateLoaderFactory} from '@libs/helpers/translate-loader-factory';

@NgModule({
  declarations: [FooterComponent,],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatSliderModule,
    MatProgressBarModule,
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgxMaskModule,
    TranslateModule,
    MatSliderModule,
    MatProgressBarModule,
    FooterComponent,
  ],
})
export class LocalSharedModule {
}
