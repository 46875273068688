import * as Models from '../models/models';

export class LanguageConfig {

    static ua: string = 'ua';
    static ru: string = 'ru';

    static readonly languages: Models.ILanguage = {
        ua: {
            lang: 'ua',
            ogLocale: 'uk_UA'
        },
        ru: {
            lang: 'ru',
            ogLocale: 'ru_RU'
        }
    };

    static momentLang: Models.IMomentLang = {
        ua: {
            param: 'uk'
        },
        ru: {
            param: 'ru'
        }
    };
}
