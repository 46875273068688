import {Injectable} from '@angular/core';
import {CustomEnvironmentService} from './custom-environment.service';

@Injectable()
export class UrlService {
  constructor(private _customEnvironmentService: CustomEnvironmentService) {}

  public host = this._customEnvironmentService.environment.hostUrl;

  loginUrl = `${this.host}/auth`;
  registrationUrl = `${this.host}/auth/registration`;
  verificationUrl = `${this.host}/verifications`;
  verificationPhoneCallUrl = `${this.host}/verifications/ivr`;
  ivrCallUrl = `${this.host}/verifications/ivr-call`;
  ivrSubscribeUrl = `${this.host}/verifications/ivr-subscribe`;
  ivrUnsubscribeUrl = `${this.host}/verifications/ivr-unsubscribe`;
  testVerificationPhoneCallUrl = `${this.host}/test/verifications/ivr`;
  confirmationsUrl = `${this.host}/verifications/confirmations`;
  cardUrl = `${this.host}/me/payment-cards`;
  loanUrl = `${this.host}/me/loans`;
  otherCompanyredirectUrl = `${this.host}/me/other-company-redirect`;
  profileUrl = `${this.host}/me`;
  hostUrl = `${this.host}`;
  identOptionsUrl = `${this.host}/me/ident-options`;
  applicationUrl = `${this.host}/me/loans`;
  uploadDocumentUrl = `${this.host}/me/client-uploaded-documents`;
  testApplicationUrl = `${this.host}/test/me/loans`;
  loanContactUrl = `${this.host}/loans`;
  scheduleUrl = `${this.host}/loans`;
  loanDocumentsUrl = `${this.host}/loan-documents`;
  loanProductsUrl = `${this.host}/me/loan-products`;
  refreshUrl = `${this.loginUrl}/refresh`;
  logoutUrl = `${this.loginUrl}/logout`;
  scannerUrl = `${this.host}/me/photos`;
  selfieUrl = `${this.host}/me/ubkiid`;
  promoCampaign = `${this.host}/promo-campaigns`;
  financeNumberUrl = `${this.host}/me/financial-phones`;
  financeNumberTestUrl = `${this.host}/test/me/financial-phones`;
  bankInfoUrl = `${this.host}/me/bankid/redirect-info`;
  bankInfoPlatonUrl = `${this.host}/me/bankid/platon/url-params`;
  bankIdCallback = `${this.host}/me/bankid/customer-info`;
  bankIdTestCallback = `${this.host}/test/me/bankid/customer-info`;
  serviceData = `${this.host}/me?service-data`;
  freepayment = `${this.host}/authless/payments`;
  closedDocuments = `${this.host}/authless/closed-documents`;
  closedDocumentsDownload = `${this.host}/authless/closed-documents/download`;
  loggerUrl = `https://logger.zecredit.ua/logger/log`;
}
