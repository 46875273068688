<div [ngClass]="dialogClasses">
  <span *ngIf="showCloseBtn"
        class="dialog-close-btn"
        tabindex="-1"
        mat-icon-button
        [mat-dialog-close]="true"
        (click)="closeDialog()">
  </span>

  <div class="dialog-content">
    <ng-content select="[dialogContent]"></ng-content>
  </div>

  <div class="dialog-actions">
    <ng-content select="[dialogActions]"></ng-content>
  </div>
</div>
