export enum VerificationActionType {

    CONTRACT_SIGN = 'CONTRACT_SIGN',
    PROLONGATION_SIGN = 'PROLONGATION_SIGN',
    REGISTRATION = 'REGISTRATION',
    PHONE_VERIFICATION = 'PHONE_VERIFICATION',
    LOGIN = 'LOGIN',
    FREEZING_SIGN = 'FREEZING_SIGN',
    RESTRUCTURING_SIGN = 'RESTRUCTURING_SIGN',

}
