<ng-container [ngSwitch]="group">

  <div *ngSwitchCase="'AVANS'" class="resend-sms  d-flx flex-column align-items-center">
    <div class="resend-sms-body">
      <div class="resend-sms-content" *ngIf="showSignMessage">
        <p class="resend-sms__text text-center">{{"RESEND.SIGN_MESSAGE" | translate }}</p>
      </div>
      <div class="resend-sms-content" *ngIf="showAuthMessage">
        <p class="resend-sms__text text-center"
           *ngIf="!timeOut && showPhone"
           [innerHtml]="'AUTH.SHORT_TEXT' | translate"></p>
        <p class="resend-sms__text text-center"
           *ngIf="timeOut || !showPhone">{{ 'CONFIRM.PHONE' | translate}}</p>
      </div>
    </div>
    <div class="resend-sms-footer w-auto text-center d-md-flex"
         [ngClass]="showPhone ? 'justify-content-md-between' : 'justify-content-md-center'">
      <ng-container *ngIf="showPhone">
        <app-button (click)="resend(verificationType.PHONE)"
                    size="w100"
                    color="not-bg"
                    [label]="'button.repeatPhone' | translate">
        </app-button>
        <p class="text-center">{{'bankId.or' | translate}}</p>
      </ng-container>
      <app-button (click)="resend(verificationType.SMS)"
                  size="w100"
                  color="not-bg"
                  [label]="'button.repeatSms' | translate">
      </app-button>
    </div>
  </div>

  <div *ngSwitchCase="'STARFIN'" class="resend-sms d-flex flex-column align-items-center">
    <div class="resend-sms-body">
      <div class="resend-sms-content" *ngIf="showSignMessage">
        <p class="resend-sms__text text-center">{{"RESEND.SIGN_MESSAGE" | translate }}</p>
      </div>
      <div class="resend-sms-content" *ngIf="showAuthMessage">
        <p class="resend-sms__text text-center"
           *ngIf="!timeOut && showPhone"
           [innerHtml]="'AUTH.SHORT_TEXT' | translate"></p>
        <p class="resend-sms__text text-center"
           *ngIf="timeOut || !showPhone">{{ 'CONFIRM.PHONE' | translate}}</p>
      </div>
    </div>
    <div class="resend-sms-footer w-100 text-center d-md-flex"
         [ngClass]="showPhone ? 'justify-content-md-between' : 'justify-content-md-center'">
      <ng-container *ngIf="showPhone">
        <app-button (click)="resend(verificationType.PHONE)"
                    size="large"
                    type="button"
                    color="inline"
                    classes="fs-15 fw-bold mb-3 mb-md-0"
                    [label]="'BUTTON.REPEAT_PHONE' | translate">
        </app-button>
      </ng-container>
      <app-button (click)="resend(verificationType.SMS)"
                  size="large"
                  type="button"
                  color="primary"
                  classes="fs-15 fw-bold"
                  [label]="'BUTTON.REPEAT_SMS' | translate">
      </app-button>
    </div>
  </div>

  <div *ngSwitchCase="'SUNCREDIT'" class="resend-sms d-flex flex-column align-items-center">
    <div class="resend-sms-body">
      <div class="resend-sms-content" *ngIf="showSignMessage">
        <p class="resend-sms__text text-center">{{"RESEND.SIGN_MESSAGE" | translate }}</p>
      </div>
      <div class="resend-sms-content" *ngIf="showAuthMessage">
        <p class="resend-sms__text text-center"
           *ngIf="!timeOut && showPhone"
           [innerHtml]="'AUTH.SHORT_TEXT' | translate"></p>
        <p class="resend-sms__text text-center"
           *ngIf="timeOut || !showPhone">{{ 'CONFIRM.PHONE' | translate}}</p>
      </div>
    </div>
    <div class="resend-sms-footer text-center d-md-flex"
         [ngClass]="showPhone ? 'justify-content-md-between' : 'justify-content-md-center'">
      <ng-container *ngIf="showPhone">
        <app-button (click)="resend(verificationType.PHONE)"
                    size="large"
                    type="button"
                    color="limpid"
                    [img]="{url: './assets/images/icons/icon-repeat-call.svg',width: '24px',height: '26px'}"
                    classes="fs-18 fw-medium mb-3 mb-md-0"
                    [label]="'BUTTON.REPEAT_PHONE' | translate">
        </app-button>
      </ng-container>
      <app-button (click)="resend(verificationType.SMS)"
                  size="large"
                  type="button"
                  color="limpid"
                  [img]="{url: './assets/images/icons/icon-repeat-sms.svg',width: '24px',height: '24px'}"
                  classes="fs-18 fw-medium"
                  [label]="'BUTTON.REPEAT_SMS' | translate">
      </app-button>
    </div>
  </div>

  <div class="resend-sms d-flex flex-column align-items-center" *ngSwitchDefault>
    <div class="resend-sms-body">
      <div class="resend-sms-content" *ngIf="showSignMessage">
        <p class="resend-sms__text text-center">{{"RESEND.SIGN_MESSAGE" | translate }}</p>
      </div>
      <div class="resend-sms-content" *ngIf="showAuthMessage">
        <p class="resend-sms__text text-center"
           *ngIf="!timeOut && showPhone"
           [innerHtml]="'AUTH.SHORT_TEXT' | translate"></p>
        <p class="resend-sms__text text-center"
           *ngIf="timeOut || !showPhone">{{ 'CONFIRM.PHONE' | translate}}</p>
      </div>
    </div>
    <div class="resend-sms-footer text-center d-md-flex"
         [ngClass]="showPhone ? 'justify-content-md-between' : 'justify-content-md-center'">
      <ng-container *ngIf="showPhone">
        <app-button (click)="resend(verificationType.PHONE)"
                    size="large"
                    color="not-bg"
                    [label]="'button.repeatPhone' | translate">
        </app-button>
        <p class="text-center">{{'bankId.or' | translate}}</p>
      </ng-container>
      <app-button (click)="resend(verificationType.SMS)"
                  size="large"
                  color="not-bg"
                  [label]="'button.repeatSms' | translate">
      </app-button>
    </div>
  </div>

</ng-container>

