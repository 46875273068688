<section class="auth-agreement" [formGroup]="form">
  <div class="auth-agreement-content">
    <div class="mb-2">
      <app-checkbox formControlName="agree"
                    [checkImg]="{url: './assets/images/icons/check.svg',width: imgSize, height: imgSize}"
                    [uncheckImg]="{url: './assets/images/icons/uncheck.svg',width: imgSize, height: imgSize}"
                    [errorImg]="{url: './assets/images/icons/uncheckError.svg',width: imgSize, height: imgSize}"
                    [template]="accept"
                    [labelClasses]="labelClasses"
      ></app-checkbox>
    </div>
    <div>
      <app-checkbox formControlName="marketingConsent"
                    [checkImg]="{url: './assets/images/icons/check.svg',width: imgSize, height: imgSize} "
                    [uncheckImg]="{url: './assets/images/icons/uncheck.svg',width: imgSize, height: imgSize} "
                    [description]="'AGREEMENT.INFO' | translate"
                    [labelClasses]="labelClasses"
      ></app-checkbox>
    </div>
  </div>
</section>
