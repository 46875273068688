import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { defineLocale, ruLocale } from 'ngx-bootstrap/chronos';
import { takeUntil } from 'rxjs/operators';
import * as Models from '../../models/models';
import * as Config from '../../configs/general-config';
import {UnsubscribeService} from "../../shared/services/unsubscribe.service";
import {LanguageStore} from "../../shared/storage/language.store";

@Component({
    selector: 'app-system',
    templateUrl: './system.component.html',
    styleUrls: ['./system.component.sass'],
    providers: [UnsubscribeService]
})
export class SystemComponent implements OnInit {

    public locales: Models.IDatepicker = Config.DatepickerConfig.DatepickerLang;

    constructor(private langStore: LanguageStore,
                private translate: TranslateService,
                private destroyStream$: UnsubscribeService) {
        defineLocale('ru', ruLocale);
    }

    public ngOnInit(): void {
        this.loadLanguage();
        defineLocale(this.locales[Config.LanguageConfig.ua]['lang'], this.locales[Config.LanguageConfig.ua]['locale']);
    }

    public loadLanguage(): void {
        this.langStore.getLang().pipe(
            takeUntil(this.destroyStream$)
        ).subscribe(lang => {
            this.translate.use(lang);
        });
    }

}
