import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DataForm} from '../../../../shared/static-data/Data-form.static';
import {VerificationType} from '../../../../enums/verification-type.enum';
import {UserVerificationService} from '../../../../services/user-verification.service';
import {AffiliateService} from '../../../../shared/services/affiliate.service';
import {ActivatedRoute} from '@angular/router';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {UnsubscribeService} from '../../../../shared/services/unsubscribe.service';
import {VerificationActionType} from '../../../../enums/verification-action-type.enum';
import {switchMap, takeUntil} from 'rxjs/operators';
import {environment} from '../../../../../apps/zecredit/src/environments/environment';
import * as Models from '../../../../models/models';
import * as Config from '../../../../configs/general-config';
import {GroupType} from '../../../../types/group.type';

@Component({
  selector: 'app-auth-phone',
  templateUrl: './auth-phone.component.html',
  styleUrls: ['./auth-phone.component.sass'],
})
export class AuthPhoneComponent implements OnInit {
  @Input()
  public production: boolean;

  @Input()
  public group: GroupType;

  @Output()
  public readonly emitData: EventEmitter<any> = new EventEmitter();

  @Output()
  public readonly emitPhone: EventEmitter<string> = new EventEmitter<string>();

  public verificationType: VerificationType = VerificationType.PHONE;
  public loginForm: FormGroup;
  public submitted: boolean = false;
  public isAuthConfirm: boolean = false;
  public isAuthCallConfirm: boolean = false;
  public isResend: boolean = false;
  public withCaptcha: boolean = false;
  public phone: string;
  public isDisabled: boolean = false;
  public rikki: boolean = false;
  public show: boolean = false;
  public doubleCountryRegular =
    Config.RegularExpression.ERROR_MOBILE_INPUT_DOUBLE_COUNTRY;
  public doubleZeroRegular =
    Config.RegularExpression.ERROR_MOBILE_INPUT_DOUBLE_ZERO;
  public defaultMobilePhone = 380;
  @ViewChild('phoneInput') phoneInput: any;

  constructor(
    public verificationService: UserVerificationService,
    public affiliateService: AffiliateService,
    public activatedRoute: ActivatedRoute,
    public recaptchaV3Service: ReCaptchaV3Service,
    public destroyStream$: UnsubscribeService,
    public route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.activateForm();
    this.setQueryParams();
    this.route.queryParams.subscribe((params) => {
      this.show = params.show;
      this.rikki = params.rikki;
    });
  }

  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent): void {
    const input = this.phoneInput.nativeElement;
    let currentValue = input.value;
    currentValue = event.clipboardData.getData('text');
    switch (currentValue.length) {
      case 12:
        this.loginForm.get('phone').setValue(currentValue);
        break;
      case 11:
        this.loginForm.get('phone').setValue(3 + currentValue);
        break;
      case 10:
        this.loginForm.get('phone').setValue(38 + currentValue);
        break;
      case 9:
        this.loginForm.get('phone').setValue(380 + currentValue);
        break;
    }
  }

  public preventDefaultValueDeletion(event: any): boolean {
    if (
      (event.target.value.length === 4 ||
        this.loginForm.get('phone').value === this.defaultMobilePhone) &&
      (event.code === 'Backspace' || event.code === 'Delete')
    ) {
      return false;
    }
    this.inputPhoneNumber(event);
    return true;
  }

  public inputPhoneNumber(event: any): void {
    if (
      this.doubleCountryRegular.test(
        String(this.loginForm.get('phone').value)
      ) ||
      this.doubleZeroRegular.test(String(this.loginForm.get('phone').value))
    ) {
      this.loginForm.get('phone').setValue(380);
    }
  }

  public activateForm(): void {
    this.loginForm = DataForm.getLoginFormGroup();
  }

  public setQueryParams() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.affiliateService.setQueryParamsFromUrl(params);
    });
  }

  public setValuePhone(element): void {
    const val = element.value;
    element.value = '';
    element.value = val;
  }

  public editPhone(event: boolean): void {
    if (event) {
      this.isResend = false;
      this.isAuthConfirm = false;
      this.isAuthCallConfirm = false;
    }
  }

  get f(): Models.IFormControls {
    return this.loginForm.controls;
  }

  public verificationClient(): void {
    this.verificationService
      .sendSmsCode(
        this.phone,
        VerificationActionType.LOGIN,
        false,
        this.verificationType
      )
      .subscribe(
        () => {
          this.isDisabled = false;
          this.isResend = false;
          this.isAuthConfirm = true;
          this.emitDataToParent();
        },
        () => (this.isDisabled = false)
      );
  }

  // public verificationClientReturn(): Observable<any> {
  //   return this.verificationService.
  //   sendSmsCode(this.phone, VerificationActionType.LOGIN,
  //     false, this.verificationType);
  // }

  public verificationClientPhoneCall(): void {
    this.isAuthCallConfirm = true;
    this.isAuthConfirm = false;
    this.isResend = false;
    this.emitDataToParent();
  }

  public verificationClientPhoneCallWithCaptcha(): void {
    this.withCaptcha = true;
    this.isAuthCallConfirm = true;
    this.isAuthConfirm = false;
    this.isResend = false;
    this.emitDataToParent();
  }

  public verificationClientWithCaptcha(): void {
    this.recaptchaV3Service
      .execute('login')
      .pipe(
        switchMap((reCaptchaToken) =>
          this.verificationService.sendSmsCodeWithCaptcha(
            this.phone,
            VerificationActionType.LOGIN,
            reCaptchaToken,
            false,
            this.verificationType
          )
        ),
        takeUntil(this.destroyStream$)
      )
      .subscribe(
        () => {
          this.isDisabled = false;
          this.isAuthConfirm = true;
          this.isResend = false;
          this.emitDataToParent();
        },
        () => (this.isDisabled = false)
      );
  }

  public timeGone(): void {
    if (this.isAuthCallConfirm) {
      this.isResend = true;
      this.isAuthCallConfirm = false;
      this.isAuthConfirm = false;
    }
    if (this.isAuthConfirm) {
      this.isResend = false;
      this.isAuthConfirm = true;
      this.isAuthCallConfirm = false;
    }
    this.emitDataToParent();
  }

  public chooseWayRepeat(type): void {
    this.verificationType = type;
    this.isResend = false;
    this.isAuthCallConfirm = false;
    this.isAuthConfirm = true;

    environment.recaptchaKEY_CODE
      ? this.verificationClientWithCaptcha()
      : this.verificationClient();
  }

  public emitDataToParent(): void {
    this.emitData.emit({
      isAuthCallConfirm: this.isAuthCallConfirm,
      isAuthConfirm: this.isAuthConfirm,
      isResend: this.isResend,
      withCaptcha: this.withCaptcha,
    });
    this.emitPhone.emit(this.phone);
  }

  public onSubmitRikki(): void {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.isDisabled = true;
    this.phone = this.loginForm.value.phone;
    sessionStorage.setItem('phone', this.phone);
    environment.recaptchaKEY_CODE
      ? this.verificationClientPhoneCallWithCaptcha()
      : this.verificationClientPhoneCall();
  }

  public onSubmit(): void {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.isDisabled = true;
    this.phone = this.loginForm.value.phone;
    sessionStorage.setItem('phone', this.phone);
    environment.recaptchaKEY_CODE
      ? this.verificationClientWithCaptcha()
      : this.verificationClient();
  }
}
