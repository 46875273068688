import {NgModule} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {CounterWidgetComponent} from "./counter-widget.component";

@NgModule({
  declarations: [CounterWidgetComponent],
  imports: [TranslateModule, CommonModule, MatProgressSpinnerModule],
  exports: [CounterWidgetComponent]
})
export class CounterWidgetModule{}
