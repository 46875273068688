<ng-container  [ngSwitch]="group">

  <ng-container *ngSwitchCase="'AVANS'">
    <section class="auth-phone auth-phone-avans">
      <h2 class="auth-phone-title text-center fw-bold">{{'AUTH.TITLE' | translate}}</h2>
      <form [formGroup]='loginForm' class="form">
        <div class="form-body">
          <div class="form__row">
            <label for="phone" class="form__label text-center" [innerHTML]="'AUTH.SUBTITLE' | translate"></label>
            <div class="form__field">
              <input formControlName="phone" id="phone" class="form__input input"
                     [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                     placeholder="380" mask="00(000)000-00-00"
                     [minLength]="18"
                     type="tel"
                     #phoneInput
                     autocomplete="on"
                     (keydown)="preventDefaultValueDeletion($event)"
                     (keyup)="inputPhoneNumber($event.target)"
                     (click)="setValuePhone($event.target)">
            </div>
            <div class="form-error" *ngIf="submitted && f.phone.errors">
              <span *ngIf="f.phone.errors.required" translate>validation.required</span>
              <span *ngIf="f.phone.errors.pattern" translate>validation.phone</span>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column align-items-center">
          <app-button (click)="onSubmitRikki()"
                      [disabled]='isDisabled'
                      size="large"
                      color="secondary"
                      classes="fs-20"
                      [label]="'BUTTON.CONTINUE' | translate">
          </app-button>

          <app-button (click)='onSubmit()'
                      [type]="'submit'"
                      size="large"
                      color="pure"
                      [disabled]='isDisabled'
                      *ngIf='!production'
                      [label]="'4 останні цифри дев' | translate"
          ></app-button>
        </div>
      </form>
    </section>
  </ng-container>

  <ng-container *ngSwitchCase="'STARFIN'">
    <section class="auth-phone auth-phone-starfin">
      <h2 class="auth-phone-title text-center">{{'AUTH.TITLE' | translate}}</h2>
      <h2 class="auth-phone-subtitle text-center">{{'AUTH.SUBTITLE' | translate}}</h2>
      <div class="auth-phone-banks"></div>
      <form [formGroup]='loginForm' class="form">
        <div class="form-body">
          <div class="form__row">
            <div class="form__field">
              <input formControlName="phone" id="phone" class="form__input input"
                     [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                     placeholder="380" mask="00(000)000-00-00"
                     [minLength]="18"
                     type="tel"
                     #phoneInput
                     autocomplete="on"
                     (keydown)="preventDefaultValueDeletion($event)"
                     (keyup)="inputPhoneNumber($event.target)"
                     (click)="setValuePhone($event.target)">
            </div>
            <div class="form-error" *ngIf="submitted && f.phone.errors">
              <span *ngIf="f.phone.errors.required" translate>validation.required</span>
              <span *ngIf="f.phone.errors.pattern" translate>validation.phone</span>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column align-items-center">
          <app-button (click)="onSubmitRikki()"
                      [disabled]='isDisabled'
                      size="w100"
                      class="w-100"
                      color="secondary"
                      classes="fs-15 fw-bold"
                      [label]="'BUTTON.CONTINUE' | translate">
          </app-button>

          <app-button (click)='onSubmit()'
                      [type]="'submit'"
                      size="w100"
                      class="w-100"
                      color="pure"
                      [disabled]='isDisabled'
                      *ngIf='!production'
                      [label]="'4 останні цифри дев' | translate"
          ></app-button>
        </div>
      </form>
    </section>
  </ng-container>

  <ng-container *ngSwitchCase="'SUNCREDIT'">
    <section class="auth-phone auth-phone-suncredit">
      <h2 class="auth-phone-title text-center title-decor">{{'AUTH.TITLE' | translate}}</h2>
      <h2 class="auth-phone-subtitle text-center">{{'AUTH.SUBTITLE' | translate}}</h2>
      <div class="auth-phone-banks"></div>
      <form [formGroup]='loginForm' class="form">
        <div class="form-body">
          <div class="form__row">
            <div class="form__field">
              <input formControlName="phone" id="phone" class="form__input input"
                     [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                     placeholder="380" mask="00(000)000-00-00"
                     [minLength]="18"
                     type="tel"
                     #phoneInput
                     autocomplete="on"
                     (keydown)="preventDefaultValueDeletion($event)"
                     (keyup)="inputPhoneNumber($event.target)"
                     (click)="setValuePhone($event.target)">
            </div>
            <div class="form-error" *ngIf="submitted && f.phone.errors">
              <span *ngIf="f.phone.errors.required" translate>validation.required</span>
              <span *ngIf="f.phone.errors.pattern" translate>validation.phone</span>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column align-items-center">
          <app-button (click)="onSubmitRikki()"
                      [disabled]='isDisabled'
                      size="w100"
                      class="w-100"
                      color="primary"
                      classes="fs-18 fw-medium"
                      [label]="'BUTTON.CONTINUE' | translate">
          </app-button>

          <app-button (click)='onSubmit()'
                      [type]="'submit'"
                      size="w100"
                      class="w-100"
                      color="pure"
                      classes="fs-18 fw-medium"
                      [disabled]='isDisabled'
                      *ngIf='!production'
                      [label]="'4 останні цифри дев' | translate"
          ></app-button>
        </div>
      </form>
    </section>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <section class='login' *ngIf='!isAuthConfirm && !isResend && !isAuthCallConfirm'>
      <div class='auth-landscape'>
        <div class='auth-landscape__body'>
          <div class='auth-landscape-content'>
            <h2 class='auth-landscape__title'>{{'AUTH.TITLE'  | translate}}</h2>
          </div>
        </div>
      </div>
      <form [formGroup]='loginForm' class='form'>
        <div class='login-form'>
          <div class="form-body">
            <div class='form__row'>
              <div class='form__label form__label_required'>
                <label for='phone' [innerHTML]="'LOGIN.PHONE' | translate"></label>
              </div>
              <div class='form__field'>
                <input formControlName="phone" id="phone" class="form__input input"
                       [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                       placeholder="380" mask="00(000)000-00-00"
                       [minLength]="18"
                       type="tel"
                       #phoneInput
                       autocomplete="on"
                       (keydown)="preventDefaultValueDeletion($event)"
                       (keyup)="inputPhoneNumber($event.target)"
                       (click)="setValuePhone($event.target)">
              </div>
              <div class='form-error' *ngIf='submitted && f.phone.errors'>
                <span *ngIf='f.phone.errors.required' translate>validation.required</span>
                <span *ngIf='f.phone.errors.pattern' translate>validation.phone</span>
              </div>
            </div>
          </div>
          <div class='login-footer'>
            <button type='submit'
                    (click)='onSubmitRikki()'
                    [disabled]='isDisabled'
                    class='btn btn_large btn_auto btn-not-bg'>{{ 'button.next' | translate }}
            </button>
            <button type='submit'
                    *ngIf='!production'
                    (click)='onSubmit()'
                    [disabled]='isDisabled'
                    class='btn btn_large btn_auto btn-not-bg'>{{ 'button.next' | translate }} 4 останні цифри дев
            </button>
          </div>
        </div>
      </form>
    </section>
  </ng-container>

</ng-container>
