import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackComponent } from './callback/callback.component';
import { BankIdMainComponent } from './bank-id-main.component';
import {FormGuard} from "../../guards/form.guard";

const routes: Routes = [
    {
        path: '',
        component: BankIdMainComponent,
        canActivateChild: [FormGuard],
        children: [
            {
                path: 'callback',
                component: CallbackComponent,
                data: {
                    stage: ['ACCOUNT', 'UBKI_ID', 'CLIENT', 'CARD', 'APPLICATION', 'LOAN', 'FINISHED_LOAN']
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BankIdMainRoutingModule {
}

