import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as Models from '../models/models';
import { PlatonPrivat } from '../models/models';
import { UrlService } from './url.service';
import { map, switchMap } from 'rxjs/operators';
import { ClientModel } from '../models/client.model';
import { CustomEnvironmentService } from './custom-environment.service';
import { NamesEnum } from '../enums/names.enum';

@Injectable()
export class BankIdService {
  private forbiddenBanks = ['oshadbank'];
  public redirectToChoosenBank: Subject<string> = new Subject<string>();
  public bankIdHost: string;
  public group = this._customEnvironmentService.environment.group;

  constructor(
    private http: HttpClient,
    private urlService: UrlService,
    private _customEnvironmentService: CustomEnvironmentService
  ) {
    this.bankIdHost = this._customEnvironmentService.environment.production
      ? 'https://id.bank.gov.ua/'
      : 'https://testid.bank.gov.ua/';
    // if (this.nameProject === NamesEnum.AVANS) this.forbiddenBanks = [];
    this.redirectToChoosenBank
      .pipe(switchMap(bank => this.bankIdVerification(bank)))
      .subscribe((redirectLink: string) => window.open(redirectLink, '_self'));
  }

  public getBankList(): Observable<Models.IBank[]> {
    return this.http
      .get<Models.IBank[]>(`${this.bankIdHost}api/banks`)
      .pipe(
        map(banks =>
          banks.filter(bank => !this.forbiddenBanks.includes(bank.id))
        )
      );
  }

  public bankIdVerification(bankId: string): Observable<string> {
    return this.http.post<string>(this.urlService.bankInfoUrl, { bankId });
  }

  public bankIdPlatonGet(): Observable<PlatonPrivat[]> {
    return this.http.get<Models.PlatonPrivat[]>(
      this.urlService.bankInfoPlatonUrl
    );
  }

  public getBankIdTestData(): Observable<ClientModel> {
    return this.http.post<ClientModel>(this.urlService.bankIdTestCallback, {});
  }
}
