import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonModule} from '../button/button.module';
import {SelfieModeWidgetComponent} from './selfie-mode-widget.component';
import {CameraSelfieModule} from '../camera-selfie/camera-selfie.module';

@NgModule({
  declarations: [SelfieModeWidgetComponent],
  exports: [SelfieModeWidgetComponent],
  imports: [CommonModule, TranslateModule, ButtonModule, CameraSelfieModule],
})
export class SelfieModeWidgetModule {}
