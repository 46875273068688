import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { FormGroup, Validators } from '@angular/forms';
import { IControlWithAnyValue, IControlWithValidatorsFn } from '../models/models';

@Injectable({
    providedIn: 'root'
})
export class FormExecutorService {

    public fillForm(form: FormGroup, data): void {
        Object.keys(data).forEach(key => {
            if (form.controls[key]) {
                (moment(data[key],  'YYYY-MM-DD', true).isValid())
                    ? form.controls[key].setValue(moment(data[key]).format('DD/MM/YYYY'))
                    : form.controls[key].setValue(data[key]);
            }
        });
    }

    public updateValueAndValidityControls(form: FormGroup, ...controls: string[]): void {
        const customControls = controls.length !== 0 ? controls : Object.keys(form.controls);
        customControls.forEach(c => form.controls[c].updateValueAndValidity());
    }

    public clearValidatorsControls(form: FormGroup, ...controls: string[]): void {
        const customControls = controls.length !== 0 ? controls : Object.keys(form.controls);
        customControls.forEach(c => form.controls[c].clearValidators());
    }

    public setValidatorsControls(form: FormGroup, ...controlsWithFn: IControlWithValidatorsFn []): void {
        controlsWithFn.forEach(controlWithFn => {
            for (const controlKey in controlWithFn) {
                if (controlWithFn.hasOwnProperty(controlKey)) {
                    form.controls[controlKey].setValidators(
                        Validators.compose([...controlWithFn[controlKey]]));
                }
            }
        });
    }

    public setValuesForm(form: FormGroup, ...controls: IControlWithAnyValue[]): void {
        controls.forEach(controlWithValue => {
            for (const control in controlWithValue) {
                if (controlWithValue.hasOwnProperty(control)) {
                    form.controls[control].setValue(controlWithValue[control]);
                }
            }
        });
    }

}
