import { Regions } from '../enums/regions.enum';
import { RelationType } from '../enums/relation-type.enum';
import { Occupation } from '../enums/occupation.enum';
import { Education } from '../enums/education.enam';
import { OwnershipTypeEnum } from '../enums/ownership-type.enum';
import * as Models from '../models/models';
import { IncomeSourceEnum } from '../enums/income-source';
import { BusynessTypeEnum } from '../enums/busyness-type.enum';

export class SelectConfig {
    static regions: Array<Models.ISelectEnum> = [
        {value: Regions.VINNICKAYA, name: 'VINNICKAYA'},
        {value: Regions.VOLYNSKAYA, name: 'VOLYNSKAYA'},
        {value: Regions.DNEPROPETROVSKAYA, name: 'DNEPROPETROVSKAYA'},
        {value: Regions.DONETSKAYA, name: 'DONETSKAYA'},
        {value: Regions.ZHITOMIRSKAYA, name: 'ZHITOMIRSKAYA'},
        {value: Regions.ZAKARPATSKAYA, name: 'ZAKARPATSKAYA'},
        {value: Regions.ZAPOROZHSKAYA, name: 'ZAPOROZHSKAYA'},
        {value: Regions.IVANO_FRANKOVSKAYA, name: 'IVANO_FRANKOVSKAYA'},
        {value: Regions.KIEVSKAYA, name: 'KIEVSKAYA'},
        {value: Regions.KIROVOGRADSKAYA, name: 'KIROVOGRADSKAYA'},
        {value: Regions.LUGANSKAYA, name: 'LUGANSKAYA'},
        {value: Regions.LVOVSKAYA, name: 'LVOVSKAYA'},
        {value: Regions.NIKOLAEVSKAYA, name: 'NIKOLAEVSKAYA'},
        {value: Regions.ODESSKAYA, name: 'ODESSKAYA'},
        {value: Regions.POLTAVSKAYA, name: 'POLTAVSKAYA'},
        {value: Regions.ROVNENSKAYA, name: 'ROVNENSKAYA'},
        {value: Regions.SUMSKAYA, name: 'SUMSKAYA'},
        {value: Regions.TERNOPOLSKAYA, name: 'TERNOPOLSKAYA'},
        {value: Regions.HARKOVSKAYA, name: 'HARKOVSKAYA'},
        {value: Regions.HERSONSKAYA, name: 'HERSONSKAYA'},
        {value: Regions.HMELNITSKAYA, name: 'HMELNITSKAYA'},
        {value: Regions.CHERKASSKAYA, name: 'CHERKASSKAYA'},
        {value: Regions.CHERNIGOVSKAYA, name: 'CHERNIGOVSKAYA'},
        {value: Regions.CHERNOVITSKAYA, name: 'CHERNOVITSKAYA'},
        {value: Regions.AP_KRYM, name: 'AP_KRYM'},
        {value: Regions.KIEV, name: 'KIEV'},
    ];

    static relation: Array<Models.ISelectEnum> = [
        {value: RelationType.MOTHER_FATHER, name: 'MOTHER_FATHER'},
        {value: RelationType.SISTER_BROTHER, name: 'SISTER_BROTHER'},
        {value: RelationType.WIFE_HUSBAND, name: 'WIFE_HUSBAND'},
        {value: RelationType.SON_DAUGHTER, name: 'SON_DAUGHTER'},
        {value: RelationType.CLOSE_RELATIVE, name: 'CLOSE_RELATIVE'},
        {value: RelationType.RELATIVE, name: 'RELATIVE'},
    ];

    static educationArray: Array<Models.ISelectEnum> = [
        {value: Education.MIDDLE_SCHOOL, name: 'MIDDLE_SCHOOL'},
        {value: Education.HIGH_SCHOOL, name: 'HIGH_SCHOOL'},
        {value: Education.SPECIALIZED_HIGH_SCHOOL, name: 'SPECIALIZED_HIGH_SCHOOL'},
        {value: Education.INCOMPLETE_HIGHER_EDUCATION, name: 'INCOMPLETE_HIGHER_EDUCATION'},
        {value: Education.HIGHER_EDUCATION, name: 'HIGHER_EDUCATION'},
        {value: Education.OTHER, name: 'OTHER'},
    ];

    static employmentArray: Array<Models.ISelectEnum> = [
        {value: BusynessTypeEnum.WORKING_MYSELF, name: 'WORKING_MYSELF'},
        {value: BusynessTypeEnum.EMPLOYER, name: 'EMPLOYER'},
        {value: BusynessTypeEnum.HEAD_OF_UNIT, name: 'HEAD_OF_UNIT'},
        {value: BusynessTypeEnum.CHIEF_EXECUTIVE, name: 'CHIEF_EXECUTIVE'},
        {value: BusynessTypeEnum.PENSIONER, name: 'PENSIONER'},
        {value: BusynessTypeEnum.STUDENT, name: 'STUDENT'},
        {value: BusynessTypeEnum.MILITARY, name: 'MILITARY'},
        {value: BusynessTypeEnum.MVD, name: 'MVD'},
        {value: BusynessTypeEnum.UNEMPLOYED, name: 'UNEMPLOYED'}
    ];

    static occupationArray: Array<Models.ISelectEnum> = [
        {value: Occupation.EMPLOYER, name: 'EMPLOYER'},
        {value: Occupation.EMPLOYER_WITH_HIGHER_EDUCATION, name: 'EMPLOYER_WITH_HIGHER_EDUCATION'},
        {value: Occupation.HEAD_OF_UNIT, name: 'HEAD_OF_UNIT'},
        {value: Occupation.CHIEF_EXECUTIVE, name: 'CHIEF_EXECUTIVE'}
    ];

    static passportOptions: Array<Models.IPassportOption> = [
        {label: 'Паспорт', value: false},
        {label: 'ID Карта', value: true}
    ];

    static relativeTypeArray: Array<Models.ISelectEnum> = [
        {value: RelationType.MOTHER_FATHER, name: 'MOTHER_FATHER'},
        {value: RelationType.SISTER_BROTHER, name: 'SISTER_BROTHER'},
        {value: RelationType.WIFE_HUSBAND, name: 'WIFE_HUSBAND'},
        {value: RelationType.SON_DAUGHTER, name: 'SON_DAUGHTER'},
        {value: RelationType.CLOSE_RELATIVE, name: 'CLOSE_RELATIVE'},
        {value: RelationType.RELATIVE, name: 'RELATIVE'}
    ];

    static incomeSourceArray: Array<Models.ISelectEnum> = [
        {value: IncomeSourceEnum.SALARY, name: 'SALARY'},
        {value: IncomeSourceEnum.AGE_PENSION, name: 'AGE_PENSION'},
        {value: IncomeSourceEnum.DISABILITY_PENSION, name: 'DISABILITY_PENSION'},
        {value: IncomeSourceEnum.UNEMPLOYMENT_BENEFIT, name: 'UNEMPLOYMENT_BENEFIT'},
        {value: IncomeSourceEnum.CHILD_BENEFIT, name: 'CHILD_BENEFIT'},
        {value: IncomeSourceEnum.ALIMONY, name: 'ALIMONY'},
        {value: IncomeSourceEnum.DIVIDEND, name: 'DIVIDEND'},
        {value: IncomeSourceEnum.OTHER, name: 'OTHER'}
    ];

    static ownershipTypeArray: Array<Models.ISelectEnum> = [
        {value: OwnershipTypeEnum.APARTMENT_OWNER, name: 'APARTMENT_OWNER'},
        {value: OwnershipTypeEnum.HOUSE_OWNER, name: 'HOUSE_OWNER'},
        {value: OwnershipTypeEnum.APARTMENT_RENT, name: 'APARTMENT_RENT'},
        {value: OwnershipTypeEnum.ROOM_RENT, name: 'ROOM_RENT'},
        {value: OwnershipTypeEnum.PARENTS, name: 'PARENTS'},
        {value: OwnershipTypeEnum.OTHER, name: 'OTHER'}
    ];
}
