import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {CustomEnvironmentService} from '../../../../services/custom-environment.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UserVerificationService} from '../../../../services/user-verification.service';
import {AffiliateService} from '../../../../shared/services/affiliate.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {UnsubscribeService} from '../../../../shared/services/unsubscribe.service';
import {VerificationType} from '../../../../enums/verification-type.enum';
import {DataForm} from '../../../../shared/static-data/Data-form.static';
import {VerificationActionType} from '../../../../enums/verification-action-type.enum';
import {filter, switchMap, take, takeUntil} from 'rxjs/operators';
import {environment} from '../../../../../apps/zecredit/src/environments/environment';
import {AuthPhoneComponent} from '../auth-phone/auth-phone.component';
import {ClientModel} from '../../../../models/client.model';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import * as Config from '../../../../configs/general-config';
import {Observable} from 'rxjs';
import {CalculateStore} from '../../../../shared/storage/calculate.store';
import {GroupType} from '../../../../types/group.type';
import {MatDialog} from '@angular/material/dialog';
import {AuthPhoneEditModalComponent} from '../auth-phone-edit-modal/auth-phone-edit-modal.component';

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthLoginComponent implements OnInit {
  public group: GroupType;
  public production: boolean = false;
  public verificationType: VerificationType = VerificationType.PHONE;
  public loginForm: FormGroup;
  public showPhone: boolean = false;
  public submitted: boolean = false;
  public isAuthConfirm: boolean = false;
  public isAuthCallConfirm: boolean = false;
  public isResend: boolean = false;
  public withCaptcha: boolean = false;
  public phone: string;
  public isDisabled: boolean = false;
  public rikki: boolean = false;
  public show: boolean = false;
  public isPreview$: Observable<boolean>;
  private path = Config.StaticDataConfig.redirectPathByClientStage;
  @ViewChild(AuthPhoneComponent, {static: false}) phoneCom: AuthPhoneComponent;

  constructor(
    public formBuilder: FormBuilder,
    public verificationService: UserVerificationService,
    public affiliateService: AffiliateService,
    public activatedRoute: ActivatedRoute,
    public recaptchaV3Service: ReCaptchaV3Service,
    public destroyStream$: UnsubscribeService,
    public customEnvironmentService: CustomEnvironmentService,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthenticationService,
    private router: Router,
    public route: ActivatedRoute,
    private calculateStore: CalculateStore,
    private dialog: MatDialog
  ) {
    this.group = this.customEnvironmentService.environment?.group;
    this.production = this.customEnvironmentService.environment?.production;
    this.isPreview$ = this.calculateStore.isPreviewVisible;
  }

  get type(): string {
    const types = {
      AVANS: 'avans',
      STARFIN: 'starfin',
      SUNCREDIT: 'suncredit',
      BASE: 'base',
    };
    return types[this.group] ? types[this.group] : 'base';
  }

  public ngOnInit(): void {
    this.activateForm();
    this.setQueryParams();
    this.route.queryParams.subscribe((params: Params) => {
      this.show = params.show;
      this.rikki = params.rikki;
    });
  }

  public setQueryParams() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.affiliateService.setQueryParamsFromUrl(params);
    });
  }

  public setValuePhone(element): void {
    const val = element.value;
    element.value = '';
    element.value = val;
  }

  public editPhoneHandler(event: boolean): void {
    if (event) {
      this.isResend = false;
      this.isAuthConfirm = false;
      this.isAuthCallConfirm = false;
      this.changeDetectorRef.detectChanges();
      this.phoneCom.loginForm.patchValue({
        phone: this.phone,
      });
    }
  }

  public showPhoneEditorModal(): void {
    const modal = this.dialog.open(AuthPhoneEditModalComponent, {
      panelClass: 'phone-edit-dialog',
      data: {
        phoneNumber: this.phone,
        group: this.group,
      },
      width: '600px',
      maxWidth: '90vw',
      maxHeight: '90vh',
    });

    modal
      .afterClosed()
      .pipe(filter(Boolean), take(1))
      .subscribe((phone: number) => {
        this.isResend = false;
        this.isAuthConfirm = false;
        this.isAuthCallConfirm = false;
        this.changeDetectorRef.detectChanges();
        this.phoneCom.loginForm.patchValue({
          phone: phone,
        });
        environment.production
          ? this.phoneCom.onSubmitRikki()
          : this.phoneCom.onSubmit();
      });
  }

  public verificationClient(): void {
    this.verificationService
      .sendSmsCode(
        this.phone,
        VerificationActionType.LOGIN,
        false,
        this.verificationType
      )
      .subscribe(
        () => {
          this.isDisabled = false;
          this.isResend = false;
          this.isAuthConfirm = true;
        },
        () => (this.isDisabled = false)
      );
  }

  // public verificationClientReturn(): Observable<any> {
  //   return this.verificationService.
  //   sendSmsCode(this.phone, VerificationActionType.LOGIN,
  //     false, this.verificationType);
  // }

  public verificationClientPhoneCall(): void {
    this.isAuthCallConfirm = true;
    this.isAuthConfirm = false;
    this.isResend = false;
  }

  public verificationClientPhoneCallWithCaptcha(): void {
    this.withCaptcha = true;
    this.isAuthCallConfirm = true;
    this.isAuthConfirm = false;
    this.isResend = false;
  }

  public verificationClientWithCaptcha(): void {
    this.recaptchaV3Service
      .execute('login')
      .pipe(
        switchMap((reCaptchaToken) =>
          this.verificationService.sendSmsCodeWithCaptcha(
            this.phone,
            VerificationActionType.LOGIN,
            reCaptchaToken,
            false,
            this.verificationType
          )
        ),
        takeUntil(this.destroyStream$)
      )
      .subscribe(
        () => {
          this.isDisabled = false;
          this.isAuthConfirm = true;
          this.isResend = false;
        },
        () => (this.isDisabled = false)
      );
  }

  public timeGoneHandler(): void {
    if (this.isAuthCallConfirm) {
      this.isResend = true;
      this.isAuthCallConfirm = false;
      this.isAuthConfirm = false;
    }
    if (this.isAuthConfirm) {
      this.isResend = false;
      this.isAuthConfirm = true;
      this.isAuthCallConfirm = false;
    }
  }

  public chooseWayRepeat(type): void {
    this.verificationType = type;
    this.isResend = false;
    this.isAuthCallConfirm = false;
    this.isAuthConfirm = true;

    environment.recaptchaKEY_CODE
      ? this.verificationClientWithCaptcha()
      : this.verificationClient();
  }

  public setValues(event): void {
    this.isAuthCallConfirm = event.isAuthCallConfirm;
    this.isAuthConfirm = event.isAuthConfirm;
    this.isResend = event.isResend;
    this.withCaptcha = event.withCaptcha;
  }

  public setPhone(number: string): void {
    this.phone = number;
  }

  public redirectClientHandler(client: ClientModel): void {
    this.path.form[client.stage]
      ? this.router.navigate(['form', this.path.form[client.stage]])
      : this.router.navigate(['main', 'loan']);
    this.authService.setLoggedIn(true);
  }

  private activateForm(): void {
    this.loginForm = DataForm.getLoginFormGroup();
  }
}
