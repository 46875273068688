<ng-container [ngSwitch]="group">

  <ng-container *ngSwitchCase="'AVANS'">
    <section class="auth-confirm auth-confirm-avans" [formGroup]="form">
      <div class="auth-confirm-content">
        <h2 class="auth-confirm-title text-center">{{'AUTH.TITLE'  | translate}}</h2>
        <p class="auth-confirm-text">
          <span [innerHTML]="'AUTO_CONFIRM.CALL' | translate : {number: phoneNumber}"></span>
          <span class="cursor-pointer font-weight-bold"
                (click)="editPhone()">({{'AUTH.EDIT' | translate }})</span>
        </p>
        <p class="auth-confirm-text" [innerHTML]="'AUTO_CONFIRM.CLICK' | translate"></p>
        <div class="auth-confirm-icon d-flex justify-content-center">
          <svg class="call-btn" width="61" height="60" viewBox="0 0 61 60" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d='M30.5 60C47.3447 60 61 46.5685 61 30C61 13.4315 47.3447 0 30.5 0C13.6553 0 0 13.4315 0 30C0 46.5685 13.6553 60 30.5 60ZM30.9046 35.4603C28.217 34.1673 26.0464 32.0023 24.7466 29.3181C24.6533 29.1232 24.6116 28.9076 24.6254 28.692C24.6393 28.4764 24.7082 28.2679 24.8256 28.0865L27.4624 24.0759C27.5741 23.9014 27.6412 23.7021 27.6577 23.4956C27.6742 23.2891 27.6397 23.0817 27.5572 22.8917L24.3834 15.4863C24.2783 15.2338 24.0931 15.0228 23.8563 14.8858C23.6195 14.7489 23.3442 14.6936 23.0729 14.7284C21.2449 14.9626 19.5648 15.8549 18.3471 17.2383C17.1294 18.6217 16.4574 20.4013 16.457 22.2443C16.457 27.9396 18.7195 33.4015 22.7466 37.4287C26.7738 41.4558 32.2357 43.7183 37.931 43.7183C39.774 43.7178 41.5536 43.0459 42.937 41.8282C44.3204 40.6105 45.2127 38.9304 45.4469 37.1024C45.4817 36.8311 45.4264 36.5558 45.2895 36.319C45.1525 36.0822 44.9415 35.897 44.689 35.7918L37.2994 32.6181C37.1063 32.5337 36.895 32.4995 36.6852 32.5189C36.4753 32.5382 36.2738 32.6104 36.0994 32.7287L32.152 35.3655C31.9702 35.4883 31.7595 35.5615 31.5408 35.5782C31.3221 35.5948 31.1028 35.5541 30.9046 35.4603Z'
                  fill='#00B92D'/>
          </svg>
        </div>
        <ng-container [class.d-none]="timeOut">
          <app-countdown-container [verificationType]="verificationType"
                                   [initialState]="initialState"
                                   [counterType]="'TIMER'"
                                   [group]="group"
                                   classes="mt-2"
                                   (breakpointOutEvent)="breakpointOutHandler($event)"
                                   (timeOutEvent)="timeOutEventHandler($event)"
          ></app-countdown-container>
        </ng-container>
        <ng-container *ngIf="breakpointTimeOut">
          <app-resend-sms-widget [timeOut]="timeOut"
                                 [showPhone]="showPhone"
                                 [group]="group"
                                 [showAuthMessage]="true"
                                 (verifyWayEvent)="wayVerificationHandler($event)"
          ></app-resend-sms-widget>
        </ng-container>
        <div class="mt-3">
          <app-auth-agreement formGroupName="agreement"
                              labelClasses="text-justify"></app-auth-agreement>
        </div>
      </div>
    </section>
  </ng-container>

  <ng-container *ngSwitchCase="'STARFIN'">
    <section class="auth-confirm auth-confirm-starfin" [formGroup]="form">
      <div class="auth-confirm-content">
        <h2 class="auth-confirm-title text-center">{{'AUTH.TITLE'  | translate}}</h2>
        <p class="auth-confirm-text mb-2">
          <span [innerHTML]="'AUTO_CONFIRM.CALL' | translate : {number: phoneNumber}"></span>
          <span class="cursor-pointer font-weight-bold"
                (click)="showPhoneEditorEvent.emit()">{{'AUTH.EDIT' | translate }}</span>
        </p>
        <p class="auth-confirm-text mb-3" [innerHTML]="'AUTO_CONFIRM.CLICK' | translate"></p>
        <ng-container [class.d-none]="timeOut">
          <app-countdown-container [verificationType]="verificationType"
                                   [initialState]="initialState"
                                   [counterType]="'TIMER'"
                                   [group]="group"
                                   classes="countdown-starfin mt-2"
                                   [showProgressBar]="true"
                                   (breakpointOutEvent)="breakpointOutHandler($event)"
                                   (timeOutEvent)="timeOutEventHandler($event)"
          ></app-countdown-container>
        </ng-container>
        <ng-container *ngIf="breakpointTimeOut">
          <app-resend-sms-widget [timeOut]="timeOut"
                                 [showPhone]="showPhone"
                                 [group]="group"
                                 [showAuthMessage]="true"
                                 (verifyWayEvent)="wayVerificationHandler($event)"
          ></app-resend-sms-widget>
        </ng-container>
        <div class="auth-agreement mt-3">
          <app-auth-agreement formGroupName="agreement"
                              labelClasses="fs-12 text-left"></app-auth-agreement>
        </div>
      </div>
    </section>
  </ng-container>

  <ng-container *ngSwitchCase="'SUNCREDIT'">
    <section class="auth-confirm auth-confirm-suncredit" [formGroup]="form">
      <div class="auth-confirm-content">
        <h2 class="auth-confirm-title text-center title-decor">{{'AUTH.TITLE'  | translate}}</h2>
        <div class="auth-editor">
          <div class="auth-editor-container">
            <p class="auth-editor-header">{{'AUTO_CONFIRM.CALL' | translate}}</p>
            <div class="auth-editor-control d-flex flex-column" (click)="showPhoneEditorEvent.emit()">
              <span class="auth-editor-label">Телефонний номер</span>
              <span class="auth-editor-value">{{phoneNumber}}</span>
              <span class="auth-editor-btn"></span>
            </div>
            <p class="auth-confirm-text text-center fs-16 lh-22 fw-regular" [innerHTML]="'AUTO_CONFIRM.CLICK' | translate"></p>
          </div>
        </div>
        <ng-container [class.d-none]="timeOut">
          <app-countdown-container [verificationType]="verificationType"
                                   [initialState]="initialState"
                                   [counterType]="'COUNTDOWN'"
                                   [group]="group"
                                   classes="countdown-suncredit mt-2"
                                   [showProgressBar]="false"
                                   (breakpointOutEvent)="breakpointOutHandler($event)"
                                   (timeOutEvent)="timeOutEventHandler($event)"
          ></app-countdown-container>
        </ng-container>
        <ng-container *ngIf="breakpointTimeOut">
          <app-resend-sms-widget [timeOut]="timeOut"
                                 [showPhone]="showPhone"
                                 [group]="group"
                                 [showAuthMessage]="true"
                                 (verifyWayEvent)="wayVerificationHandler($event)"
          ></app-resend-sms-widget>
        </ng-container>
        <div class="auth-agreement mt-3">
          <app-auth-agreement formGroupName="agreement"
                              labelClasses="fs-12 text-left"
                              imgSize="32px"></app-auth-agreement>
        </div>
      </div>
    </section>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <section class="confirm-auth" [formGroup]="form">
      <div class="auth-landscape">
        <div class="auth-landscape__body">
          <div class="auth-landscape-content">
            <h2 class="auth-landscape__title">{{'AUTH.TITLE'  | translate}}</h2>
            <p class="auth-landscape__text">
              <span [innerHTML]="'AUTO_CONFIRM.CALL' | translate : {number: phoneNumber}"></span>
              <span class="pl-1 cursor-pointer font-weight-bold"
                    (click)="editPhone()">{{'AUTH.EDIT' | translate }}</span>
            </p>
            <p class="auth-landscape__text" [innerHTML]="'AUTO_CONFIRM.CLICK' | translate"></p>
            <div class="auth-confirm-icon d-flex justify-content-center mt-3 mb-3">
              <svg width="54" height="54" viewBox="0 0 54 54" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M39.1322 14.8679C37.4902 13.226 35.3957 12.1114 33.1167 11.6667L36.148 12.7066L39.1322 14.8679ZM39.1322 14.8679C40.7741 16.5098 41.8887 18.6043 42.3333 20.8834L41.2935 17.8521L39.1322 14.8679ZM45.6379 8.34639C42.2671 4.97978 37.8517 2.85939 33.1167 2.33337L36.404 2.96704L39.4814 4.08433L42.7722 5.95329L45.6379 8.34639ZM45.6379 8.34639C49.0088 11.713 51.1347 16.1257 51.6667 20.86L50.957 17.3214L49.6731 14.0001L47.8873 11.0001L45.6379 8.34639ZM49.3333 39.48V46.48C49.336 47.1299 49.2029 47.7731 48.9425 48.3685C48.6822 48.9639 48.3004 49.4984 47.8215 49.9377C47.3427 50.377 46.7773 50.7115 46.1618 50.9197C45.5462 51.1279 44.8939 51.2052 44.2467 51.1467C37.0666 50.3665 30.1697 47.913 24.11 43.9834C18.4723 40.4009 13.6925 35.6211 10.11 29.9834C6.16662 23.8962 3.71257 16.9657 2.94667 9.75337C2.88836 9.10813 2.96505 8.45781 3.17184 7.84383C3.37863 7.22985 3.71101 6.66565 4.14779 6.18716C4.58458 5.70866 5.11622 5.32636 5.70885 5.06459C6.30149 4.80282 6.94214 4.66732 7.59001 4.66671H14.59C15.7224 4.65556 16.8202 5.05656 17.6788 5.79495C18.5374 6.53334 19.0982 7.55875 19.2567 8.68004C19.5521 10.9202 20.1001 13.1197 20.89 15.2367C21.2039 16.0719 21.2719 16.9795 21.0858 17.8521C20.8997 18.7247 20.4674 19.5256 19.84 20.16L16.8767 23.1234C20.1983 28.965 25.0351 33.8017 30.8767 37.1234L33.84 34.16C34.4744 33.5327 35.2754 33.1004 36.148 32.9143C37.0205 32.7282 37.9282 32.7961 38.7633 33.11C40.8803 33.9 43.0799 34.4479 45.32 34.7434C46.4535 34.9033 47.4886 35.4742 48.2286 36.3475C48.9686 37.2209 49.3617 38.3357 49.3333 39.48Z"
                  stroke="#006CE7" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <ng-container [class.d-none]="timeOut">
              <app-countdown-container [verificationType]="verificationType"
                                       [initialState]="initialState"
                                       [counterType]="'TIMER'"
                                       [group]="group"
                                       classes="mt-2"
                                       (breakpointOutEvent)="breakpointOutHandler($event)"
                                       (timeOutEvent)="timeOutEventHandler($event)"
              ></app-countdown-container>
            </ng-container>
            <ng-container *ngIf="breakpointTimeOut">
              <app-resend-sms-widget [timeOut]="timeOut"
                                     [showPhone]="showPhone"
                                     [group]="group"
                                     [showAuthMessage]="true"
                                     (verifyWayEvent)="wayVerificationHandler($event)"
              ></app-resend-sms-widget>
            </ng-container>
            <div class="mt-3">
              <app-auth-agreement formGroupName="agreement"
                                  labelClasses="text-justify"></app-auth-agreement>
            </div>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
</ng-container>

