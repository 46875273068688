import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/internal/operators';
import * as Config from '../../../../configs/general-config';
import * as Models from '../../../../models/models';
import { UnsubscribeService } from '../../../../shared/services/unsubscribe.service';
import { ClientModel } from '../../../../models/client.model';
import { ClientService } from '../../../../services/client.service';
import { ClientStore } from '../../../../shared/storage/client.store';
import { FormExecutorService } from '../../../../services/form-executor.service';
import { DataForm } from '../../../../shared/static-data/Data-form.static';
import { BusynessTypeEnum } from '../../../../enums/busyness-type.enum';
import { EmploymentModel } from '../../../../models/employment.model';
import { AddCardComponent } from '../../../system/components/application/add-card/add-card.component';
import {
  CustomEnvironmentService,
  IEnvConfig,
} from '../../../../services/custom-environment.service';

@Component({
  selector: 'app-form-busyness',
  templateUrl: './form-busyness.component.html',
  styleUrls: ['./form-busyness.component.sass'],
  providers: [UnsubscribeService],
})
export class FormBusynessComponent implements OnInit {
  public form: FormGroup;
  public submitted: boolean = false;
  public isLoaded: boolean = false;
  public isUnemployed: boolean = false;
  public isDisabled: boolean = false;
  public client: ClientModel;
  public educationList: Array<Models.ISelectEnum> =
    Config.SelectConfig.educationArray;
  public busynessList: Array<Models.ISelectEnum> =
    Config.SelectConfig.employmentArray;
  public incomeSourceList: Array<Models.ISelectEnum> =
    Config.SelectConfig.incomeSourceArray;
  public ownershipType: Array<Models.ISelectEnum> =
    Config.SelectConfig.ownershipTypeArray;
  public ENV: IEnvConfig = this.environmentService.environment;
  @ViewChild(AddCardComponent, { static: false })
  addCardComponent: AddCardComponent;

  constructor(
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private router: Router,
    private clientStore: ClientStore,
    private destroyStream$: UnsubscribeService,
    private formService: FormExecutorService,
    private environmentService: CustomEnvironmentService
  ) {}

  public ngOnInit(): void {
    this.clientStore
      .getClient()
      .pipe(takeUntil(this.destroyStream$))
      .subscribe((client: ClientModel) => {
        this.client = client;
        this.isLoaded = true;
        this.activateForm();
        this.formService.fillForm(this.form, client.employmentData);
        this.checkedClientEmployed(client);
      });
  }

  public activateForm(): void {
    this.form = DataForm.getBusinessFormGroup();
    this.initFormValidators();
  }

  get f(): Models.IFormControls {
    return this.form.controls;
  }

  public checkedClientEmployed(client: ClientModel): void {
    if (client.employmentData.busynessType === BusynessTypeEnum.UNEMPLOYED) {
      this.hideEmploymentControl();
    }
  }

  public onOccupationChoose($event) {
    $event.target.value === BusynessTypeEnum.UNEMPLOYED
      ? this.hideEmploymentControl()
      : this.showEmploymentControl();
  }

  public hideEmploymentControl(): void {
    this.isUnemployed = true;
    this.formService.clearValidatorsControls(this.form);
    this.formService.setValuesForm(this.form);
    this.formService.updateValueAndValidityControls(this.form);
  }

  public showEmploymentControl(): void {
    this.isUnemployed = false;
    this.initFormValidators();
    this.formService.updateValueAndValidityControls(this.form);
  }

  public initFormValidators(): void {
    this.formService.setValidatorsControls(this.form, {
      busynessType: [Validators.required],
    });
  }

  public onSubmit() {
    this.submitted = true;
    const successUrl =
      window.location.origin + '/system/application?addedCard=1';
    const errorUrl = window.location.origin;
    if (this.form.invalid) {
      return;
    }
    const employmentData = new EmploymentModel(this.form.value);
    this.isDisabled = true;
    this.clientService
      .updateClientInfo(employmentData, '?employment-data')
      .subscribe(
        (client: ClientModel) => {
          this.clientStore.setClient(client);
          this.addCardComponent.addCard(successUrl, errorUrl);
        },
        () => (this.isDisabled = false)
      );
  }
}
