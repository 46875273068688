<ng-container [ngSwitch]="type">

  <div class="counter text-center" *ngSwitchCase="'TIMER'"
       [ngClass]="type">{{currentCount}}
  </div>

  <div class="countdown" *ngSwitchCase="'COUNTDOWN'">
    <div class="countdown-spinner d-flex justify-content-center">
      <div class="spinner-container">
        <div class="spinner-background" [style.border-width.px]="initialState.strokeWidth">{{currentCount}}</div>
        <mat-progress-spinner
          color="primary"
          mode="determinate"
          [diameter]="initialState.diameter"
          [strokeWidth]="initialState.strokeWidth"
          [value]="progress">
        </mat-progress-spinner>
      </div>
    </div>
  </div>

</ng-container>
