import {Injectable} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';
import {UrlService} from '../../../services/url.service';

@Injectable()
export class CommunicationService {

  public modalRef: BsModalRef;
  public isDisabled: boolean = false;
  public enabledCommunication: boolean = true;
  public subjectModalRef: Subject<BsModalRef> = new Subject<BsModalRef>();

  constructor(private http: HttpClient, private urlService: UrlService) {
    this.subjectModalRef.asObservable()
      .subscribe(modalRef => this.modalRef = modalRef);
  }

  public setLanguage(communicationLanguage: string): void {
    this.isDisabled = true;
    this.http.post<string>(this.urlService.serviceData, {communicationLanguage})
      .subscribe(() => {
        this.enabledCommunication = false;
        this.modalRef.hide()
      });
  }
}
