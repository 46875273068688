<ng-container [ngSwitch]="group">

  <ng-container *ngSwitchCase="'AVANS'">
    <section class="camera-selfie text-center d-none" [class.d-block]="isPermission">
      <h2 class="camera-selfie-header fw-bold">
        Щоб все вийшло з першої спроби - розмістіть своє обличчя у відповідній зоні (не можна завантажувати старі фото)
      </h2>
      <div class="camera-selfie-content">
        <div class="camera-selfie-mask camera-selfie-mask-avans">
          <webcam *ngIf="showWebcam"
                  [height]="580"
                  [width]="380"
                  [trigger]="triggerObservable"
                  [allowCameraSwitch]="allowCameraSwitch"
                  [switchCamera]="nextWebcamObservable"
                  [videoOptions]="videoOptions"
                  (imageCapture)="handleImage($event)"
                  (cameraSwitched)="cameraWasSwitched($event)"
                  (initError)="handleInitError($event)"
          ></webcam>
        </div>
      </div>
      <br/>
      <app-button
        (click)="triggerSnapshot()"
        size="large"
        color="secondary"
        classes="fs-20"
        [label]="'Зробити селфі'">
      </app-button>
    </section>

    <section class="permission" *ngIf="!isPermission">
      <div class="permission-content text-center">
        <h2 class="permission__title">Щоб зробити фото, необхідний доступ до вашої камери</h2>
        <p class="permission-info fw-bold">Доступ до камери</p>
        <img class="permission-icon" src="assets/images/photo.svg" alt="">
      </div>
    </section>
  </ng-container>

  <ng-container *ngSwitchCase="'STARFIN'">
    <section
      class="camera-selfie text-center d-none"
      [class.d-block]="isPermission"
      [class.camera-selfie-error]="isError"
    >
      <h2 class="camera-selfie-header fw-bold">
        {{
        (isError ? 'SELFIE.MAKE_PHOTO.ERROR_HEADER' : 'SELFIE.MAKE_PHOTO.HEADER')
          | translate
        }}
      </h2>
      <h5 class="camera-selfie-text mb-0">
        {{
        (isError
            ? 'SELFIE.MAKE_PHOTO.ERROR_DESCRIPTION'
            : 'SELFIE.MAKE_PHOTO.DESCRIPTION'
        ) | translate
        }}
      </h5>
      <div class="camera-selfie-content camera-selfie-content-starfin">
        <div class="camera-selfie-mask" [class.camera-selfie-mask_error]="isError">
          <webcam
            *ngIf="showWebcam"
            [height]="120"
            [width]="120"
            [trigger]="triggerObservable"
            [allowCameraSwitch]="allowCameraSwitch"
            [switchCamera]="nextWebcamObservable"
            [videoOptions]="videoOptions"
            (imageCapture)="handleImage($event)"
            (cameraSwitched)="cameraWasSwitched($event)"
            (initError)="handleInitError($event)"
          ></webcam>
        </div>
      </div>
      <app-button
        (click)="triggerSnapshot()"
        size="large"
        color="secondary"
        classes="fs-16 fw-700"
        [label]="
      (isError
        ? 'SELFIE.MAKE_PHOTO.ERROR_BTN_TEXT'
        : 'SELFIE.MAKE_PHOTO.BTN_TEXT'
      ) | translate
    "
      >
      </app-button>
    </section>

    <section class="permission" *ngIf="!isPermission">
      <div class="permission-content text-center">
        <h2 class="permission__title">
          {{ 'SELFIE.CAMERA_ACCESS.TITLE' | translate }}
        </h2>
        <p class="permission-info fw-bold">
          {{ 'SELFIE.CAMERA_ACCESS.DESCRIPTION' | translate }}
        </p>
        <img
          class="permission-icon"
          src="assets/images/Illustr_Phone.webp"
          alt="illustration"
        />
      </div>
    </section>
  </ng-container>

  <ng-container *ngSwitchCase="'SUNCREDIT'">
    <section
      class="camera-selfie text-center d-none"
      [class.d-block]="isPermission"
      [class.camera-selfie-error]="isError"
    >
      <h2 class="camera-selfie-header title-decor fw-bold">
        {{
        (isError ? 'SELFIE.MAKE_PHOTO.ERROR_HEADER' : 'SELFIE.MAKE_PHOTO.HEADER')
          | translate
        }}
      </h2>
      <h5 class="camera-selfie-text">
        {{
        (isError
            ? 'SELFIE.MAKE_PHOTO.ERROR_DESCRIPTION'
            : 'SELFIE.MAKE_PHOTO.DESCRIPTION'
        ) | translate
        }}
      </h5>
      <div class="camera-selfie-content">
        <div class="camera-selfie-mask">
          <div class="camera-selfie-layout" [class.camera-selfie-layout_error]="isError">
            <span class="camera-selfie-layout-element"></span>
            <span class="camera-selfie-layout-element"></span>
            <span class="camera-selfie-layout-element"></span>
            <span class="camera-selfie-layout-element"></span>
          </div>
          <webcam
            *ngIf="showWebcam"
            [height]="580"
            [width]="380"
            [trigger]="triggerObservable"
            [allowCameraSwitch]="allowCameraSwitch"
            [switchCamera]="nextWebcamObservable"
            [videoOptions]="videoOptions"
            (imageCapture)="handleImage($event)"
            (cameraSwitched)="cameraWasSwitched($event)"
            (initError)="handleInitError($event)"
          ></webcam>
        </div>
      </div>

      <div class="camera-selfie-footer">
        <app-button
          (click)="triggerSnapshot()"
          size="w100"
          class="w-100"
          color="primary"
          classes="fs-18 fw-medium"
          [label]="
      (isError
        ? 'SELFIE.MAKE_PHOTO.ERROR_BTN_TEXT'
        : 'SELFIE.MAKE_PHOTO.BTN_TEXT'
      ) | translate
    "
        >
        </app-button>
      </div>

    </section>

    <section class="permission" *ngIf="!isPermission">
      <div class="permission-content text-center">
        <h2 class="permission__title">
          {{ 'SELFIE.CAMERA_ACCESS.TITLE' | translate }}
        </h2>
        <p class="permission-info fw-bold">
          {{ 'SELFIE.CAMERA_ACCESS.DESCRIPTION' | translate }}
        </p>
        <img
          class="permission-icon"
          src="assets/images/illustration.webp"
          alt="illustration"
        />
      </div>
    </section>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <section class="text-center">
      <p *ngIf="enableSubText">{{'CAMERA_SELFIE.INFO' | translate}}</p>
      <ng-container *ngIf="disabledSelfie">
        <div class="dark-block"></div>
      </ng-container>
      <div class="mask">
        <webcam *ngIf="showWebcam"
                [hidden]="disabledSelfie"
                [height]="500"
                [width]="380"
                [trigger]="triggerObservable"
                [allowCameraSwitch]="allowCameraSwitch"
                [switchCamera]="nextWebcamObservable"
                [videoOptions]="videoOptions"
                (imageCapture)="handleImage($event)"
                (cameraSwitched)="cameraWasSwitched($event)"
                (initError)="handleInitError($event)"></webcam>
      </div>
      <br/>
      <button class="btn" [disabled]="disabledSelfie"
              (click)="triggerSnapshot();">{{'CAMERA_SELFIE.BTN' | translate}}</button>
    </section>

  </ng-container>

</ng-container>
