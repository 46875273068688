import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {
  ToasterComponentData,
  ToasterWidgetComponent,
  ToastType
} from './toaster-widget.component';
import {IFormFieldError} from '../../../models/models';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  private static get config(): MatSnackBarConfig {
    return {
      direction: 'ltr',
      horizontalPosition: 'right',
      verticalPosition: 'bottom'
    } as MatSnackBarConfig;
  }

  constructor(private snackBar: MatSnackBar) {
  }

  public success(title: string = '', className: string = '') {
    return this.show(ToastType.SUCCESS, title, className);
  }

  public error(title: string = '', className: string = '') {
    return this.show(ToastType.ERROR, title, className);
  }

  public warning(title: string = '', className: string = '') {
    return this.show(ToastType.WARNING, title, className);
  }

  public info(title: string = '', className: string = '') {
    return this.show(ToastType.INFO, title, className);
  }

  private show(type: ToastType, title: string, className: string = '') {
    return this.snackBar.openFromComponent(ToasterWidgetComponent, {
      ...ToasterService.config,
      duration: 6000,
      panelClass: className,
      data: {
        type,
        title
      } as ToasterComponentData
    });
  }
  public showWithContent(type: ToastType, data: {title?: string, message?: string, loggerCode?: string, errors?: IFormFieldError[]}) {
    return this.snackBar.openFromComponent(ToasterWidgetComponent, {
      ...ToasterService.config,
      duration: 8000,
      data: {
        type,
        title: data.title,
        content: {
          message: data.message,
          loggerCode: data.loggerCode,
          errors: data.errors
        },
      } as ToasterComponentData
    });
  }
}
