import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../shared/services/authentication.service';
import { ValidationHttpErrorResponse } from '../models/models';
import { ErrorHandlerService } from '../services/error-handler.service';
import { CustomEnvironmentService } from '../services/custom-environment.service';
import { StatusCodes } from './status-codes.enum';
import { ToasterService } from '../shared/components/toaster-widget/toaster.service';
import { ToastType } from '../shared/components/toaster-widget/toaster-widget.component';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private ENV = this._customEnvironmentService.environment;

  constructor(
    private toaster: ToasterService,
    private router: Router,
    private authenticationService: AuthenticationService,
    public errorHandlerService: ErrorHandlerService,
    private _customEnvironmentService: CustomEnvironmentService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        let errorMessage: string;
        let loggerCode: string;
        if (err.error instanceof ErrorEvent) {
          errorMessage = 'error.browser';
        }
        if (err.error instanceof Blob) {
          this.convertBlobError(err.error).subscribe(convertedErr => {
            loggerCode = convertedErr.loggerCode;
            errorMessage = convertedErr.message;
            this.toaster.showWithContent(ToastType.WARNING, {
              title: 'errors.title',
              message: errorMessage,
              loggerCode: loggerCode,
            });
          });
          this.sendError(err.message);
          return throwError(err);
        }

        switch (err.status) {
          case StatusCodes.CONNECTION_REFUSED:
            errorMessage = 'error.server.connectionRefused';
            break;
          case StatusCodes.UNAUTHORIZED:
            break;
          case StatusCodes.BAD_REQUEST:
            const errors = (err.error as ValidationHttpErrorResponse)
              .fieldErrors;
            this.toaster.showWithContent(ToastType.WARNING, {
              title: 'errors.title',
              errors: errors,
            });
            this.sendError(err.message);
            return throwError(err);
          case StatusCodes.UNSUPPORTED_MEDIA:
            this.sendError(err.message);
            return throwError(err);
          default:
            errorMessage = err.error.message;
            loggerCode = err.error.loggerCode;
            this.toaster.showWithContent(ToastType.WARNING, {
              title: 'errors.title',
              message: errorMessage,
              loggerCode: loggerCode,
            });
            this.sendError(errorMessage);
            return throwError(err);
        }
      })
    );
  }

  private sendError(error: any) {
    const stackLines = error.stack.split('\n');
    let errorLocation: string = '';
    if (stackLines.length > 1) {
      errorLocation = stackLines[1].trim();
    }
    const website = this.ENV.name;
    const token = sessionStorage.getItem('jwt_token');
    const phone = sessionStorage.getItem('phone');
    const url = window.location.pathname;
    if (this.ENV.production) {
      this.errorHandlerService
        .sendErrors('WARN', error, website, url, phone, token, errorLocation)
        .subscribe(() => {});
    }
  }

  // this method may be not optimal (async error response converting), should come up with other decision
  private convertBlobError(blob: Blob): Observable<any> {
    return new Observable<string>(observer => {
      const reader = new FileReader();
      reader.onload = e => {
        const responseText = reader.result as string;
        const error = JSON.parse(responseText);
        observer.next(error);
        observer.complete();
      };
      reader.readAsText(blob, 'utf-8');
    });
  }
}
