import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {AuthRepeatModule} from '../../../modules/auth/components/auth-repeat/auth-repeat.module';
import {TimerComponent} from './timer.component';

@NgModule({
  declarations: [TimerComponent],
  imports: [CommonModule, AuthRepeatModule, TranslateModule],
  exports: [TimerComponent]
})
export class TimerModule {
}
