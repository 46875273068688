import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {ButtonsWayCodeComponent} from './buttons-way-code.component';


@NgModule({
  declarations: [ButtonsWayCodeComponent],
  imports: [CommonModule, TranslateModule],
  exports: [ButtonsWayCodeComponent]
})
export class ButtonsWayCodeModule {
}
