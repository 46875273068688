<section class="sms-code-widget" [ngClass]="'sms-code-widget_'+ type">
  <div class="sms-code__label text-center mb-2">
    <label for="sms-code" *ngIf="verificationType === 'SMS'">{{ 'CONFIRM.SMS_CODE_LABEL' | translate }}</label>
    <label for="sms-code" *ngIf="verificationType === 'PHONE'">{{ 'CONFIRM.PHONE_CODE_LABEL' | translate }}</label>
  </div>
  <div class="sms-code__field" [ngClass]="{'code-sms': verificationType === 'SMS'}">
    <input id="sms-code"
           autocomplete="off"
           class="sms-code__input"
           type="number"
           [class.in-valid]="control?.invalid && control?.touched"
           [class.valid]=" control?.valid"
           [placeholder]="placeholder"
           [disabled]="isDisabled"
           [value]="value"
           [ngClass]="inputClasses"
           (blur)="markAsTouched()"
           (change)="change($event)"
           (input)="input($event)"
           (focus)="registerFocusEvent($event)">

    <div class="error-message" *ngIf="control?.errors && control?.invalid && control?.touched">
      <span *ngIf="control?.errors.required">{{ 'validation.required' | translate }}</span>
      <span *ngIf="control?.errors.minlength" translate
            [translateParams]="{requiredLength:control?.errors.minlength.requiredLength}">validation.minLength</span>
    </div>
  </div>
</section>



