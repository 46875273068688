import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import * as Models from '../../models/models';
import * as Config from './../../configs/general-config';

import * as moment from 'moment';
import {CustomEnvironmentService} from "../../services/custom-environment.service";

@Injectable()
export class AffiliateService {

    public affiliateParam: Models.IAffiliate = {};
    public affiliate = Config.StaticDataConfig.affiliate;

    constructor(private cookieService: CookieService,
                private _customEnvironmentService: CustomEnvironmentService) {}

    public domain: string = this._customEnvironmentService.environment.cookieDomain;
    public setAffiliate(): void {
        this.affiliate.forEach(aff => {
            this.affiliateParam[aff] = this.cookieService.get(aff);
        });
    }

    public setQueryParamsFromUrl(params) {
        const date = moment(new Date()).format('YYYY-MM-DD');
        Object.keys(params).forEach(key => {
            if (this.affiliate.includes(key)) {
                this.cookieService.set(key, params[key], 30, '/', this.domain);
                this.cookieService.set('affiliateCreatedAt', date, 30, '/', this.domain);
            }
        });
    }

    public getAffiliate() {
        return this.affiliateParam;
    }
}
