import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WebcamModule} from 'ngx-webcam';
import {ButtonModule} from '../button/button.module';
import {TranslateModule} from '@ngx-translate/core';
import {CameraSelfieComponent} from './camera-selfie.component';

@NgModule({
  declarations: [CameraSelfieComponent],
  exports: [CameraSelfieComponent],
  imports: [CommonModule, WebcamModule, ButtonModule, TranslateModule],
})
export class CameraSelfieModule {}
