import {NgModule} from '@angular/core';
import {CallbackComponent} from './callback/callback.component';
import {BankIdMainComponent} from './bank-id-main.component';
import {BankIdMainRoutingModule} from './bank-id-main-routing.module';
import {BankIdComponent} from './bank-id/bank-id.component';
import {TranslateModule} from '@ngx-translate/core';
import {LoaderModule} from '../../shared/components/loader/loader.module';
import {PlatonPrivatFormModule} from '../../shared/components/platon-privat-form/platon-privat-form.module';
import {CommonModule} from '@angular/common';
import {BankIdModalComponent} from './bank-id-modal/bank-id-modal.component';

@NgModule({
  declarations: [CallbackComponent, BankIdMainComponent, BankIdComponent, BankIdModalComponent],
  exports: [BankIdMainRoutingModule, CallbackComponent, BankIdMainComponent, BankIdComponent, BankIdModalComponent],
  imports: [BankIdMainRoutingModule, CommonModule, TranslateModule, LoaderModule, PlatonPrivatFormModule]
})
export class BankIdMainModule {
}
