import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import * as Config from '../../../libs/configs/general-config';
import {AuthComponent} from './auth.component';
import {AuthLoginComponent} from './components/auth-login/auth-login.component';

const authRoutes: Routes = [{
  path: Config.RouterConfig.auth,
  component: AuthComponent,
  children: [
    {
      path: Config.RouterConfig.login,
      component: AuthLoginComponent
    },
    {
      path: Config.RouterConfig.autoLogin,
      loadChildren: () => import('./components/login-link/login-link.module').then(m => m.LoginLinkModule),
    },
    {
      path: '**',
      redirectTo: Config.RouterConfig.login,
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
