import * as Models from '../../../../../libs/models/models';

export class ContactConfig {

  static phones: Models.IContact[] = [
    {
      label: '+38(063)245-25-23',
      contact: 'tel:+380632452523'
    }
  ];

  static emails: Models.IContact[] = [
    {
      label: 'mailto:info@bananacredit.com.ua',
      contact: 'info@bananacredit.com.ua'
    }
  ];
}
