import {Component, EventEmitter, Input, Output} from '@angular/core';
import {WebcamImage} from 'ngx-webcam';
import {filter, switchMap, takeUntil} from 'rxjs/internal/operators';
import {HttpEventType} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UnsubscribeService} from '../../services/unsubscribe.service';
import {ScannerService} from '../../../services/scanner.service';
import {CalculateStore} from '../../storage/calculate.store';
import {ClientStore} from '../../storage/client.store';
import {GroupType} from '../../../types/group.type';
import {ClientModel} from '../../../models/client.model';

@Component({
  selector: 'app-selfie-mode-widget',
  templateUrl: './selfie-mode-widget.component.html',
  styleUrls: ['./selfie-mode-widget.component.sass'],
})
export class SelfieModeWidgetComponent {
  @Input()
  public group: GroupType;

  @Output()
  public readonly onManualInput: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public webcamImage: WebcamImage = null;
  public isSelfie: boolean = false;
  public errorPhoto: boolean = false;
  public isPreview$: Observable<boolean>;

  constructor(
    private destroyStream$: UnsubscribeService,
    private scanService: ScannerService,
    private calculateStore: CalculateStore,
    private clientStore: ClientStore
  ) {
    this.isPreview$ = this.calculateStore.isPreviewVisible;
  }

  public handleImage(webcamImage: WebcamImage): any {
    this.webcamImage = webcamImage;
    webcamImage ? this.sendSelfie(webcamImage) : null;
  }

  public handlePermission(event): void {
    this.onManualInput.emit(event);
  }

  public emitManual(state: boolean): void {
    this.onManualInput.emit(state);
  }

  public createNewPhoto(): void {
    this.webcamImage = null;
    this.errorPhoto = false;
  }

  sendSelfie(img: any) {
    const file = img.imageAsBase64;
    const contentType = 'image/png';
    const blob = SelfieModeWidgetComponent.b64toBlob(file, contentType);
    this.uploadPhoto(blob);
  }

  private static b64toBlob(
    b64Data,
    contentType: string = '',
    sliceSize: number = 512
  ): Blob {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
  }

  public uploadPhoto(blob): void {
    const photoData = new FormData();
    photoData.append('file', blob);
    this.scanService
      .postSelfie(photoData)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        switchMap(() => this.clientStore.updateClient()),
        takeUntil(this.destroyStream$)
      )
      .subscribe(
        (event: ClientModel) => {
          this.onManualInput.emit(true);
        },
        (error) => {
          this.errorPhoto = true;
          this.onManualInput.emit(false);
          console.error(error);
        }
      );
  }
}
