<ng-container [ngSwitch]="group">

  <ng-container *ngSwitchCase="'AVANS'">
    <section  class="auth-confirm auth-confirm-avans d-flex flex-column align-items-center justify-content-start">
      <div class="auth-confirm-content">
        <h2 class="auth-confirm-title text-center">{{'AUTH.TITLE'  | translate}}</h2>
        <p class="auth-confirm-text" *ngIf="verificationType === 'SMS'">
          <span [innerHTML]="'CONFIRM.INFO_1' | translate : {number: phoneNumber}"></span>
          <span class="pl-1 cursor-pointer font-weight-bold"
                (click)="editPhone()">({{'AUTH.EDIT' | translate }})</span>
          <span class="d-inline-block" [innerHTML]="'CONFIRM.INFO_2' | translate"></span>
        </p>
        <p class="auth-confirm-text" *ngIf="verificationType === 'PHONE'">
          <span [innerHTML]="'CONFIRM.INFO_PHONE_1' | translate : {number: phoneNumber}"></span>
          <span class="pl-1 cursor-pointer font-weight-bold"
                (click)="editPhone()">({{'AUTH.EDIT' | translate }})</span>
          <span class="d-inline-block mt-3" [innerHTML]="'CONFIRM.INFO_PHONE_2' | translate"></span>
        </p>
      </div>
      <div class="auth-confirm-footer" [formGroup]="form">
        <div class="mb-3">
          <app-sms-code-widget formControlName="code"
                               [placeholder]="''"
                               classes="text-center"
                               [verificationAction]="loginAction"
                               [verificationType]="verificationType"
                               [type]="'avans'"
          ></app-sms-code-widget>
          <div class="mt-3" [class.d-none]="timeOut">
            <app-countdown-container [verificationType]="verificationType"
                                     [initialState]="initialState"
                                     [counterType]="'TIMER'"
                                     [group]="group"
                                     (breakpointOutEvent)="breakpointOutHandler($event)"
                                     (timeOutEvent)="timeOutEventHandler($event)"
            ></app-countdown-container>
          </div>
        </div>
        <ng-container *ngIf="breakpointTimeOut">
          <app-resend-sms-widget [timeOut]="timeOut"
                                 [showPhone]="showPhone"
                                 [group]="group"
                                 [showAuthMessage]="true"
                                 (verifyWayEvent)="wayVerificationHandler($event)"
          ></app-resend-sms-widget>
        </ng-container>
        <div class="mt-2">
          <app-auth-agreement formGroupName="agreement"
                              labelClasses="text-justify"></app-auth-agreement>
        </div>
      </div>
    </section>
  </ng-container>

  <ng-container *ngSwitchCase="'STARFIN'">
    <section  class="auth-confirm auth-confirm-starfin d-flex flex-column align-items-center justify-content-start">
      <div class="auth-confirm-content">
        <h2 class="auth-confirm-title text-center">{{'AUTH.TITLE'  | translate}}</h2>
        <p class="auth-confirm-text mb-2" *ngIf="verificationType === 'SMS'">
          <span [innerHTML]="'CONFIRM.INFO_1' | translate : {number: phoneNumber}"></span>
          <span class="pl-1 cursor-pointer font-weight-bold"
                (click)="showPhoneEditorEvent.emit()">{{'AUTH.EDIT' | translate }}</span>
          <span class="d-inline-block" [innerHTML]="'CONFIRM.INFO_2' | translate"></span>
        </p>
        <p class="auth-confirm-text mb-3" *ngIf="verificationType === 'PHONE'">
          <span [innerHTML]="'CONFIRM.INFO_PHONE_1' | translate : {number: phoneNumber}"></span>
          <span class="pl-1 cursor-pointer font-weight-bold"
                (click)="showPhoneEditorEvent.emit()">{{'AUTH.EDIT' | translate }}</span>
          <span class="d-inline-block mt-3" [innerHTML]="'CONFIRM.INFO_PHONE_2' | translate"></span>
        </p>
      </div>
      <div class="auth-confirm-footer" [formGroup]="form">
        <div class="mb-3">
          <app-sms-code-widget formControlName="code"
                               [placeholder]="''"
                               classes="text-center"
                               [verificationAction]="loginAction"
                               [verificationType]="verificationType"
                               [type]="'starfin'"
          ></app-sms-code-widget>
          <div class="mt-4" [class.d-none]="timeOut">
            <app-countdown-container [verificationType]="verificationType"
                                     [initialState]="initialState"
                                     [counterType]="'TIMER'"
                                     [group]="group"
                                     classes="countdown-starfin"
                                     [showProgressBar]="true"
                                     (breakpointOutEvent)="breakpointOutHandler($event)"
                                     (timeOutEvent)="timeOutEventHandler($event)"
            ></app-countdown-container>
          </div>
        </div>
        <ng-container *ngIf="breakpointTimeOut">
          <app-resend-sms-widget [timeOut]="timeOut"
                                 [showPhone]="showPhone"
                                 [group]="group"
                                 [showAuthMessage]="true"
                                 (verifyWayEvent)="wayVerificationHandler($event)"
          ></app-resend-sms-widget>
        </ng-container>
        <div class="auth-agreement mt-3">
          <app-auth-agreement formGroupName="agreement"
                              labelClasses="fs-12 text-left"></app-auth-agreement>
        </div>
      </div>
    </section>
  </ng-container>

  <ng-container *ngSwitchCase="'SUNCREDIT'">
    <section  class="auth-confirm auth-confirm-suncredit d-flex flex-column align-items-center justify-content-start">
      <div class="auth-confirm-content">
        <h2 class="auth-confirm-title text-center title-decor">{{'AUTH.TITLE'  | translate}}</h2>
        <div class="auth-editor">
          <div class="auth-editor-container" *ngIf="verificationType === 'SMS'">
            <p class="auth-editor-header text-center">{{'CONFIRM.INFO_1' | translate}}</p>
            <div class="auth-editor-control d-flex flex-column" (click)="showPhoneEditorEvent.emit()">
              <span class="auth-editor-label">Телефонний номер</span>
              <span class="auth-editor-value">{{phoneNumber}}</span>
              <span class="auth-editor-btn"></span>
            </div>
            <p class="auth-editor-info text-center" [innerHTML]="'CONFIRM.INFO_2' | translate"></p>
          </div>
          <div class="auth-editor-container" *ngIf="verificationType === 'PHONE'">
            <p class="auth-editor-header text-center">{{'CONFIRM.INFO_PHONE_1' | translate}}</p>
            <div class="auth-editor-control d-flex flex-column" (click)="showPhoneEditorEvent.emit()">
              <span class="auth-editor-label">Телефонний номер</span>
              <span class="auth-editor-value">{{phoneNumber}}</span>
              <span class="auth-editor-btn"></span>
            </div>
            <p class="auth-editor-info text-center" [innerHTML]="'CONFIRM.INFO_PHONE_2' | translate"></p>
          </div>
        </div>
      </div>
      <div class="auth-confirm-footer" [formGroup]="form">
        <div class="mb-3">
          <app-sms-code-widget formControlName="code"
                               [placeholder]="''"
                               classes="text-center"
                               [verificationAction]="loginAction"
                               [verificationType]="verificationType"
                               [type]="'suncredit'"
          ></app-sms-code-widget>
          <div class="mt-3" [class.d-none]="timeOut">
            <app-countdown-container [verificationType]="verificationType"
                                     [initialState]="initialState"
                                     [counterType]="'TIMER'"
                                     [group]="group"
                                     classes="countdown-suncredit"
                                     [showProgressBar]="false"
                                     (breakpointOutEvent)="breakpointOutHandler($event)"
                                     (timeOutEvent)="timeOutEventHandler($event)"
            ></app-countdown-container>
          </div>
        </div>
        <ng-container *ngIf="breakpointTimeOut">
          <app-resend-sms-widget [timeOut]="timeOut"
                                 [showPhone]="showPhone"
                                 [group]="group"
                                 [showAuthMessage]="true"
                                 (verifyWayEvent)="wayVerificationHandler($event)"
          ></app-resend-sms-widget>
        </ng-container>
        <div class="auth-agreement mt-3">
          <app-auth-agreement formGroupName="agreement"
                              labelClasses="fs-12 text-left"
                              imgSize="32px"></app-auth-agreement>
        </div>
      </div>
    </section>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <section  class="confirm-auth">
      <div class="auth-landscape">
        <div class="auth-landscape__body">
          <div class="auth-landscape-content">
            <h2 class="auth-landscape__title">{{'AUTH.TITLE'  | translate}}</h2>
            <p class="auth-landscape__text" *ngIf="verificationType === 'SMS'">
              <span [innerHTML]="'CONFIRM.INFO_1' | translate : {number: phoneNumber}"></span>
              <span class="pl-1 cursor-pointer font-weight-bold"
                    (click)="editPhone()">{{'AUTH.EDIT' | translate }}</span>
              <span class="d-inline-block" [innerHTML]="'CONFIRM.INFO_2' | translate"></span>
            </p>
            <p class="auth-landscape__text" *ngIf="verificationType === 'PHONE'">
              <span [innerHTML]="'CONFIRM.INFO_PHONE_1' | translate : {number: phoneNumber}"></span>
              <span class="pl-1 cursor-pointer font-weight-bold"
                    (click)="editPhone()">{{'AUTH.EDIT' | translate }}</span>
              <span class="d-inline-block" [innerHTML]="'CONFIRM.INFO_PHONE_2' | translate"></span>
            </p>
          </div>
        </div>
      </div>
      <div class="auth-confirm-content" [formGroup]="form">
        <div class="mt-3 mb-3">
          <app-sms-code-widget formControlName="code"
                               [placeholder]="''"
                               classes="text-center"
                               [verificationAction]="loginAction"
                               [verificationType]="verificationType"
          ></app-sms-code-widget>
          <div class="mt-3" [class.d-none]="timeOut">
            <app-countdown-container [verificationType]="verificationType"
                                     [initialState]="initialState"
                                     [counterType]="'TIMER'"
                                     [group]="group"
                                     (breakpointOutEvent)="breakpointOutHandler($event)"
                                     (timeOutEvent)="timeOutEventHandler($event)"
            ></app-countdown-container>
          </div>
        </div>
        <ng-container *ngIf="breakpointTimeOut">
          <app-resend-sms-widget [timeOut]="timeOut"
                                 [showPhone]="showPhone"
                                 [group]="group"
                                 [showAuthMessage]="true"
                                 (verifyWayEvent)="wayVerificationHandler($event)"
          ></app-resend-sms-widget>
        </ng-container>
        <div class="mt-2">
          <app-auth-agreement formGroupName="agreement"
                              labelClasses="text-justify"></app-auth-agreement>
        </div>
      </div>
    </section>
  </ng-container>

</ng-container>
