import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UrlService} from './url.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(private http: HttpClient, private urlService: UrlService) {}

  public sendErrors(
    type: string,
    errors: any,
    website: string,
    url: string,
    phone?: string,
    token?: string,
    errorLocation?: string
  ) {
    if (errors.error) {
      errors = errors.error.message;
    }

    return this.http.post(
      `${this.urlService.loggerUrl}/${type}?website=${website}&phone=${phone}&token=${token}&message=${errors}&url=${url}&errorLocation=${errorLocation}`,
      {}
    );
  }
}
