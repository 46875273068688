import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LastApplicationStorage } from '../shared/storage/last-application.storage';
import * as Config from '../configs/general-config';
import * as moment from 'moment';

@Injectable()
export class ApplicationGuard implements CanActivate {

    constructor(private router: Router,
                private lastApplicationStore: LastApplicationStorage) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.lastApplicationStore.get().map(lastLoan => {
            const canActivated = !lastLoan.status
                || (lastLoan.nextPossibleAt && moment(lastLoan.nextPossibleAt, Config.StaticDataConfig.DATE_FORMAT).isBefore(moment.now()));
            if (canActivated) {
                return true;
            } else {
                this.router.navigate(['main', 'profile']);
                return false;
            }
        });
    }
}
