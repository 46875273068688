import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormGroupDirective} from '@angular/forms';

@Component({
  selector: 'app-auth-agreement',
  templateUrl: './auth-agreement.component.html',
  styleUrls: ['./auth-agreement.component.sass'],
})
export class AuthAgreementComponent implements OnInit {
  @Input()
  private formGroupName: string;

  @Input()
  public labelClasses: string = '';

  @Input()
  public imgSize: string = '24px';

  public form: FormGroup;
  public accept: string = `<span >Я ознайомлений(а) з </span>
                           <a class="d-inline" target="_blank" href="../../../assets/docs/pravila_nadannja_kowtiv_y_poziky.pdf">
                           <span>Правилами надання коштів у позику</span></a>,
                           <span>в тому числі і на умовах фінансового кредиту (Публічною пропозицією), та даю свою </span>
                           <a class="d-inline" target="_blank" href="../../../assets/docs/pravila_obrobki_personalnih_danih.pdf">
                           <span>Згоду на обробку персональних даних.</span></a>
                           <p class="label-description fs-12">Підтверджую, що не є військовослужбовцем в розумінні чинного законодавства
                              та на мене не розповсюджується дія Закону України "Про соціальний і правовий захист
                              військовослужбовців та членів їх сімей"</p>`;

  constructor(
    private formBuilder: FormBuilder,
    private rootFormGroup: FormGroupDirective
  ) {}

  public ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }
}
