import {NgModule} from '@angular/core';
import {AuthRoutingModule} from './auth-routing.module';
import {AuthLoginModule} from './components/auth-login/auth-login.module';
import {RouterModule} from '@angular/router';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {AuthComponent} from './auth.component';

@NgModule({
  declarations: [AuthComponent,],
  imports: [AuthRoutingModule, AuthLoginModule, RouterModule],
  providers: [AuthenticationService],
})
export class AuthModule {}
