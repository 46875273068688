<section class="auth"
         [class.auth_indent]="isPreview$ | async"
         [ngClass]="'auth-'+ type">
  <div class="auth-content">

    <app-auth-phone *ngIf="!isAuthConfirm && !isResend && !isAuthCallConfirm"
                    #phoneCom
                    [group]="group"
                    [production]="production"
                    (emitData)='setValues($event)'
                    (emitPhone)='setPhone($event)'></app-auth-phone>

    <app-auth-auto-confirm *ngIf="isAuthCallConfirm"
                           [group]="group"
                           [withCaptcha]="withCaptcha"
                           [phone]="phone"
                           [showPhone]="showPhone"
                           (redirectClientEvent)="redirectClientHandler($event)"
                           (editPhoneEvent)="editPhoneHandler($event)"
                           (showPhoneEditorEvent)="showPhoneEditorModal()"
                           (timeGoneEvent)="timeGoneHandler()"
                           (verifyWayEvent)="chooseWayRepeat($event)"></app-auth-auto-confirm>

    <app-auth-confirm *ngIf='isAuthConfirm'
                      [group]="group"
                      [phone]='phone'
                      [showPhone]="showPhone"
                      [verificationType]='verificationType'
                      (redirectClientEvent)="redirectClientHandler($event)"
                      (timeGoneEvent)='timeGoneHandler()'
                      (editPhoneEvent)="editPhoneHandler($event)"
                      (showPhoneEditorEvent)="showPhoneEditorModal()"
                      (verifyWayEvent)='chooseWayRepeat($event)'></app-auth-confirm>

    <app-auth-repeat *ngIf='isResend'
                     [group]="group"
                     [showPhone]="showPhone"
                     (wayVerif)='chooseWayRepeat($event)'></app-auth-repeat>

  </div>
</section>

