import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {VerificationType} from '../../../enums/verification-type.enum';
import {GroupType} from '../../../types/group.type';
import {CounterWidgetComponent} from '../counter-widget/counter-widget.component';

@Component({
  selector: 'app-countdown-container',
  templateUrl: './countdown-container.component.html',
  styleUrls: ['./countdown-container.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountdownContainerComponent {
  @Input()
  public verificationType: VerificationType;

  @Input()
  public initialState: {
    count: number;
    breakpoint?: number;
    diameter?: number;
    strokeWidth?: number;
  } = {
    count: 60,
    breakpoint: 10,
    diameter: 90,
    strokeWidth: 5,
  };

  @Input()
  public group: GroupType;

  @Input()
  public counterType: 'TIMER' | 'COUNTDOWN' = 'TIMER';

  @Input()
  public classes: string = '';

  @Input()
  public showProgressBar: boolean;

  @Output()
  public readonly breakpointOutEvent = new EventEmitter<boolean>();

  @Output()
  public readonly timeOutEvent = new EventEmitter<boolean>();

  public progressValue: string = '100%';

  @ViewChild(CounterWidgetComponent)
  private counterWidget: CounterWidgetComponent;

  public tickEventEventHandler(sec: number): void {
    this.progressValue = `${100 - (sec * 100) / this.initialState.count - 1}%`;
  }

  public restartTimer(): void {
    this.counterWidget.restartTimer.next();
    this.counterWidget.initTimer();
  }
}
