<div class="d-flex flex-column align-items-center justify-content-between" [ngClass]="classes">
  <div class="toaster-title text-center"
       *ngIf="data.title"
       [innerHTML]="data.title | translate">
  </div>
  <div class="toaster-content"
       *ngIf="data.content?.message"
       [translate]="'errors.'+ data.content?.message">
  </div>
  <div class="toaster-content"
       *ngIf="data.content?.loggerCode"
       [innerHTML]="'errors.unexpectedError' | translate: {code: data.content?.loggerCode}">
  </div>
  <div class="toaster-content" *ngIf="data.content?.errors?.length">
    <ng-container *ngFor="let err of data.content?.errors">
      <p class="toaster-error__text">
        <span [translate]="'client.'+ err.field"></span>:
        <span class="pl-1" [translate]="err.message"></span>
      </p>
    </ng-container>
  </div>
</div>
