export interface NavItem {
  label: string;
  route: string;
  img?: {
    url: string;
    height?: string;
    width?: string;
  }
}

export const navItems: NavItem[] = [
  {
    label: 'NAVIGATION.LOAN',
    route: 'main/loan',
  },
  {
    label: 'NAVIGATION.PROFILE',
    route: 'main/profile',
  },
  {
    label: 'NAVIGATION.DOCUMENTS',
    route: 'main/contracts',
  },
  {
    label: 'NAVIGATION.UPLOAD',
    route: 'main/upload-documents',
  },
];

