<section [ngSwitch]="type">
  <div
    class="spinner-segment d-flex justify-content-center"
    *ngSwitchCase="'SEGMENT'"
  >
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgba(255, 255, 255, 0); display: block;" width="61px" height="61px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <g transform="rotate(0 50 50)">
      <rect x="46" y="3.5" rx="4" ry="4.76" width="8" height="17" fill="#fc7128">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(30 50 50)">
      <rect x="46" y="3.5" rx="4" ry="4.76" width="8" height="17" fill="#fc7128">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(60 50 50)">
      <rect x="46" y="3.5" rx="4" ry="4.76" width="8" height="17" fill="#fc7128">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(90 50 50)">
      <rect x="46" y="3.5" rx="4" ry="4.76" width="8" height="17" fill="#fc7128">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(120 50 50)">
      <rect x="46" y="3.5" rx="4" ry="4.76" width="8" height="17" fill="#fc7128">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(150 50 50)">
      <rect x="46" y="3.5" rx="4" ry="4.76" width="8" height="17" fill="#fc7128">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(180 50 50)">
      <rect x="46" y="3.5" rx="4" ry="4.76" width="8" height="17" fill="#fc7128">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(210 50 50)">
      <rect x="46" y="3.5" rx="4" ry="4.76" width="8" height="17" fill="#fc7128">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(240 50 50)">
      <rect x="46" y="3.5" rx="4" ry="4.76" width="8" height="17" fill="#fc7128">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(270 50 50)">
      <rect x="46" y="3.5" rx="4" ry="4.76" width="8" height="17" fill="#fc7128">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(300 50 50)">
      <rect x="46" y="3.5" rx="4" ry="4.76" width="8" height="17" fill="#fc7128">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(330 50 50)">
      <rect x="46" y="3.5" rx="4" ry="4.76" width="8" height="17" fill="#fc7128">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
      </rect>
    </g>
    </svg>
  </div>

  <div
    class="spinner-dot d-flex justify-content-center"
    *ngSwitchCase="'BUBBLES'"
  >
    <div class="spinner-dot-icon"></div>
  </div>

  <div class="spinner-circle"  *ngSwitchCase="'CIRCLE'">
    <svg class="spinner-circle-icon" viewBox="0 0 50 50">
      <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
    </svg>
  </div>

  <div class="loader" *ngSwitchDefault>
    <div class="loader__item loader__item_one"></div>
    <div class="loader__item loader__item_two"></div>
    <div class="loader__item loader__item_three"></div>
  </div>
</section>
