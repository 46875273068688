import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import * as Config from '../../configs/general-config';
import {takeUntil} from "rxjs/operators";
import {UnsubscribeService} from "../services/unsubscribe.service";


@Injectable()
export class LanguageStore {
  private storage: Storage = sessionStorage;
  public currentLang = new BehaviorSubject(Config.LanguageConfig.ua);

  constructor(private translate: TranslateService,
              private destroyStream$: UnsubscribeService) {
    this.translate.onLangChange.subscribe(response => this.setLang(response.lang));
    this.getStorageLang();
  }

  public getStorageLang() {
    this.getLang().pipe(
      takeUntil(this.destroyStream$)
    ).subscribe(() => {
      const lang = this.storage.getItem('lang')
      lang !== null ? this.translate.use(lang): '';
      return lang;
    });
  }

  public getLang(): Observable<any> {
    return this.currentLang.asObservable();
  }

  public setLang(lang: any) {
    this.storage.setItem('lang',lang);
    this.currentLang.next(lang);
  }
}
