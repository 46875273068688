import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {defineLocale, ruLocale} from 'ngx-bootstrap/chronos';
import {Meta} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';
import {takeUntil} from 'rxjs/operators';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {LanguageStore} from '../../shared/storage/language.store';
import {UnsubscribeService} from '../../shared/services/unsubscribe.service';
import {CustomEnvironmentService} from '../../services/custom-environment.service';
import {NavItem, navItems} from './nav-items';
import {DeviceService} from '../../services/device.service';
import {ClientStore} from '../../shared/storage/client.store';
import * as Models from '../../models/models';
import * as Config from '../../../libs/configs/general-config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  public ENV = this._customEnvironmentService.environment;
  public isActive: boolean = false;
  public isLogged$: Observable<boolean>;
  public tabletWide$: Observable<boolean>;
  public isMenu$: Observable<boolean>;
  public selectedLang: string = Config.LanguageConfig.ua;
  public languages: Models.ILanguage = Config.LanguageConfig.languages;
  public locales: Models.IDatepicker = Config.DatepickerConfig.DatepickerLang;
  private html = this.document.querySelector('html');
  public readonly navItems: NavItem[] = navItems;

  constructor(
    private translate: TranslateService,
    private authService: AuthenticationService,
    private router: Router,
    private langStore: LanguageStore,
    private meta: Meta,
    private _customEnvironmentService: CustomEnvironmentService,
    private clientStore: ClientStore,
    private readonly deviceService: DeviceService,
    private destroyStream$: UnsubscribeService,
    @Inject(DOCUMENT) private document: Document
  ) {
    defineLocale('ru', ruLocale);
    this.tabletWide$ = this.deviceService.isTabletWide;
    this.isLogged$ = this.authService.isLoggedIn();
    this.isMenu$ = this.clientStore.isMenuVisible$;
  }

  public get isBorder(): boolean {
    return !['/form/profile', '/form/busyness', '/system/scanner'].includes(
      this.router.url
    );
  }

  public switchLanguage(lang: string): void {
    if (lang === Config.LanguageConfig.ua) {
      this.translate.use(Config.LanguageConfig.ru);
      this.html.setAttribute('lang', Config.LanguageConfig.ru);
      this.meta.updateTag({
        property: 'og:locale',
        content: Config.LanguageConfig.languages['ru'].ogLocale,
      });
      this.langStore.setLang(Config.LanguageConfig.ru);
      this.selectedLang = Config.LanguageConfig.ru;
    } else {
      this.translate.use(Config.LanguageConfig.ua);
      this.html.setAttribute('lang', Config.LanguageConfig.ua);
      this.meta.updateTag({
        property: 'og:locale',
        content: Config.LanguageConfig.languages['ua'].ogLocale,
      });
      this.langStore.setLang(Config.LanguageConfig.ua);
      this.selectedLang = Config.LanguageConfig.ua;
    }
  }

  public ngOnInit(): void {
    this.langStore
      .getLang()
      .pipe(takeUntil(this.destroyStream$))
      .subscribe((lang: string) => {
        this.selectedLang = lang;
      });
    defineLocale(
      this.locales[Config.LanguageConfig.ua]['lang'],
      this.locales[Config.LanguageConfig.ua]['locale']
    );
  }

  public hamburgerEventHandler() {
    this.isActive = !this.isActive;
    this.isActive
      ? (window.onscroll = () => {
          window.scrollTo(0, 0);
        })
      : (window.onscroll = () => {
          window.scrollTo();
        });
  }

  public logout(): void {
    this.authService.logout();
    this.router.navigate(['auth', 'login']);
  }
}
