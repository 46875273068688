import {ErrorHandler, Injectable} from '@angular/core';
import {ErrorHandlerService} from '../services/error-handler.service';
import {CustomEnvironmentService} from '../services/custom-environment.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private ENV = this._customEnvironmentService.environment;

  constructor(
    public errorHandlerService: ErrorHandlerService,
    private _customEnvironmentService: CustomEnvironmentService
  ) {}

  handleError(error: any) {
    const stackLines = error.stack.split('\n');
    let errorLocation: string = '';
    if (stackLines.length > 1) {
      errorLocation = stackLines[1].trim();
    }
    const website = this.ENV.name;
    const token = sessionStorage.getItem('jwt_token');
    const phone = sessionStorage.getItem('phone');
    const url = window.location.pathname;
    if (this.ENV.production) {
      this.errorHandlerService
        .sendErrors('ERROR', error, website, url, phone, token, errorLocation)
        .subscribe(() => {});
    }
  }
}
