import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {VerificationType} from '../../../enums/verification-type.enum';
import {GroupType} from '../../../types/group.type';

@Component({
  selector: 'app-resend-sms-widget',
  templateUrl: './resend-sms-widget.component.html',
  styleUrls: ['./resend-sms-widget.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResendSmsWidgetComponent {

  @Input()
  public showSignMessage: boolean;

  @Input()
  public showAuthMessage: boolean;

  @Input()
  public timeOut: boolean;

  @Input()
  public showPhone: boolean;

  @Input()
  public group: GroupType;

  @Input()
  public classes: string = '';

  @Output()
  public readonly verifyWayEvent = new EventEmitter<VerificationType>();

  public verificationType = VerificationType;

  public resend(type): void {
    this.verifyWayEvent.emit(type);
  }
}
