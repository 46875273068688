export enum BusynessTypeEnum {

    WORKING_MYSELF = 'WORKING_MYSELF', // работаю на себя
    EMPLOYER = 'EMPLOYER', // разнорабочий/специалист
    HEAD_OF_UNIT = 'HEAD_OF_UNIT', // руководитель подразделения
    CHIEF_EXECUTIVE = 'CHIEF_EXECUTIVE', // руководитель высшего звена
    PENSIONER = 'PENSIONER', // пенсионер
    STUDENT = 'STUDENT', // студент
    MILITARY = 'MILITARY', // военнослужащий
    MVD = 'MVD', // работник правохранительных органов
    UNEMPLOYED = 'UNEMPLOYED'  // нищеброд
}
