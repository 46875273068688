import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {NavItem} from "@libs/models/nav-item.interface";

@Component({
  selector: 'app-header-desktop-navigation',
  templateUrl: './header-desktop-navigation.component.html',
  styleUrls: ['./header-desktop-navigation.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderDesktopNavigationComponent {

  @Input() public items: NavItem[] = [];

}
