import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.sass'],
})
export class LoaderComponent {
  @Input()
  public type: 'BUBBLES' | 'SEGMENT' | 'CIRCLE';
}
