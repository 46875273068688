export class PersonalDataModel {

  public firstName: string;
  public lastName: string;
  public middleName: string;
  public mobilePhone: string;
  public financialPhone: string;
  public inn: string;
  public regionType: string;
  public region: string;
  public email: string;

  public constructor(init?: Partial<PersonalDataModel>) {
    Object.assign(this, init);
  }

}
