<header class="header" [class.header_border]="isBorder">
  <div class="header-container" *ngIf="tabletWide$ | async; else desktop">
    <ng-container *ngIf="(isLogged$ && isMenu$) | async">
      <app-header-mobile-navigation [items]="navItems"
                                    [active]="isActive"
                                    (itemClickEvent)="hamburgerEventHandler()"
      ></app-header-mobile-navigation>
      <div class="hamburger"
           [ngClass]="{'hamburger_active': isActive}" (click)="hamburgerEventHandler()">
        <span class="hamburger__line"></span>
      </div>
    </ng-container>
    <div class="logo logo_header">
      <a [href]="ENV.WEBSITE_URL"><img [src]="ENV.LOGO" alt="logo"></a>
    </div>
    <div class="header-controls d-flex align-items-center">
      <div class="header-phone">
        <a [href]="ENV.CONTACT">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.8881 15.6381V18.4952C20.8892 18.7605 20.8322 19.023 20.7206 19.266C20.6091 19.5091 20.4454 19.7272 20.2402 19.9065C20.035 20.0858 19.7927 20.2224 19.5289 20.3073C19.265 20.3923 18.9855 20.4239 18.7081 20.4C15.631 20.0816 12.6751 19.0801 10.0781 17.4762C7.66194 16.014 5.61345 14.063 4.07812 11.7619C2.38809 9.27733 1.33636 6.44855 1.00812 3.50475C0.983127 3.24139 1.01599 2.97595 1.10462 2.72534C1.19324 2.47474 1.33569 2.24445 1.52288 2.04915C1.71008 1.85385 1.93792 1.69781 2.19191 1.59096C2.44589 1.48412 2.72046 1.42881 2.99812 1.42856H5.99812C6.48342 1.42401 6.95391 1.58768 7.32188 1.88907C7.68985 2.19045 7.93019 2.60898 7.99812 3.06665C8.12474 3.981 8.35957 4.87878 8.69812 5.74285C8.83266 6.08373 8.86178 6.45419 8.78202 6.81035C8.70227 7.16651 8.51698 7.49343 8.24812 7.75237L6.97812 8.96189C8.40167 11.3462 10.4746 13.3204 12.9781 14.6762L14.2481 13.4667C14.52 13.2106 14.8633 13.0341 15.2372 12.9582C15.6112 12.8822 16.0002 12.9099 16.3581 13.0381C17.2654 13.3605 18.2081 13.5842 19.1681 13.7048C19.6539 13.77 20.0975 14.003 20.4146 14.3595C20.7318 14.716 20.9003 15.171 20.8881 15.6381Z"
              stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
      </div>
      <div class="logout d-flex align-items-center" *ngIf="isLogged$ | async">
        <div (click)="logout()" class="logout__btn">
          <svg class="logout-icon" width="22" height="22" viewBox="0 0 22 22" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.25 19.25H4.58333C4.0971 19.25 3.63079 19.0568 3.28697 18.713C2.94315 18.3692 2.75 17.9029 2.75 17.4167V4.58333C2.75 4.0971 2.94315 3.63079 3.28697 3.28697C3.63079 2.94315 4.0971 2.75 4.58333 2.75H8.25"
              stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.6667 15.5834L19.25 11L14.6667 6.41669" stroke="white" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M19.25 11H8.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</header>

<ng-template #desktop>
  <div class="header-container">
    <div class="logo logo_header">
      <a [href]="ENV.WEBSITE_URL"><img [src]="ENV.LOGO" alt="logo"></a>
    </div>
    <app-header-desktop-navigation *ngIf="(isLogged$ && isMenu$) | async"
                                   [items]="navItems"></app-header-desktop-navigation>
    <div class="header-controls d-flex align-items-center">
      <div class="header-phone">
        <a [href]="ENV.CONTACT">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.8881 15.6381V18.4952C20.8892 18.7605 20.8322 19.023 20.7206 19.266C20.6091 19.5091 20.4454 19.7272 20.2402 19.9065C20.035 20.0858 19.7927 20.2224 19.5289 20.3073C19.265 20.3923 18.9855 20.4239 18.7081 20.4C15.631 20.0816 12.6751 19.0801 10.0781 17.4762C7.66194 16.014 5.61345 14.063 4.07812 11.7619C2.38809 9.27733 1.33636 6.44855 1.00812 3.50475C0.983127 3.24139 1.01599 2.97595 1.10462 2.72534C1.19324 2.47474 1.33569 2.24445 1.52288 2.04915C1.71008 1.85385 1.93792 1.69781 2.19191 1.59096C2.44589 1.48412 2.72046 1.42881 2.99812 1.42856H5.99812C6.48342 1.42401 6.95391 1.58768 7.32188 1.88907C7.68985 2.19045 7.93019 2.60898 7.99812 3.06665C8.12474 3.981 8.35957 4.87878 8.69812 5.74285C8.83266 6.08373 8.86178 6.45419 8.78202 6.81035C8.70227 7.16651 8.51698 7.49343 8.24812 7.75237L6.97812 8.96189C8.40167 11.3462 10.4746 13.3204 12.9781 14.6762L14.2481 13.4667C14.52 13.2106 14.8633 13.0341 15.2372 12.9582C15.6112 12.8822 16.0002 12.9099 16.3581 13.0381C17.2654 13.3605 18.2081 13.5842 19.1681 13.7048C19.6539 13.77 20.0975 14.003 20.4146 14.3595C20.7318 14.716 20.9003 15.171 20.8881 15.6381Z"
              stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
      </div>
      <div class="logout d-flex align-items-center" *ngIf="isLogged$ | async">
        <div (click)="logout()" class="logout__btn">
          <svg class="logout-icon" width="22" height="22" viewBox="0 0 22 22" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.25 19.25H4.58333C4.0971 19.25 3.63079 19.0568 3.28697 18.713C2.94315 18.3692 2.75 17.9029 2.75 17.4167V4.58333C2.75 4.0971 2.94315 3.63079 3.28697 3.28697C3.63079 2.94315 4.0971 2.75 4.58333 2.75H8.25"
              stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.6667 15.5834L19.25 11L14.6667 6.41669" stroke="white" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M19.25 11H8.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</ng-template>
