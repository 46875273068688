import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {AuthAgreementModule} from '../auth-agreement/auth-agreement.module';
import {AuthAutoConfirmComponent} from './auth-auto-confirm.component';
import {TimerModule} from '../../../../shared/components/timer/timer.module';
import {CounterWidgetModule} from '../../../../shared/components/counter-widget/counter-widget.module';
import {ResendSmsWidgetModule} from '../../../../shared/components/resend-sms-widget/resend-sms-widget.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CountdownContainerModule} from '../../../../shared/components/countdown-container/countdown-container.module';

@NgModule({
  declarations: [AuthAutoConfirmComponent],
  imports: [
    CommonModule,
    TranslateModule,
    AuthAgreementModule,
    TimerModule,
    CounterWidgetModule,
    ResendSmsWidgetModule,
    ReactiveFormsModule,
    FormsModule,
    CountdownContainerModule,
  ],
  exports: [AuthAutoConfirmComponent]
})
export class AuthAutoConfirmModule {
}
