import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SystemComponent} from './system.component';
import * as Config from '../../configs/general-config';
import {ApplicationGuard} from '../../guards/application.guard';
import {IdentGuard} from '../../guards/ident.guard';

const routes: Routes = [
  {
    path: '',
    component: SystemComponent,
    children: [
      {
        path: Config.RouterConfig.financeNumber,
        loadChildren: () => import('./components/finance-number/finance-number.module').then(m => m.FinanceNumberModule),
      },
      {
        path: Config.RouterConfig.scanner,
        loadChildren: () => import('./components/document-scanner/document-scanner.module').then(m => m.DocumentScannerModule),
      },
      {
        path: Config.RouterConfig.application,
        loadChildren: () => import('./components/apply/apply.module').then(m => m.ApplyModule),
        canActivate: [
          ApplicationGuard, IdentGuard
        ]
      },
      {
        path: '**',
        redirectTo: Config.RouterConfig.profile
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SystemRoutingModule {
}

