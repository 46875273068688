import {Component, OnInit, Input} from '@angular/core';
import {CardService} from '../../../../../services/card.service';

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.sass']
})
export class AddCardComponent implements OnInit {

  @Input()
  public hasCard: boolean; // todo get from card storage and check
  public successUrl: string;
  public errorUrl: string;

  constructor(private cardService: CardService) {
  }

  public ngOnInit(): void {
    this.successUrl = window.location.origin + '/system/application?addedCard=1';
    this.errorUrl = window.location.origin;
  }

  public addCard(successUrl: string, errorUrl: string): void {
    this.cardService.getRegisterData(successUrl, errorUrl)
      .subscribe(formData => {
        window.location.href = formData.ipayData.url;
      });
  }
}
