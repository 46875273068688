import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlService } from './url.service';

@Injectable()
export class DocumentService {

    constructor(private http: HttpClient,
                private urlService: UrlService) { }

   download(id: number, type?: string) {
       return this.http.get(`${this.urlService.loanDocumentsUrl}/${id}?${type}`, {responseType: 'blob'});
   }

}
