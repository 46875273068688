import {Inject, Injectable} from '@angular/core';
import {PRODUCT_CONFIG_TOKEN} from '../../configs/calculate-config';
import {CookieService} from 'ngx-cookie-service';
import {AffiliateService} from '../services/affiliate.service';
import {UnsubscribeService} from '../services/unsubscribe.service';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {ICalculatePreview} from '../../interfaces/calculate-preview.interface';
import {ILoanProduct} from '../../models/models';
import * as moment from 'moment';
import {ActivatedRoute, Params} from '@angular/router';
import {skip, tap} from 'rxjs/operators';
import {takeUntil} from 'rxjs/internal/operators';
import {IScheduleEvent} from '../../interfaces/schedule-event';
import {CustomEnvironmentService} from '../../services/custom-environment.service';

interface IExpectedPayment {
  date: moment.Moment;
  amount: number;
  rate: number;
}

@Injectable()
export class CalculateStore {
  private startValues: {[key: string]: {term: number; amount: number}} = {
    AVANS: {term: 20, amount: 15000},
    FINBAR: {term: 25, amount: 10000},
    CREDISEND: {term: 25, amount: 10000},
  };

  private preview: BehaviorSubject<ICalculatePreview> =
    new BehaviorSubject<ICalculatePreview>({
      amount: 0,
      percentAmount: 0,
      term: 0,
    });
  private schedule: ReplaySubject<Array<IScheduleEvent>> = new ReplaySubject<
    Array<IScheduleEvent>
  >();
  private isPreview: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private readonly keys: string[] = [
    'amount',
    'term',
    'totalAmount',
    'promoCode',
  ];
  private readonly dayMs = 86400000;
  private time = moment().toDate().getTime();

  constructor(
    @Inject(PRODUCT_CONFIG_TOKEN) private productConfig: ILoanProduct,
    private cookieService: CookieService,
    private affiliateService: AffiliateService,
    private destroyStream$: UnsubscribeService,
    private environmentService: CustomEnvironmentService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams
      .pipe(
        skip(1),
        tap((params: Params) => this.setFakePreview(params, this.keys)),
        takeUntil(this.destroyStream$)
      )
      .subscribe();
  }

  get getPreviewParam$(): Observable<ICalculatePreview> {
    return this.preview.asObservable();
  }

  get isPreviewVisible(): Observable<boolean> {
    return this.isPreview.asObservable();
  }

  set showPreview(value: boolean) {
    this.isPreview.next(value);
  }

  get previewParam(): ICalculatePreview {
    return this.preview.value;
  }

  get schedule$(): Observable<Array<IScheduleEvent>> {
    return this.schedule.asObservable();
  }

  public init(product: ILoanProduct, showParams?: boolean): void {
    this.affiliateService.setAffiliate();
    this.setDefaultParam(product, this.productConfig, showParams);
  }

  public initSchedule(product: ILoanProduct): void {
    this.updateSchedule(
      this.amount(product),
      this.term(product),
      product.interest,
      product.is360,
      product.firstPeriodInterest
    );
  }

  public setDefaultParam(
    product: ILoanProduct,
    config?: ILoanProduct,
    showParams?: boolean
  ): void {
    const rate = product.interest || config.interest;
    const amount = this.amount(product);
    const term = this.term(product);
    this.updatePreviewParam(amount, term, rate, showParams);
  }

  public updatePreviewParam(
    amount: number,
    term: number,
    rate: number = this.productConfig.interest,
    showParams: boolean = false
  ) {
    const data = {
      term: term,
      amount: amount,
      percentAmount: Number(((amount * rate * term) / 100).toFixed(2)),
      totalAmount: Number((amount + ((amount * rate) / 100) * term).toFixed(2)),
      returnDate: moment(this.time + (this.dayMs * term - this.dayMs)).format(
        'DD.MM.YYYY'
      ),
      rate: rate,
    };
    this.preview.next(data);
    this.isPreview.next(showParams);
  }

  public updateSchedule(
    amount: number,
    period: number,
    rate: number = this.productConfig.interest,
    is360,
    firstPeriodInterest,
    promoRate?: number
  ): void {
    const data: IExpectedPayment[] = is360
      ? this.fill360ProductData(amount, rate, period)
      : this.fillNot360ProductData(amount, rate, period);
    CalculateStore.updateFirsPaymentInterest(
      promoRate,
      data,
      amount,
      period,
      firstPeriodInterest
    );
    CalculateStore.addLoanAmountToLastPayment(data, amount);
    const scheduleData: IScheduleEvent[] = data.map((ep: IExpectedPayment) => ({
      date: ep.date.format('DD.MM.YYYY'),
      amount: ep.amount,
      rate: ep.rate,
    }));
    this.schedule.next(scheduleData);
  }

  public term(product: ILoanProduct): number {
    const storedTerm = Number(sessionStorage.getItem('term'));
    const startTerm =
      this.startValues[this.environmentService.environment?.name]?.term;
    const minTerm = product.minTerm;
    const maxTerm = product.maxTerm;

    if (
      storedTerm &&
      storedTerm <= maxTerm &&
      storedTerm >= minTerm &&
      storedTerm % 5 === 0
    ) {
      return storedTerm;
    } else if (startTerm && startTerm <= maxTerm && startTerm >= minTerm) {
      return startTerm;
    } else {
      return maxTerm;
    }
  }

  public amount(product: ILoanProduct): number {
    const storedAmount = Number(sessionStorage.getItem('amount'));
    const startAmount =
      this.startValues[this.environmentService.environment?.name]?.amount;
    const minAmount = product.minAmount;
    const maxAmount = product.maxAmount;
    if (
      storedAmount &&
      storedAmount <= maxAmount &&
      storedAmount >= minAmount
    ) {
      return storedAmount;
    } else if (
      startAmount &&
      startAmount <= maxAmount &&
      startAmount >= minAmount
    ) {
      return startAmount;
    } else {
      return maxAmount;
    }
  }

  private fill360ProductData(
    amount: number,
    rate: number,
    period: number
  ): IExpectedPayment[] {
    const endDate: moment.Moment = this.getLastPaymentDate();
    const data: IExpectedPayment[] = [];
    let next = this.buildInitial(amount, rate, period);
    while (next.date.isBefore(endDate)) {
      data.push(next);
      next = CalculateStore.buildNext(next, period);
    }
    this.updateLastPaymentData(next, period, amount, rate);
    data.push(next);
    return data;
  }

  private buildInitial(
    amount: number,
    rate: number,
    period: number
  ): IExpectedPayment {
    return {
      date: moment(this.time + this.dayMs * (period - 1)),
      amount: CalculateStore.getExpectedPaymentAmount(amount, rate, period),
      rate: rate,
    };
  }

  private updateLastPaymentData(
    last: IExpectedPayment,
    period: number,
    amount: number,
    rate: number
  ) {
    const lastPaymentDate: moment.Moment = this.getLastPaymentDate();
    const daysDiff: number = last.date.diff(lastPaymentDate, 'days');
    const finalPeriod: number = period - daysDiff;
    last.date = lastPaymentDate;
    last.amount = CalculateStore.getExpectedPaymentAmount(
      amount,
      rate,
      finalPeriod
    );
    last.rate = rate;
  }

  private getLastPaymentDate(): moment.Moment {
    const totalPeriod = 360;
    return moment(this.time + this.dayMs * (totalPeriod - 1));
  }

  private fillNot360ProductData(
    amount: number,
    rate: number,
    period: number
  ): IExpectedPayment[] {
    const data: IExpectedPayment[] = [];
    data.push({
      date: moment(this.time + this.dayMs * (period - 1)),
      amount: CalculateStore.getExpectedPaymentAmount(amount, rate, period),
      rate: rate,
    });
    return data;
  }

  private setFakePreview(params: Params, keys: string[]): void {
    keys.forEach((key: string) => {
      params[key] ? sessionStorage.setItem(key, params[key]) : null;
    });
    if (
      Boolean(+params['amount'] && +params['term'] && +params['totalAmount'])
    ) {
      const data: ICalculatePreview = {amount: 0, percentAmount: 0, term: 0};
      keys.forEach((key: string) => (data[key] = Number(params[key])));
      this.isPreview.next(true);
      this.preview.next(data);
    }
  }

  private static getExpectedPaymentAmount(
    loanAmount: number,
    rate: number,
    period: number
  ): number {
    return Number(((loanAmount * rate * period) / 100).toFixed(2));
  }

  private static buildNext(
    next: IExpectedPayment,
    period: number
  ): IExpectedPayment {
    return {
      date: next.date.clone().add(period, 'days'),
      amount: next.amount,
      rate: next.rate,
    };
  }

  private static addLoanAmountToLastPayment(
    data: IExpectedPayment[],
    loanAmount: number
  ) {
    data[data.length - 1].amount += loanAmount;
  }

  private static updateFirsPaymentInterest(
    promoRate: number,
    data: any[],
    amount: number,
    period: number,
    firstPeriodInterest: number
  ) {
    const firstPeriodRate =
      Boolean(promoRate) && promoRate < firstPeriodInterest
        ? promoRate
        : firstPeriodInterest;
    data[0].amount = Number(
      ((amount * firstPeriodRate * period) / 100).toFixed(2)
    );
    data[0].rate = firstPeriodRate;
  }
}
