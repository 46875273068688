import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ruLocale} from 'ngx-bootstrap/locale';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {takeUntil} from 'rxjs/operators';
import * as Models from '../../models/models';
import * as Config from '../../configs/general-config';
import {UnsubscribeService} from '../../shared/services/unsubscribe.service';
import {LanguageStore} from '../../shared/storage/language.store';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.sass'],
  encapsulation: ViewEncapsulation.None,
  providers: [UnsubscribeService],
})
export class FormComponent implements OnInit {
  public locales: Models.IDatepicker = Config.DatepickerConfig.DatepickerLang;

  constructor(
    private langStore: LanguageStore,
    private translate: TranslateService,
    private destroyStream$: UnsubscribeService
  ) {
    defineLocale('ru', ruLocale);
  }

  public ngOnInit(): void {
    this.loadLanguage();
    defineLocale(
      this.locales[Config.LanguageConfig.ua]['lang'],
      this.locales[Config.LanguageConfig.ua]['locale']
    );
  }

  public loadLanguage(): void {
    this.langStore
      .getLang()
      .pipe(takeUntil(this.destroyStream$))
      .subscribe((lang) => {
        this.translate.use(lang);
      });
  }
}
