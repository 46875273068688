export enum IncomeSourceEnum {

    SALARY = 'SALARY',
    AGE_PENSION = 'AGE_PENSION',
    DISABILITY_PENSION = 'DISABILITY_PENSION',
    UNEMPLOYMENT_BENEFIT = 'UNEMPLOYMENT_BENEFIT',
    CHILD_BENEFIT = 'CHILD_BENEFIT',
    ALIMONY = 'ALIMONY',
    DIVIDEND = 'DIVIDEND',
    OTHER = 'OTHER'

}