import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {DeviceService} from '../../services/device.service';
import {RouterModule} from '@angular/router';
import {MatRippleModule} from '@angular/material/core';
import {HeaderMobileNavigationComponent} from './components/header-mobile-navigation/header-mobile-navigation.component';
import {HeaderDesktopNavigationComponent} from './components/header-desktop-navigation/header-desktop-navigation.component';
import {HeaderComponent} from './header.component';

@NgModule({
  declarations: [HeaderComponent, HeaderMobileNavigationComponent, HeaderDesktopNavigationComponent,],
  imports: [CommonModule, TranslateModule, RouterModule, MatRippleModule],
  exports: [HeaderComponent],
  providers: [DeviceService,],
})
export class HeaderModule {
}
