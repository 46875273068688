import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as Config from '../../../../configs/general-config';
import * as Models from '../../../../models/models';
import { UnsubscribeService } from '../../../../shared/services/unsubscribe.service';
import { IdentOptionsEnum } from '../../../../enums/ident-options.enum';
import { ClientModel } from '../../../../models/client.model';
import { FormExecutorService } from '../../../../services/form-executor.service';
import { ClientService } from '../../../../services/client.service';
import { ClientStore } from '../../../../shared/storage/client.store';
import { DataForm } from '../../../../shared/static-data/Data-form.static';
import { PersonalDataModel } from '../../../../models/personal-data.model';
import {
  CustomEnvironmentService,
  IEnvConfig,
} from '../../../../services/custom-environment.service';
import * as Mailcheck from '../../../../assets/js/mailcheck.min.js';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isValueInEnum } from '../../../../helpers/is-value-in-enum';

@Component({
  selector: 'app-form-profile',
  templateUrl: './form-profile.component.html',
  styleUrls: ['./form-profile.component.sass'],
  providers: [UnsubscribeService],
})
export class FormProfileComponent implements OnInit {
  public docOptions: Array<Models.IPassportOption> =
    Config.SelectConfig.passportOptions;
  public customPatterns = Config.RegularExpression.CUSTOM_PASSPORT_PATTERN;
  public regionList: Models.ISelectEnum[] = Config.SelectConfig.regions;
  public identOptions: IdentOptionsEnum;
  private path = Config.StaticDataConfig.redirectPathByClientStage;
  public isDisabled: boolean = false;
  public submitted: boolean = false;
  public isLoaded: boolean = false;
  public manualInput: boolean = true;
  public isNewDocument: boolean = false;
  public showBankID: boolean = true;
  public showUbkiID: boolean = false;
  public showPrivatBank: boolean = true;
  public client: ClientModel;
  public form: FormGroup;
  public bankIdPassed: boolean = false;
  public ENV: IEnvConfig = this.environmentService.environment;
  public validMailMessage = {
    email: '',
    address: '',
    domain: '',
  };

  constructor(
    private destroyStream$: UnsubscribeService,
    private formService: FormExecutorService,
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private clientStore: ClientStore,
    private route: ActivatedRoute,
    private router: Router,
    private environmentService: CustomEnvironmentService
  ) {}

  ngOnInit(): void {    
    combineLatest([
      this.clientStore.getClient(),
      this.clientStore.getClientIdentOptions(),
    ])
      .pipe(takeUntil(this.destroyStream$))
      .subscribe(([client, identOptions]: [ClientModel, IdentOptionsEnum]) => {
        this.identOptions = identOptions;
        this.client = client;
        this.isNewDocument = client.passportData.isNewPassport;
        this.activateForm(client);
        this.formService.fillForm(this.form, client.personalData);
        this.formService.fillForm(this.form, client.passportData);
        this.isLoaded = true;
        this.bankIdPassed = Boolean(identOptions.length);
        this.showUbkiID = isValueInEnum(identOptions, IdentOptionsEnum.UBKI_ID);
        this.showBankID = isValueInEnum(identOptions, IdentOptionsEnum.BANK_ID);
        this.showPrivatBank = isValueInEnum(
          identOptions,
          IdentOptionsEnum.PLATON_DATA
        );
        if (this.showUbkiID) {
          const permissionName = 'camera' as PermissionName;
          navigator.permissions.query({ name: permissionName }).then(res => {
            if (res.state !== 'denied') {
              this.manualInput = false;
            }
          });
        }
      });
  }

  public activateForm(client: ClientModel): void {
    this.form = DataForm.getPersonalFormGroup(
      client,
      this.identOptions,
      this.ENV
    );

    this.initDocumentValidators();
    if (client.addressData.registration.region && this.bankIdPassed) {
      this.formService.clearValidatorsControls(this.registration, 'region');
    }

    this.validateSymbols(['firstName', 'lastName', 'middleName']);
  }

  private validateSymbols(controls: string[]): void {
    this.form.valueChanges
      .pipe(takeUntil(this.destroyStream$))
      .subscribe(val => {
        controls.forEach(controlName => {
          const tmpVal = val[controlName];
          if (tmpVal && tmpVal.includes('--')) {
            this.form.controls[controlName].setValue(tmpVal.replace('--', '-'));
          }
          if (tmpVal && tmpVal[0] === '-') {
            this.form.controls[controlName].setValue(tmpVal.substring(1));
          }
        });
      });
  }

  public onChangeFocus(formControlName: string): void {
    let inputVal = this.form.controls[formControlName].value;
    if (inputVal && inputVal.slice(-1) === '-') {
      // remove last element
      this.form.controls[formControlName].setValue(inputVal.slice(0, -1));
    }
  }

  get f(): Models.IFormControls {
    return this.form.controls;
  }

  public onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    const personalData: PersonalDataModel = new PersonalDataModel(
      this.form.getRawValue()
    );
    this.regionList.forEach(item => {
      if (item.value === this.form.value.region) {
        personalData.regionType = item.name;
      }
    });
    this.isDisabled = true;
    this.clientService
      .updateClientInfo(personalData, '?personal-data')
      .subscribe(
        (client: ClientModel) => {
          this.clientStore.setClient(client);
          isValueInEnum(this.identOptions, IdentOptionsEnum.BANK_ID)
            ? this.router.navigate(['form', this.path.form[client.stage]])
            : this.router.navigate(['form', 'busyness']);
        },
        () => (this.isDisabled = false)
      );
  }

  public checkCorrectEmail(e, error) {
    this.validMailMessage = {
      email: '',
      address: '',
      domain: '',
    };
    if (Mailcheck && !error) {
      Mailcheck.run({
        email: e,
        suggested: suggestion => {
          this.validMailMessage['email'] = suggestion.full;
          this.validMailMessage['address'] = suggestion.address;
          this.validMailMessage['domain'] = suggestion.domain;
        },
        empty: data => {
          this.validMailMessage = {
            email: '',
            address: '',
            domain: '',
          };
        },
      });
    }
  }

  public onReplaceEmail() {
    this.form.get('email').setValue(this.validMailMessage['email']);
    this.validMailMessage = {
      email: '',
      address: '',
      domain: '',
    };
  }

  public onDocumentSwitch(): void {
    this.isNewDocument = !this.isNewDocument;
    this.initDocumentValidators();
    this.updateValueAndValidityForDocument();
  }

  public initDocumentValidators() {
    this.isNewDocument
      ? this.newDocumentControlsManipulation()
      : this.oldDocumentControlsManipulation();
  }

  public oldDocumentControlsManipulation(): void {
    this.formService.setValidatorsControls(this.form, {
      passportNumber: [
        Validators.required,
        Validators.pattern(Config.RegularExpression.FULL_PASSPORT),
      ],
    });
    this.formService.clearValidatorsControls(this.form, 'newPassportNumber');
    this.formService.setValuesForm(this.form, { newPassportNumber: null });
  }

  public newDocumentControlsManipulation(): void {
    this.formService.setValidatorsControls(this.form, {
      newPassportNumber: [
        Validators.required,
        Validators.pattern(Config.RegularExpression.NEW_PASSPORT_NUMBER),
      ],
    });
    this.formService.clearValidatorsControls(this.form, 'passportNumber');
    this.formService.setValuesForm(this.form, { passportNumber: null });
  }

  private updateValueAndValidityForDocument() {
    this.formService.updateValueAndValidityControls(this.form);
  }

  get registration(): FormGroup {
    return this.form.get('registration') as FormGroup;
  }

  public onManualInput(event: boolean): void {
    this.manualInput = event;
  }
}
