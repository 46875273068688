import {NgModule} from '@angular/core';
import {FormRoutingModule} from './form-routing.module';
import {SelfieModeWidgetModule} from '../../shared/components/selfie-mode-widget/selfie-mode-widget.module';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {SystemModule} from '../system/system.module';
import {LoaderModule} from '../../shared/components/loader/loader.module';
import {FormProgressModule} from '../../shared/components/form-progress/form-progress.module';
import {FormComponent} from './form.component';
import {FormProfileComponent} from './components/form-profile/form-profile.component';
import {FormBusynessComponent} from './components/form-busyness/form-busyness.component';
import {AddCardModule} from '../system/components/application/add-card/add-card.module';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMaskModule} from 'ngx-mask';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    SystemModule,
    LoaderModule,
    FormProgressModule,
    SelfieModeWidgetModule,
    AddCardModule,
    NgxMaskModule,
  ],
  declarations: [FormComponent, FormProfileComponent, FormBusynessComponent],
})
export class FormModule {}
