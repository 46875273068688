import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormComponent } from './form.component';
import { FormProfileComponent } from './components/form-profile/form-profile.component';
import { FormBusynessComponent } from './components/form-busyness/form-busyness.component';
import * as Config from '../../configs/general-config';
import {FormGuard} from "../../guards/form.guard";

const routes: Routes = [
    {
        path: '',
        component: FormComponent,
        canActivateChild: [FormGuard],
        children: [
            {
                path: Config.RouterConfig.profile,
                component: FormProfileComponent,
                data: {
                    stage: ['ACCOUNT', 'UBKI_ID']
                }
            },
            {
                path: Config.RouterConfig.busyness,
                component: FormBusynessComponent,
                data: {
                    stage: ['PERSONAL_DATA']
                }
            },
            {
                path: '**',
                redirectTo: Config.RouterConfig.profile
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FormRoutingModule {
}

