<div class="countdown" [class]="classes">
  <div class="countdown-header d-flex justify-content-center">
        <span class="countdown-label countdown-label_sms mr-1" *ngIf="verificationType === 'SMS'">
          {{ 'CONFIRM.CODE_WAS_SEND' | translate }}
        </span>
        <span class="countdown-label countdown-label_phone mr-1" *ngIf="verificationType === 'PHONE' || verificationType === 'IVR'">
          {{ 'CONFIRM.WAIT_CALL' | translate }}
        </span>
    <app-counter-widget [initialState]="initialState"
                        [type]="counterType"
                        [group]="group"
                        (breakpointOutEvent)="breakpointOutEvent.emit($event)"
                        (timeOutEvent)="timeOutEvent.emit($event)"
                        (tickEvent)="tickEventEventHandler($event)"
    ></app-counter-widget>
  </div>
  <div class="countdown-progress mt-3" *ngIf="showProgressBar">
    <div class="countdown-progress-line" [style.width]="progressValue"></div>
  </div>
</div>
