import {InjectionToken, FactoryProvider} from '@angular/core';

export const WINDOW_TOKEN = new InjectionToken<Window>('window');

const windowProvider: FactoryProvider = {
  provide: WINDOW_TOKEN,
  useFactory: () => window,
};

export const WINDOW = [windowProvider];
