export const environment = {
  production: true,
  name: 'BANANA',
  group: 'BASE',
  hostUrl: `https://back.zecredit.com.ua/v1/api/rest`,
  isTestPaymentEnabled: false,
  isInnValidationEnabled: true,
  recaptchaKEY_CODE: '6LfwSrYaAAAAADcDL_h7dYSVFiDFDpwgC15QpBKP',
  cookieDomain: '.bananacredit.com.ua',
  WEBSITE_URL: 'https://bananacredit.com.ua/',
  LOGO: '../assets/images/logo.png',
  CONTACT: 'tel:+380632452523',
  partnerLink:
    'http://groshi.bez-otkazov.com/?utm_source=ba&utm_medium=refferal&utm_campaign=lk&utm_term=',
  otherCompanyRedirect_cab:
    'utm_source=refferal&utm_medium=banana&utm_campaign=link_on_a_button_main_page',
  otherCompanyRedirect_form:
    'utm_source=refferal&utm_medium=Bnn-zayavka-bk&utm_campaign=redirect',
};
