export enum Regions {
    
    VINNICKAYA = 'Вінницька',
    VOLYNSKAYA = 'Волинська',
    DNEPROPETROVSKAYA = 'Дніпропетровська',
    DONETSKAYA = 'Донецька',
    ZHITOMIRSKAYA = 'Житомирська',
    ZAKARPATSKAYA = 'Закарпатська',
    ZAPOROZHSKAYA = 'Запорізька',
    IVANO_FRANKOVSKAYA = 'Івано-Франківська',
    KIEVSKAYA = 'Київська',
    KIROVOGRADSKAYA = 'Кіровоградська',
    LUGANSKAYA = 'Луганська',
    LVOVSKAYA = 'Львівська',
    NIKOLAEVSKAYA = 'Миколаївська',
    ODESSKAYA = 'Одеська',
    POLTAVSKAYA = 'Полтавська',
    ROVNENSKAYA = 'Рівненська',
    SUMSKAYA = 'Сумська',
    TERNOPOLSKAYA = 'Тернопільська',
    HARKOVSKAYA = 'Харківська',
    HERSONSKAYA = 'Херсонська',
    HMELNITSKAYA = 'Хмельницька',
    CHERKASSKAYA = 'Черкаська',
    CHERNIGOVSKAYA = 'Чернігівська',
    CHERNOVITSKAYA = 'Чернівецька',
    AP_KRYM = 'АР Крим',
    KIEV = 'м. Київ'
    
}
