<label #trigger class="d-flex align-items-start" [class.not-allowed]="disabled" [class]="checkboxClasses">
  <input type="checkbox" (change)="onChanged($event)" [disabled]="disabled" [checked]="isChecked">
  <span class="checkbox-inner d-flex justify-content-center align-items-center">
        <span class="checkbox-ripple"
              matRipple
              [matRippleColor]="rippleColor"
              [matRippleTrigger]="trigger"
              [matRippleCentered]="true"
        ></span>
        <span class="checkbox"
              [ngClass]="{ 'ng-valid': control?.valid,'in-valid': control?.invalid}"
              *ngIf="!checkImg?.url && !uncheckImg?.url"
              [class.checked]="isChecked"
              [style.border]="isChecked ? '1px solid ' + border : '1px solid #E0E1E4'"
              [style.backgroundColor]="isChecked ? background : (backgroundColorAlways ? background : '#ffffff')"
        ></span>

     <span class="checkbox"
           [ngClass]="{'ng-valid': control?.valid,'in-valid': control?.invalid}"
           *ngIf="checkImg?.url && uncheckImg?.url"
           [style.height]="checkImg.height || ''"
           [style.width]="checkImg.width || ''"
           [style.background-image]="isChecked ? 'url('+checkImg.url+')' : (control?.invalid ? 'url('+errorImg.url+')' : 'url('+uncheckImg.url+')')"
     ></span>

    </span>
  <div class="d-flex flex-column" *ngIf="!template">
    <span class="label-title fs-12" [innerHTML]="label"></span>
    <span *ngIf="description"
          class="label-description"
          [class]="labelClasses">{{description}}</span>
  </div>
  <div *ngIf="template"
       class="label-description"
       [innerHTML]="template"
       [class]="labelClasses"></div>
</label>
