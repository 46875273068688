import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {AuthAgreementModule} from '../auth-agreement/auth-agreement.module';
import {SmsCodeWidgetModule} from '../../../../shared/components/sms-code-widget/sms-code-widget.module';
import {CounterWidgetModule} from '../../../../shared/components/counter-widget/counter-widget.module';
import {ResendSmsWidgetModule} from '../../../../shared/components/resend-sms-widget/resend-sms-widget.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthConfirmComponent} from './auth-confirm.component';
import {CountdownContainerModule} from '../../../../shared/components/countdown-container/countdown-container.module';

@NgModule({
  declarations: [AuthConfirmComponent],
  imports: [
    CommonModule,
    TranslateModule,
    AuthAgreementModule,
    SmsCodeWidgetModule,
    CounterWidgetModule,
    ResendSmsWidgetModule,
    ReactiveFormsModule,
    FormsModule,
    CountdownContainerModule
  ],
  exports: [AuthConfirmComponent]
})
export class AuthConfirmModule {
}
