import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import * as Models from '../../../../models/models';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DataForm} from '../../../../shared/static-data/Data-form.static';
import * as Config from '../../../../configs/general-config';
import {MaskApplierService} from 'ngx-mask';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GroupType} from '../../../../types/group.type';

@Component({
  selector: 'app-auth-phone-edit-modal',
  templateUrl: './auth-phone-edit-modal.component.html',
  styleUrls: ['./auth-phone-edit-modal.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthPhoneEditModalComponent implements OnInit {
  public form: FormGroup;
  public defaultMobilePhone = 380;
  public submitted: boolean = false;
  public doubleCountryRegular =
    Config.RegularExpression.ERROR_MOBILE_INPUT_DOUBLE_COUNTRY;
  public doubleZeroRegular =
    Config.RegularExpression.ERROR_MOBILE_INPUT_DOUBLE_ZERO;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {phoneNumber: number; group: GroupType},
    public dialogRef: MatDialogRef<AuthPhoneEditModalComponent>,
    private formBuilder: FormBuilder,
    private maskService: MaskApplierService
  ) {}

  public ngOnInit(): void {
    this.activateForm();
  }

  public activateForm(): void {
    this.form = DataForm.getLoginFormGroup();
  }

  public preventDefaultValueDeletion(event: any): boolean {
    if (
      (event.target.value.length === 4 ||
        this.form.get('phone').value === this.defaultMobilePhone) &&
      (event.code === 'Backspace' || event.code === 'Delete')
    ) {
      return false;
    }
    this.inputPhoneNumber(event);
    return true;
  }

  public inputPhoneNumber(event: any): void {
    if (
      this.doubleCountryRegular.test(String(this.form.get('phone').value)) ||
      this.doubleZeroRegular.test(String(this.form.get('phone').value))
    ) {
      this.form.get('phone').setValue(380);
    }
  }

  public setValuePhone(element): void {
    const val = element.value;
    element.value = '';
    element.value = val;
  }

  get f(): Models.IFormControls {
    return this.form.controls;
  }

  public get phoneNumber(): string {
    return this.maskService.applyMask(
      this.data.phoneNumber.toString(),
      '+00(000) 000 00 00'
    );
  }

  public updatePhone(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    sessionStorage.setItem('phone', this.form.value.phone);
    this.dialogRef.close(this.form.value.phone);
  }
}
