import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.sass'],
})
export class AuthComponent implements OnInit {
  private readonly IS_AUTHENTICATED: string = 'isAuthenticated';

  constructor(private cookieService: CookieService,
              private router: Router) {}

  ngOnInit(): void {
    if ((this.cookieService.get(this.IS_AUTHENTICATED) && window.location.pathname === '/auth/login') ||
      window.location.pathname === '/') {
      this.router.navigate(['main', 'loan']);
    }
  }
}
