<ng-container [ngSwitch]="group">

  <section class="selfie-mode" *ngSwitchCase="'AVANS'"
           [class.selfie-mode_indent]="isPreview$ | async">
    <h2 class="selfie-mode__title text-center fw-bold" [innerHTML]="'SELFIE.TITLE' | translate"></h2>
    <div class="selfie-mode-content" *ngIf="!isSelfie">
      <h3 class="selfie-mode__subtitle text-center fw-bold">{{ 'SELFIE.SUBTITLE' | translate }}</h3>
      <div class="d-flex justify-content-center">
        <app-button type="button"
                    size="large"
                    color="secondary"
                    classes="fs-20"
                    (click)="isSelfie = true"
                    [label]="'Зробити селфі' | translate"
        ></app-button>
      </div>
      <p class="d-flex flex-column text-center fw-regular">{{ 'SELFIE.ALTERNATIVE' | translate }}
        <a class="selfie-mode-link cursor-pointer text-center fw-bold mt-2"
           (click)="emitManual(true)">{{ 'SELFIE.SUB_ALTERNATIVE' | translate }}</a>
      </p>
    </div>
    <div class="selfie-mode-content" *ngIf="isSelfie">
      <app-camera-selfie *ngIf="!webcamImage"
                         [group]="group"
                         (permissionDisabled)="handlePermission($event)"
                         (pictureTaken)="handleImage($event)">
      </app-camera-selfie>
      <div class="snapshot">
        <ng-container *ngIf="webcamImage">
          <img [src]="webcamImage.imageAsDataUrl"/>
          <p class="text-center fw-regular mt-2 mb-2">{{ 'SELFIE.SMILE' | translate }}</p>
        </ng-container>
        <ng-container *ngIf="errorPhoto">
          <div class="mb-3 d-flex justify-content-center">
            <app-button type="button"
                        size="big"
                        color="pure"
                        (click)="createNewPhoto()"
                        [label]="'SELFIE.CREATE_PHOTO' | translate"
            ></app-button>
          </div>
          <div class="d-flex justify-content-center">
            <app-button type="button"
                        size="big"
                        color="pure"
                        (click)="emitManual(true)"
                        [label]="'SELFIE.FILL_BTN' | translate"
            ></app-button>
          </div>
        </ng-container>
      </div>
    </div>
  </section>

  <section class="selfie-mode" *ngSwitchCase="'STARFIN'">
    <ng-container *ngIf="!isSelfie">
      <h2 class="selfie-mode__title text-center fw-bold">
        {{ 'SELFIE.TITLE' | translate }}
      </h2>
      <div class="selfie-mode-content">
        <h3 class="selfie-mode__subtitle text-center">
          {{ 'SELFIE.SUB_TITLE' | translate }}
        </h3>
        <div class="d-flex justify-content-center">
          <app-button
            type="button"
            size="large"
            color="secondary"
            classes="fs-16 fw-bold"
            (click)="isSelfie = true"
            [label]="'SELFIE.BUTTON_TEXT' | translate"
          ></app-button>
        </div>
        <p class="d-flex flex-column text-center fw-regular mt-2">
          {{ 'SELFIE.ALTERNATIVE' | translate }}
        </p>
        <p class="d-flex flex-column text-center fw-regular mt-2 mb-2">
          {{ 'SELFIE.SUB_ALTERNATIVE' | translate }}
        </p>
        <a
          class="selfie-mode-link cursor-pointer text-center fw-regular"
          (click)="emitManual(true)"
        >{{ 'SELFIE.OPEN_FORM_TEXT' | translate }}</a
        >
      </div>
    </ng-container>
    <div class="selfie-mode-content" *ngIf="isSelfie">
      <app-camera-selfie
        [group]="group"
        [isError]="!!webcamImage"
        (permissionDisabled)="handlePermission($event)"
        (pictureTaken)="handleImage($event)"
      >
      </app-camera-selfie>
      <div class="mt-2 text-center" *ngIf="webcamImage">
        <p class="d-flex flex-column text-center fw-regular">
          {{ 'SELFIE.ALTERNATIVE' | translate }}
        </p>
        <p class="d-flex flex-column text-center fw-regular">
          {{ 'SELFIE.SUB_ALTERNATIVE' | translate }}
        </p>
        <a
          class="selfie-mode-link cursor-pointer text-center mt-2"
          (click)="emitManual(true)"
        >{{ 'SELFIE.OPEN_FORM_TEXT' | translate }}</a
        >
      </div>
    </div>
  </section>

  <section class="selfie-mode" *ngSwitchCase="'SUNCREDIT'">
    <ng-container *ngIf="!isSelfie">
      <h2 class="selfie-mode__title title-decor text-center">
        {{ 'SELFIE.TITLE' | translate }}
      </h2>
      <div class="selfie-mode-content">
        <h3 class="selfie-mode__subtitle text-center">
          {{ 'SELFIE.SUB_TITLE' | translate }}
        </h3>
        <div class="d-flex flex-column justify-content-center">
          <app-button
            type="button"
            size="w100"
            class="w-100"
            color="primary"
            classes="fs-18 fw-medium"
            (click)="isSelfie = true"
            [label]="'SELFIE.BUTTON_TEXT' | translate"
          ></app-button>
          <app-button
            type="button"
            size="w100"
            class="w-100"
            color="underline"
            classes="fs-16 fw-medium"
            (click)="emitManual(true)"
            [label]="'SELFIE.OPEN_FORM_TEXT' | translate"
          ></app-button>
        </div>
      </div>
    </ng-container>
    <div class="selfie-mode-camera" *ngIf="isSelfie">
      <app-camera-selfie
        [group]="group"
        [isError]="!!webcamImage"
        (permissionDisabled)="handlePermission($event)"
        (pictureTaken)="handleImage($event)"
      >
      </app-camera-selfie>
      <div class="camera-selfie-footer mt-2 text-center" *ngIf="webcamImage">
        <p class="d-flex flex-column text-center fw-regular">
          {{ 'SELFIE.ALTERNATIVE' | translate }}
        </p>
        <p class="d-flex flex-column text-center fw-regular">
          {{ 'SELFIE.SUB_ALTERNATIVE' | translate }}
        </p>
        <app-button
          type="button"
          size="w100"
          class="w-100"
          color="underline"
          classes="fs-16 fw-medium"
          (click)="emitManual(true)"
          [label]="'SELFIE.OPEN_FORM_TEXT' | translate"
        ></app-button>
      </div>
    </div>
  </section>

  <section class="selfie-mode" *ngSwitchDefault>
    <div class="selfie-mode-block text-center" *ngIf="!isSelfie">
      <h3 class="selfie-mode-title">{{'SELFIE_MODE.TITLE' | translate}}</h3>
      <br>
      <button class="btn" (click)="isSelfie = true">{{'SELFIE_MODE.CREATE_SELFIE' | translate}}</button>
      <p class="selfie-mode-text selfie-mode-text_fs-12"> {{'SELFIE_MODE.SUB' | translate}}
        <a class="selfie-mode-link" (click)="emitManual(true)">
          {{'SELFIE_MODE.FILL_APPLY' | translate}}
        </a>
      </p>
    </div>
    <div class="selfie-mode-block" *ngIf="isSelfie">
      <app-camera-selfie *ngIf="!webcamImage"
                         [group]="group"
                         [enableSubText]="true"
                         (permissionDisabled)="handlePermission($event)"
                         (pictureTaken)="handleImage($event)"></app-camera-selfie>
      <div class="snapshot">
        <ng-container *ngIf="webcamImage">
          <img [src]="webcamImage.imageAsDataUrl"/>
          <p class="selfie-mode-text text-center">{{'SELFIE_MODE.INFO' | translate}}</p>
        </ng-container>
        <ng-container *ngIf="errorPhoto">
          <button class="btn"
                  (click)="webcamImage = null; errorPhoto = false">{{'SELFIE_MODE.CREATE_PHOTO_BTN' | translate}}</button>
          <br>
          <button class="btn" (click)="emitManual(true)">{{'SELFIE_MODE.FILL_BTN' | translate}}</button>
        </ng-container>
      </div>
    </div>
  </section>

</ng-container>

