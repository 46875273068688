import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AuthenticationService} from '../shared/services/authentication.service';
import {UrlService} from '../services/url.service';
import {BehaviorSubject, EMPTY, throwError} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing: boolean = false;
  private accessTokenSource = new BehaviorSubject<string>(null);
  private accessToken$ = this.accessTokenSource.asObservable();

  constructor(
    private authService: AuthenticationService,
    private urlService: UrlService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const withCredentials = !!request.params.get('withCredentials'); // проверка на добавление токена для конкретных запросов
    if (withCredentials) {
      request = request.clone({
        params: request.params.delete('withCredentials'),
      });
    }
    if (this.isUrlPermitAll(request.url) && !withCredentials) {
      return next.handle(request);
    }
    this.accessTokenSource.next(this.authService.getToken());
    return this.sendRequestWithToken(request, next).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          return this.handleUnauthorizedError(request, next);
        } else {
          return throwError(err);
        }
      })
    );
  }

  private sendRequestWithToken(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();

    if (!token) {
      return next.handle(request);
    }

    const tokenRequest = this.addToken(request, token);

    return next.handle(tokenRequest);
  }

  private handleUnauthorizedError(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.authService.isAuthenticated()) {
      return this.authService.refreshToken().pipe(
        mergeMap((token) => {
          this.authService.setToken(token);
          this.accessTokenSource.next(token.token);
          const tokenRequest = this.addToken(request, token.token);
          return next.handle(tokenRequest);
        }),
        catchError((error) => this.handleRefreshError(error, request, next))
      );
    } else {
      this.router.navigate(['/auth/login']);
      this.authService.logout();
      return EMPTY;
    }
  }

  private handleRefreshError(
    error: any,
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log('Refresh token error:', error);
    this.router.navigate(['/auth/login']);
    this.authService.logout();
    return EMPTY;
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private isUrlPermitAll(url: string): boolean {
    const loginUrl = this.urlService.loginUrl;
    const refreshUrl = this.urlService.refreshUrl;
    const registrationUrl = this.urlService.registrationUrl;
    const verificationUrl = this.urlService.verificationUrl;
    const freepayment = this.urlService.freepayment;
    const closedDocumentsDownload = this.urlService.closedDocumentsDownload;
    const closedDocuments = this.urlService.closedDocuments;
    return (
      url.search(loginUrl) !== -1 ||
      url.search(refreshUrl) !== -1 ||
      url.search(registrationUrl) !== -1 ||
      url.search(verificationUrl) !== -1 ||
      url.search(freepayment) !== -1 ||
      url.search(closedDocumentsDownload) !== -1 ||
      url.search(closedDocuments) !== -1 ||
      url.search(this.urlService.host) === -1
    );
  }
}
