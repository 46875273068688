<button mat-button
        type="button"
        (click)="clickEvent.emit($event)"
        [disabled]="disabled"
        [class.spinner]="disabled"
        [type]="type"
        [ngClass]="btnClasses">

  <img
    *ngIf="img?.url"
    [src]="img.url"
    [style.height]="img.height || ''"
    [style.width]="img.width || ''"
    alt="icon"
    class="ml-1 mr-1"
  >
  <span class="d-inline-flex align-items-center justify-content-center" *ngIf="label">{{label}}</span>
</button>
