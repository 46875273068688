import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { CookieService } from 'ngx-cookie-service';
import {CustomEnvironmentService} from "../../services/custom-environment.service";

@Injectable()
export class GuidService {

    private storage: Storage = localStorage;
    readonly GUID: string = 'guid';
    readonly ZE_GUID: string = 'ze_guid';

    constructor(private cookieService: CookieService,
                private _customEnvironmentService: CustomEnvironmentService) {
    }

    public domain: string = this._customEnvironmentService.environment.cookieDomain;
    public getGuid() {
        const cookieGuid = this.cookieService.get(this.ZE_GUID);
        if (cookieGuid && cookieGuid !== 'undefined') {
            return cookieGuid;
        }
        const storageGuid = this.storage.getItem(this.GUID);
        const zeGuid = (!storageGuid || storageGuid === 'undefined') ? uuid() : storageGuid;
        this.cookieService.set(this.ZE_GUID, zeGuid, 3650, '/', this.domain);
        this.storage.setItem(this.GUID, zeGuid);
        return zeGuid;
    }
}
