import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {TranslateModule} from '@ngx-translate/core';
import {CommunicationService} from './communication.service';
import {CommunicationComponent} from './communication.component';

@NgModule({
  declarations: [CommunicationComponent],
  imports: [CommonModule, TranslateModule],
  exports: [CommunicationComponent],
  providers: [CommunicationService]
})
export class CommunicationModule {}
