<div class="auth-repeat">
  <div class="auth-landscape">
    <div class="auth-landscape__body">
      <div class="auth-landscape-content" *ngIf="!shortVal">
        <h2 class="auth-landscape__title">{{'AUTH.TITLE'  | translate}}</h2>
        <p class="auth-landscape__text">{{'CONFIRM.PHONE'  | translate}}</p>
      </div>
      <div class="auth-landscape-content" *ngIf="shortVal">
        <p class="auth-landscape__text"
           *ngIf="!timeDown && group !== 'AVANS' && showPhone"
           [innerHtml]="'AUTH.SHORT_TEXT' | translate"></p>
        <p class="auth-landscape__text"
           *ngIf="timeDown || group === 'AVANS' || !showPhone">{{'CONFIRM.PHONE'  | translate}}</p>
      </div>
    </div>
  </div>
  <div class="auth-repeat-footer">
    <app-buttons-way-code (wayRepeat)="getWay($event)"
                          [showPhone]="showPhone"
                          [group]="group"
    ></app-buttons-way-code>
  </div>
</div>
