import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as Config from '../../configs/general-config';
import {ClientModel} from '../../models/client.model';
import {IdentOptionsEnum} from '../../enums/ident-options.enum';
import {InnValidator} from '../validators/inn.validator';
import {IEnvConfig} from '../../services/custom-environment.service';
import {ClientStageEnum} from '../../enums/client-stage.enum';

export abstract class DataForm {
  private static fb: FormBuilder = new FormBuilder();

  public static getPersonalFormGroup(
    client: ClientModel,
    identOptions: IdentOptionsEnum,
    ENV: IEnvConfig
  ): FormGroup {
    // const isDataFromBankId = !identOptions.includes(IdentOptionsEnum.BANK_ID);
    const isDataFromBankId = client.stage === ClientStageEnum.UBKI_ID;
    return this.fb.group({
      lastName: [
        {value: null, disabled: isDataFromBankId},
        Validators.compose([
          Validators.required,
          Validators.pattern(Config.RegularExpression.LAST_NAME),
        ]),
      ],
      firstName: [
        {value: null, disabled: isDataFromBankId},
        Validators.compose([
          Validators.required,
          Validators.pattern(Config.RegularExpression.FIRST_NAME),
        ]),
      ],
      middleName: [
        {value: null, disabled: isDataFromBankId},
        Validators.compose([
          Validators.required,
          Validators.pattern(Config.RegularExpression.MIDDLE_NAME),
        ]),
      ],
      inn: [
        {value: '', disabled: isDataFromBankId},
        [
          Validators.required,
          Validators.pattern(Config.RegularExpression.INN),
          new InnValidator(ENV),
        ],
      ],
      isNewPassport: [{value: false, disabled: isDataFromBankId}],
      passportNumber: [{value: null, disabled: isDataFromBankId}],
      newPassportNumber: [{value: null, disabled: isDataFromBankId}],
      region: [
        {
          value: client.addressData.residence.region,
          disabled: false,
        },
        Validators.compose([Validators.required]),
      ],
      email: [
        null,
        Validators.compose([
          Validators.email,
          Validators.pattern(Config.RegularExpression.mailPattern),
        ]),

      ],
    });
  }

  public static getBusinessFormGroup(): FormGroup {
    return this.fb.group({
      education: ['', Validators.compose([Validators.required])],
      busynessType: [''],
      ownershipType: ['', Validators.compose([Validators.required])],
      monthlyIncome: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(Config.RegularExpression.ONLY_NUMBERS),
        ]),
      ],
      relativePhone: [
        380,
        Validators.compose([
          Validators.required,
          Validators.pattern(Config.RegularExpression.MOBILE_PHONE),
        ]),
      ],
    });
  }

  public static getBankIdFormGroup(): FormGroup {
    return this.fb.group({
      bank: [null, [Validators.required]],
    });
  }

  public static getAgreementFormGroup(): FormGroup {
    return this.fb.group({
      agree: [true, Validators.requiredTrue],
      marketingConsent: [true],
    });
  }

  public static getLoginFormGroup(): FormGroup {
    return this.fb.group({
      phone: [
        380,
        Validators.compose([
          Validators.required,
          Validators.pattern(Config.RegularExpression.MOBILE_PHONE),
        ]),
      ],
    });
  }

  public static getFreePaymentFormGroup(ENV: IEnvConfig): FormGroup {
    return this.fb.group({
      inn: [
        '',
        [
          new InnValidator(ENV),
          Validators.pattern(Config.RegularExpression.INN),
          Validators.required,
        ],
      ],
      contractNumber: [null, Validators.compose([Validators.required])],
      amount: [
        null,
        Validators.compose([
          Validators.required,
          Validators.min(0.01),
          Validators.max(100000),
        ]),
      ],
    });
  }

  public static getFreePaymentWidthPhoneFormGroup(): FormGroup {
    return this.fb.group({
      contractNumber: [null, Validators.compose([Validators.required])],
      amount: [
        null,
        Validators.compose([
          Validators.required,
          Validators.min(0.01),
          Validators.max(100000),
        ]),
      ],
    });
  }

  public static getClosedDocuments(ENV: IEnvConfig): FormGroup {
    return this.fb.group({
      inn: [
        '',
        [
          Validators.required,
          Validators.pattern(Config.RegularExpression.INN),
          new InnValidator(ENV),
        ],
      ],
      contractNumber: [null, Validators.compose([Validators.required])],
    });
  }
}
