import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UrlService } from './url.service';
import { ClientModel } from '../models/client.model';
import { TokenResponse } from '../models/token-response.model';

@Injectable()
export class ClientService {

    constructor(private http: HttpClient,
                private urlService: UrlService) {}

    getClientInfo(): Observable<any> {
        return this.http.get(this.urlService.profileUrl);
    }

    getClientIdentOptions(): Observable<any> {
        return this.http.get(this.urlService.identOptionsUrl);
    }

    updateClientInfo(data: any, path: string): Observable<ClientModel> {
        return this.http.post<ClientModel>(`${this.urlService.profileUrl}${path}`, data);
    }

    updateFinanceNumber(data: any): Observable<TokenResponse> {
        return this.http.post<TokenResponse>(this.urlService.financeNumberUrl, data);
    }

    testUpdateFinanceNumber(data: any): Observable<TokenResponse> {
        return this.http.post<TokenResponse>(this.urlService.financeNumberTestUrl, data);
    }

}
