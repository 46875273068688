import {BehaviorSubject} from 'rxjs';
import {ClearStoreService} from '../services/clear-store.service';

export abstract class Store {

  constructor(public clearStoreService: ClearStoreService) {
    this.clearStoreService.getSubject().subscribe(() => {
      this.getStorageSubject().next(null);
    });
  }

  abstract getStorageSubject(): BehaviorSubject<any>;

}
