import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlService } from './url.service';
import { ILoanProduct } from '../models/models';

@Injectable()
export class ProductService {

    constructor(private http: HttpClient,
                private  urlService: UrlService) {
    }

    getActualProduct(): Observable<ILoanProduct> {
        return this.http.get<ILoanProduct>(`${this.urlService.loanProductsUrl}?actual`);
    }

}
