import {Injectable, NgZone} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UrlService} from './url.service';
import {Observable} from 'rxjs';
import {VerificationType} from '../enums/verification-type.enum';
import {CustomEnvironmentService} from './custom-environment.service';
import {Capacitor} from '@capacitor/core';
import {AuthenticationService} from '../shared/services/authentication.service';

@Injectable()
export class UserVerificationService {
  public nameApp: string;
  public linkIvr: string;
  public platform: string;

  constructor(
    private http: HttpClient,
    private urlService: UrlService,
    private authService: AuthenticationService,
    private _zone: NgZone,
    private _customEnvironmentService: CustomEnvironmentService
  ) {
    this.nameApp = this._customEnvironmentService.environment.name;
    this.linkIvr = !this._customEnvironmentService.environment.production
      ? this.urlService.testVerificationPhoneCallUrl
      : this.urlService.verificationPhoneCallUrl;
    this.getPlatform();
  }

  getPlatform() {
    this.platform = Capacitor.getPlatform().toUpperCase();
  }

  sendSmsCode(
    phone: number | string,
    action: string,
    withCredentials = false,
    verificationType?: VerificationType
  ): Observable<any> {
    this.authService.setQueryParams();
    const body = {
      phone,
      action,
      verificationType,
      scope: this.nameApp,
      platform: this.platform,
      authParams: this.authService.getAuthParams(),
    };
    let params = new HttpParams();
    if (withCredentials) {
      params = params.append('withCredentials', String(withCredentials));
    }
    return this.http.post(this.urlService.verificationUrl, body, {params});
  }

  getServerSentEvent(
    phone: number | string,
    action: string,
    verificationType?: VerificationType,
    marketingConsent = true
  ): Observable<any> {
    return Observable.create((observer) => {
      const eventSource = new EventSource(
        `${this.linkIvr}?phone=${phone}&action=${action}&verificationType=${verificationType}&marketingConsent=${marketingConsent}&scope=${this.nameApp}&platform=${this.platform}`
      );
      eventSource.onmessage = (event) => {
        this._zone.run(() => {
          observer.next(event);
        });
        eventSource.close();
      };
      eventSource.onerror = (error) => {
        this._zone.run(() => {
          observer.error(error);
        });
        eventSource.close();
      };
    });
  }

  getIvrCall(
    phone: number | string,
    action: string,
    reCaptchaToken: string,
    verificationType?: VerificationType,
    marketingConsent = true
  ) {
    this.authService.setQueryParams();
    const body = {
      phone,
      action,
      verificationType,
      reCaptchaToken,
      scope: this.nameApp,
      marketingConsent,
      platform: this.platform,
      authParams: this.authService.getAuthParams(),
    };
    return this.http.post(this.urlService.ivrCallUrl, body);
  }
  ivrUnsubscribe(token: any) {
    return this.http.get(`${this.urlService.ivrUnsubscribeUrl}?token=${token}`);
  }

  getServerSentEventIvr(item: any): Observable<any> {
    return Observable.create((observer) => {
      const eventSource = new EventSource(
        `${this.urlService.ivrSubscribeUrl}?token=${item.token}`
      );
      eventSource.onmessage = (event) => {
        if (event.data) {
          this._zone.run(() => {
            observer.next(event);
          });
          this.ivrUnsubscribe(item.token).subscribe(() => {});
          eventSource.close();
        }
      };
      eventSource.onerror = (error) => {
        this._zone.run(() => {
          observer.error(error);
        });
      };
    });
  }

  getServerSentEventWithCaptcha(
    phone: number | string,
    action: string,
    reCaptchaToken: string,
    verificationType?: VerificationType,
    marketingConsent = true
  ): Observable<any> {
    return Observable.create((observer) => {
      const eventSource = new EventSource(
        `${this.linkIvr}?phone=${phone}&action=${action}&verificationType=${verificationType}&reCaptchaToken=${reCaptchaToken}&scope=${this.nameApp}&marketingConsent=${marketingConsent}&platform=${this.platform}`
      );

      eventSource.onmessage = (event) => {
        if (event.data) {
          this._zone.run(() => {
            observer.next(event);
          });
          eventSource.close();
        }
      };
      eventSource.onerror = (error) => {
        this._zone.run(() => {
          observer.error(error);
        });
        eventSource.close();
      };
    });
  }

  sendSmsCodeWithCaptcha(
    phone: number | string,
    action: string,
    reCaptchaToken: string,
    withCredentials = false,
    verificationType?: VerificationType
  ): Observable<any> {
    this.authService.setQueryParams();
    const body = {
      phone,
      action,
      reCaptchaToken,
      scope: this.nameApp,
      verificationType,
      platform: this.platform,
      authParams: this.authService.getAuthParams(),
    };
    let params = new HttpParams();
    if (withCredentials) {
      params = params.append('withCredentials', String(withCredentials));
    }
    return this.http.post(this.urlService.verificationUrl, body, {params});
  }

  confirmSmsCode(
    phone: string,
    action: string,
    code: string,
    withCredentials = false,
    marketingConsent = true,
    verificationType: VerificationType
  ): Observable<any> {
    const body = {
      phone,
      action,
      code,
      marketingConsent,
      verificationType,
      scope: this.nameApp,
      platform: this.platform,
    };
    let params = new HttpParams();
    if (withCredentials) {
      params = params.append('withCredentials', String(withCredentials));
    }
    return this.http.post(this.urlService.confirmationsUrl, body, {params});
  }
}
