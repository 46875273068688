import {NgModule} from '@angular/core';
import {NgxMaskModule} from 'ngx-mask';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {MatDialogModule} from "@angular/material/dialog";
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import {TranslateLoaderFactory} from "../helpers/translate-loader-factory";
import {BsModalRef, ModalModule} from 'ngx-bootstrap/modal';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const MAT_MODULES = [
  MatDialogModule,
];

@NgModule({
  declarations: [],
  imports: [
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ...MAT_MODULES,
  ],
  providers: [BsModalRef],
  exports: [
    NgxMaskModule,
    TranslateModule,
    ...MAT_MODULES,
  ]
})
export class SharedModule {
}
