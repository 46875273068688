import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {NavItem} from '../../nav-items';

@Component({
  selector: 'app-header-mobile-navigation',
  templateUrl: './header-mobile-navigation.component.html',
  styleUrls: ['./header-mobile-navigation.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMobileNavigationComponent {

  @Input()
  public items: NavItem[] = [];

  @Input()
  public active: boolean;

  @Output()
  public readonly itemClickEvent = new EventEmitter<void>();

}
