export class RouterConfig {
  static auth = 'auth';
  static login = 'login';
  static autoLogin = 'autoLogin';
  static registration = 'registration';
  static main = 'main';
  static system = 'system';
  static form = 'form';
  static loan = 'loan';
  static contract = 'contract';
  static history = 'history';
  static contracts = 'contracts';
  static uploadDocuments = 'upload-documents';
  static profile = 'profile';
  static profiles = 'profiles';
  static passport = 'passport';
  static address = 'address';
  static busyness = 'busyness';
  static application = 'application';
  static scanner = 'scanner';
  static financeNumber = 'finance-number';
  static bankid = 'bankid';
  static freepayment = 'freepayment';
  static certificateOfClosure = 'certificate-of-closure';
}
