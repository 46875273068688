import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ClientModel } from '../models/client.model';
import { ClientStore } from '../shared/storage/client.store';
import "rxjs-compat/add/operator/map";

@Injectable()
export class StageGuard implements CanActivate, CanActivateChild {

    constructor(private router: Router,
                private clientStore: ClientStore) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.clientStore.getClient().map((client: ClientModel) => {
            if (route.data.stage) {
                const validStatus = route.data.stage;
                if (validStatus.includes(client.stage)) {
                    return true;
                } else {
                    const url = route.data.url;
                    this.router.navigate([url]);
                    return false;
                }
            }
        });
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.canActivate(childRoute, state);
    }

}
