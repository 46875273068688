import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatRippleModule} from '@angular/material/core';
import {CheckboxComponent} from './checkbox.component';


@NgModule({
  declarations: [CheckboxComponent],
  imports: [
    CommonModule,
    MatRippleModule
  ],
  exports: [CheckboxComponent]
})
export class CheckboxModule {
}
