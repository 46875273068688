import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMaskModule} from 'ngx-mask';
import {AuthPhoneComponent} from './auth-phone.component';
import {ButtonModule} from '../../../../shared/components/button/button.module';

@NgModule({
  declarations: [AuthPhoneComponent],
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, FormsModule, NgxMaskModule, ButtonModule],
  exports: [AuthPhoneComponent]
})
export class AuthPhoneModule {
}
