import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {TranslateModule} from '@ngx-translate/core';
import {AddCardComponent} from './add-card.component';

@NgModule({
  declarations: [AddCardComponent],
  imports: [CommonModule, TranslateModule],
  exports: [AddCardComponent]
})
export class AddCardModule{}
