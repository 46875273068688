<app-form-progress></app-form-progress>

<ng-container *ngIf="!isLoaded">
  <app-loader></app-loader>
</ng-container>

<ng-container *ngIf="isLoaded">
  <div *ngIf="!manualInput && showUbkiID">
    <app-selfie-mode-widget
      [group]="ENV.group"
      (onManualInput)="onManualInput($event)"></app-selfie-mode-widget>
  </div>

  <div class="system-body" *ngIf="manualInput || !showUbkiID">
    <div class="system-layout">
      <form
        [formGroup]="form"
        class="form"
        onkeydown="return event.key != 'Enter';">
        <div class="form-body">
          <div class="form__row">
            <mat-form-field appearance="outline">
              <mat-label class="form__label_required" translate
                >client.person.firstName</mat-label
              >
              <input
                matInput
                formControlName="firstName"
                (focusout)="onChangeFocus('firstName')"
                [maxLength]="60" />
              <mat-error *ngIf="f.firstName.errors?.required" translate
                >validation.required</mat-error
              >
              <mat-error *ngIf="f.firstName.errors?.pattern" translate
                >validation.only_uk_letters</mat-error
              >
            </mat-form-field>
          </div>
          <div class="form__row">
            <mat-form-field appearance="outline">
              <mat-label class="form__label_required" translate
                >client.person.lastName</mat-label
              >
              <input
                matInput
                formControlName="lastName"
                (focusout)="onChangeFocus('lastName')"
                [maxLength]="60" />
              <mat-error *ngIf="f.lastName.errors?.required" translate
                >validation.required</mat-error
              >
              <mat-error *ngIf="f.lastName.errors?.pattern" translate
                >validation.only_uk_letters</mat-error
              >
            </mat-form-field>
          </div>
          <div class="form__row">
            <mat-form-field appearance="outline">
              <mat-label class="form__label_required" translate
                >client.person.middleName</mat-label
              >
              <input
                matInput
                formControlName="middleName"
                (focusout)="onChangeFocus('middleName')"
                [maxLength]="60" />
              <mat-error *ngIf="f.middleName.errors?.required" translate
                >validation.required</mat-error
              >
              <mat-error *ngIf="f.middleName.errors?.pattern" translate
                >validation.only_uk_letters</mat-error
              >
            </mat-form-field>
          </div>
          <div class="form__row position-relative">
            <mat-form-field appearance="outline">
              <mat-label class="form__label_required" translate
                >client.person.inn</mat-label
              >
              <input
                matInput
                formControlName="inn"
                [maxlength]="10"
                mask="0000000000" />
              <mat-error *ngIf="f.inn.errors?.required" translate
                >validation.required
              </mat-error>
              <mat-error
                *ngIf="f.inn.errors?.pattern || f.inn.errors?.invalidInn"
                translate
                >validation.pattern
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form__row">
            <div class="passport-control">
              <div class="passport-control__item position-relative pl-0">
                <mat-checkbox
                  formControlName="isNewPassport"
                  name="isNewPassport"
                  (change)="onDocumentSwitch()">
                  {{ 'client.person.typePassport' | translate }}
                </mat-checkbox>
                <div class="typePassport">
                  <svg
                    class="tooltip-icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="red"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 19H11V17H13V19ZM15.07 11.25L14.17 12.17C13.67 12.68 13.31 13.14 13.13 13.86C13.05 14.18 13 14.54 13 15H11V14.5C11 14.04 11.08 13.6 11.22 13.19C11.42 12.61 11.75 12.09 12.17 11.67L13.41 10.41C13.87 9.97 14.09 9.31 13.96 8.61C13.83 7.89 13.27 7.28 12.57 7.08C11.46 6.77 10.43 7.4 10.1 8.35C9.98 8.72 9.67 9 9.28 9H8.98C8.4 9 8 8.44 8.16 7.88C8.59 6.41 9.84 5.29 11.39 5.05C12.91 4.81 14.36 5.6 15.26 6.85C16.44 8.48 16.09 10.23 15.07 11.25V11.25Z"></path>
                  </svg>
                  <img src="/assets/img/img-passport-tooltip.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div id="oldDocuments" *ngIf="!isNewDocument">
            <div class="form__row">
              <mat-form-field appearance="outline">
                <mat-label class="form__label_required" translate
                  >client.document.fullPassport</mat-label
                >
                <input
                  matInput
                  formControlName="passportNumber"
                  id="passportNumber"
                  [dropSpecialCharacters]="true"
                  [patterns]="customPatterns"
                  style="text-transform: uppercase"
                  mask="LL/NNNNNN"
                  type="text" />
                <mat-error *ngIf="f.passportNumber.errors?.required" translate
                  >validation.required</mat-error
                >
                <mat-error *ngIf="f.passportNumber.errors?.pattern" translate
                  >validation.pattern</mat-error
                >
              </mat-form-field>
            </div>
          </div>

          <div id="newDocuments" *ngIf="isNewDocument">
            <div class="form__row">
              <mat-form-field appearance="outline">
                <mat-label class="form__label_required" translate
                  >client.document.newPassportNumber</mat-label
                >
                <input
                  matInput
                  formControlName="newPassportNumber"
                  [maxlength]="9"
                  mask="0{9}"
                  type="text"
                  id="newPassportNumber" />
                <mat-error
                  *ngIf="f.newPassportNumber.errors?.required"
                  translate
                  >validation.required</mat-error
                >
                <mat-error *ngIf="f.newPassportNumber.errors?.pattern" translate
                  >validation.pattern</mat-error
                >
              </mat-form-field>
            </div>
          </div>

          <div class="form__row">
            <mat-form-field appearance="outline">
              <mat-label
                [hidden]="client.addressData.residence.region && bankIdPassed"
                class="form__label_required"
                translate
                >client.address.region
              </mat-label>
              <mat-select
                [hidden]="client.addressData.residence.region && bankIdPassed"
                name="region"
                formControlName="region">
                <mat-option disabled selected value hidden translate
                  >client.defaultSelect</mat-option
                >
                <ng-container *ngFor="let item of regionList">
                  <mat-option [value]="item.value">
                    <span [translate]="'enums.regions.' + item.name"></span>
                  </mat-option>
                </ng-container>
              </mat-select>
              <input
                [hidden]="!client.addressData.residence.region || !bankIdPassed"
                class="form__input input input-transparent"
                type="text"
                disabled
                [value]="client.addressData.residence.region" />
              <mat-error *ngIf="f.region.errors?.required" translate
                >validation.required</mat-error
              >
            </mat-form-field>
          </div>
          <div
            class="form__row position-relative"
            style="margin-bottom: 20px"
            [ngClass]="{ 'input-invalid': validMailMessage?.email }">
            <mat-form-field appearance="outline">
              <mat-label translate>client.person.email</mat-label>
              <input
                matInput
                formControlName="email"
                type="email"
                (change)="
                  checkCorrectEmail(this.form.value.email, f.email.errors)
                " />
              <mat-error *ngIf="f.email.errors" translate
                >validation.email.incorrect
              </mat-error>
            </mat-form-field>
            <p
              *ngIf="validMailMessage?.email && !f.email.errors?.email"
              style="bottom: -13px"
              class="position-absolute error mat-error"
              translate>
              {{ 'validEmail.message' | translate }}
              <b>{{ validMailMessage?.address }}</b
              ><b class="underline">@{{ validMailMessage?.domain }}</b
              >?
              <span (click)="onReplaceEmail()" class="replace" translate>{{
                'validEmail.btn' | translate
              }}</span>
            </p>
          </div>
        </div>
        <div class="form-footer">
          <button
            type="submit"
            [disabled]="isDisabled"
            (click)="onSubmit()"
            class="btn">
            {{ 'button.next' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-container>
