import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import * as Models from '../../../models/models';

@Component({
  selector: 'app-form-progress',
  templateUrl: './form-progress.component.html',
  styleUrls: ['./form-progress.component.sass']
})

export class FormProgressComponent implements OnInit {

  public progressCount: number = 0;
  public formSteps: Models.IFormProgress = {
    '/form/profile': 1,
    '/form/busyness': 2,
    '/system/scanner': 3,
  };

  constructor(private router: Router) {
  }

  public ngOnInit(): void {
    this.progressCount = this.formSteps[this.router.url];
  }

}
