import { Education } from '../enums/education.enam';
import { BusynessTypeEnum } from '../enums/busyness-type.enum';
import { IncomeSourceEnum } from '../enums/income-source';


export class EmploymentModel {

    public education: Education;
    public busynessType: BusynessTypeEnum;
    public incomeSource: IncomeSourceEnum;
    public monthlyIncome: number;

    constructor(init?: Partial<EmploymentModel>) {
        Object.assign(this, init);
    }

}
