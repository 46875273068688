<div class="page__loader" *ngIf="!isLoaded">
  <app-loader></app-loader>
</div>
<section class="page" *ngIf="isLoaded">
  <app-header></app-header>
  <div class="page__wrap">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</section>
