import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.sass'],
})
export class ButtonComponent {
  @Input()
  public disabled: boolean = false;

  @Input()
  public label: string = 'Button';

  @Input()
  public classes: string = '';

  @Input()
  public type: 'submit' | 'button' | 'reset' = 'button';

  @Input()
  private size: 'large' | 'big' | 'xBig' | 'middle' | 'small' | 'xSmall' =
    'middle';

  @Input()
  public color:
    | 'primary'
    | 'secondary'
    | 'red'
    | 'inline'
    | 'black'
    | 'pure'
    | 'limpid'
    | 'white' = 'limpid';

  @Input() public img: {
    url: string;
    height?: string;
    width?: string;
  };

  @Output()
  public readonly clickEvent = new EventEmitter<Event>();

  public get btnClasses(): string {
    return `ui-btn ui-btn-${this.color} ui-btn-${this.size} ${this.classes}`;
  }
}
