import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {take} from 'rxjs/operators';
import * as Config from '../../../configs/general-config';
import {LoanService} from '../../../services/loan.service';
import {ClientStageEnum} from '../../../enums/client-stage.enum';
import {ClientStore} from '../../../shared/storage/client.store';
import {ICallbackData} from '../../../models/models';
import {ClientModel} from '../../../models/client.model';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.sass'],
  providers: [LoanService],
})
export class CallbackComponent implements OnInit {
  private path = Config.StaticDataConfig.redirectPathByClientStage;
  private systemStages: ClientStageEnum[] = [
    ClientStageEnum.CLIENT,
    ClientStageEnum.CARD,
    ClientStageEnum.APPLICATION,
    ClientStageEnum.LOAN,
    ClientStageEnum.FINISHED_LOAN,
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loanService: LoanService,
    private clientStore: ClientStore
  ) {}

  ngOnInit(): void {
    const code = this.route.snapshot.queryParams.code;
    const state = this.route.snapshot.queryParams.state;
    if (!code || !state) {
      this.comeBackToHome();
      return;
    }
    const callbackData: ICallbackData = {code, state};
    this.loanService
      .postBankIdCallback(callbackData)
      .pipe(take(1))
      .subscribe(
        (client) => {
          this.clientStore.setClient(client);
          this.router.navigate(this.getRedirectUrl(client));
        },
        () => {
          this.clientStore.getClient().subscribe((client: ClientModel) => {
            this.router.navigate(this.getRedirectUrl(client));
          });
        }
      );
  }

  private comeBackToHome(): void {
    this.router.navigate(['/']);
  }

  private getRedirectUrl(client: ClientModel): string[] {
    return this.checkOldUser(client)
      ? ['main', 'loan']
      : ['form', this.path.form[client.stage]];
  }

  private checkOldUser(client: ClientModel): boolean {
    return (
      this.systemStages.findIndex((stage) => stage === client.stage) !== -1
    );
  }
}
