import {NgModule} from '@angular/core';
import {SystemComponent} from './system.component';
import {SystemRoutingModule} from './system-routing.module';
import {BankIdMainModule} from '../bankid/bank-id-main.module';


@NgModule({
  imports: [SystemRoutingModule, BankIdMainModule,],
  declarations: [SystemComponent,]
})
export class SystemModule {
}
