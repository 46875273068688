<ng-container [ngSwitch]="data.group">

  <ng-container *ngSwitchCase="'AVANS'">
    <app-dialog [size]="'medium'" [showCloseBtn]="true">
      <div class="auth-phone-edit" dialogContent>
        <h2 class="auth-phone-edit-header text-center">
          {{ 'AUTH_PHONE_MODAL.TITLE' | translate }}
        </h2>
        <p class="auth-phone-edit-info text-center"
           [innerHTML]="'AUTH_PHONE_MODAL.INFO' | translate : {number: phoneNumber}"
        ></p>
        <form [formGroup]='form' class="form">
          <div class="form-body">
            <div class="form__row">
              <div class="form__field">
                <input formControlName="phone" id="phone" class="form__input input"
                       [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                       placeholder="380" mask="00(000)000-00-00"
                       [minLength]="18"
                       type="tel"
                       #phoneInput
                       autocomplete="on"
                       (keydown)="preventDefaultValueDeletion($event)"
                       (keyup)="inputPhoneNumber($event.target)"
                       (click)="setValuePhone($event.target)">
              </div>
              <div class="form-error" *ngIf="submitted && f.phone.errors">
                <span *ngIf="f.phone.errors.required" translate>validation.required</span>
                <span *ngIf="f.phone.errors.pattern" translate>validation.phone</span>
              </div>
            </div>
          </div>
        </form>
        <div class="auth-phone-edit-footer d-flex flex-column align-items-center">
          <app-button type="button"
                      size="w100"
                      class="w-100"
                      color="primary"
                      classes="fs-15 fw-bold"
                      [label]="'AUTH_PHONE_MODAL.BTN' | translate"
                      (click)="updatePhone()"></app-button>
        </div>
      </div>
    </app-dialog>
  </ng-container>

  <ng-container *ngSwitchCase="'SUNCREDIT'">
    <app-dialog [size]="'medium'" [showCloseBtn]="true">
      <div class="auth-phone-edit" dialogContent>
        <h2 class="auth-phone-edit-header text-center">
          {{ 'AUTH_PHONE_MODAL.TITLE' | translate }}
        </h2>
        <p class="auth-phone-edit-info text-center"
           [innerHTML]="'AUTH_PHONE_MODAL.INFO' | translate : {number: phoneNumber}"
        ></p>
        <form [formGroup]='form' class="form">
          <div class="form-body">
            <div class="form__row">
              <div class="form__field">
                <input formControlName="phone" id="phone" class="form__input input"
                       [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                       placeholder="380" mask="00(000)000-00-00"
                       [minLength]="18"
                       type="tel"
                       #phoneInput
                       autocomplete="on"
                       (keydown)="preventDefaultValueDeletion($event)"
                       (keyup)="inputPhoneNumber($event.target)"
                       (click)="setValuePhone($event.target)">
              </div>
              <div class="form-error" *ngIf="submitted && f.phone.errors">
                <span *ngIf="f.phone.errors.required" translate>validation.required</span>
                <span *ngIf="f.phone.errors.pattern" translate>validation.phone</span>
              </div>
            </div>
          </div>
        </form>
        <div class="auth-phone-edit-footer d-flex flex-column align-items-center">
          <app-button type="button"
                      size="w100"
                      class="w-100"
                      color="primary"
                      classes="fs-18 fw-medium"
                      [label]="'AUTH_PHONE_MODAL.BTN' | translate"
                      (click)="updatePhone()"></app-button>
        </div>
      </div>
    </app-dialog>
  </ng-container>
</ng-container>
