import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import {Observable, Subject} from 'rxjs';
import {GroupType} from '../../../types/group.type';
import {NamesEnum} from '../../../enums/names.enum';

@Component({
  selector: 'app-camera-selfie',
  templateUrl: './camera-selfie.component.html',
  styleUrls: ['./camera-selfie.component.sass'],
})
export class CameraSelfieComponent implements OnInit {
  @Input()
  public group: GroupType;

  @Input()
  public isError: boolean = false;

  @Input()
  public enableSubText!: boolean;

  @Output()
  public pictureTaken: EventEmitter<WebcamImage> =
    new EventEmitter<WebcamImage>();

  @Output()
  public permissionDisabled: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public deviceId: string;
  public showWebcam: boolean = true;
  public allowCameraSwitch: boolean = true;
  public multipleWebcamsAvailable: boolean = false;
  public disabledSelfie: boolean = true;
  public isPermission: boolean = false;
  public videoOptions: MediaTrackConstraints = {};
  public errors: WebcamInitError[] = [];
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();

  public ngOnInit(): void {
    if (this.group === NamesEnum.STARFIN) {
      this.videoOptions = {
        width: {ideal: 512},
        height: {ideal: 512},
        aspectRatio: {min: 1, max: 1},
      };
    }
    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      }
    );
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    if (
      error.mediaStreamError &&
      error.mediaStreamError.name === 'NotAllowedError'
    ) {
      this.permissionDisabled.emit(true);
    }
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
    this.pictureTaken.emit(webcamImage);
  }

  public cameraWasSwitched(deviceId: string): void {
    this.disabledSelfie = false;
    this.isPermission = true;
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
}
