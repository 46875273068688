import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {PlatonPrivatFormComponent} from './platon-privat-form.component';

@NgModule({
  declarations: [PlatonPrivatFormComponent],
  imports: [CommonModule, FormsModule,],
  exports: [PlatonPrivatFormComponent]
})
export class PlatonPrivatFormModule {
}
