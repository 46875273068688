<app-form-progress></app-form-progress>

<ng-container *ngIf="!isLoaded">
  <app-loader></app-loader>
</ng-container>

<div class="system-body" *ngIf="isLoaded">
  <div class="system-layout">
    <form [formGroup]="form" class="form" onkeydown="return event.key != 'Enter';">
      <h2 class="title" translate>client.busyness.title</h2>
      <div class="form-body">
        <div class="form__row">
          <mat-form-field appearance="outline">
            <mat-label class="form__label_required" translate>client.busyness.education</mat-label>
            <mat-select name="education"
                        formControlName="education">
              <ng-container *ngFor="let item of educationList">
                <mat-option disabled selected value hidden>{{'client.defaultSelect' | translate}}</mat-option>
                <mat-option [value]="item.value">
                  <span [translate]="'enums.education.'+ item.name"></span>
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-error *ngIf="f.education.errors?.required" translate>validation.required</mat-error>
          </mat-form-field>
        </div>

        <div class="form__row">
          <mat-form-field appearance="outline">
            <mat-label class="form__label_required" translate>client.busyness.ownershipType</mat-label>
            <mat-select name="ownershipType"
                        formControlName="ownershipType">
              <ng-container *ngFor="let item of ownershipType">
                <mat-option disabled selected value hidden>{{'client.defaultSelect' | translate}}</mat-option>
                <mat-option [value]="item.value">
                  <span [translate]="'enums.housingType.'+ item.name"></span>
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-error *ngIf="f.ownershipType.errors?.required" translate>validation.required</mat-error>
          </mat-form-field>
        </div>

        <div class="form__row">
          <mat-form-field appearance="outline">
            <mat-label class="form__label_required" translate>client.busyness.busynessType</mat-label>
            <mat-select name="busynessType"
                        formControlName="busynessType">
              <ng-container *ngFor="let item of busynessList">
                <mat-option disabled selected value hidden>{{'client.defaultSelect' | translate}}</mat-option>
                <mat-option [value]="item.value">
                  <span [translate]="'enums.busynessType.'+ item.name"></span>
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-error *ngIf="f.busynessType.errors?.required" translate>validation.required</mat-error>
          </mat-form-field>
        </div>

        <div class="form__row">
          <mat-form-field appearance="outline">
            <mat-label class="form__label_required" translate>client.busyness.monthlyIncome</mat-label>
            <input matInput formControlName="monthlyIncome" id="monthlyIncome" [maxLength]="6" mask="0*">
            <mat-error *ngIf="f.monthlyIncome.errors?.required" translate>validation.required</mat-error>
          </mat-form-field>
        </div>

        <div class="form__row position-relative" >
          <mat-form-field appearance="outline">
            <mat-label class="form__label_required" translate>client.busyness.relative_phone</mat-label>
            <input matInput [formControl]="form.controls['relativePhone']" id="relativePhone"
                   placeholder="380" mask="000(00)000-00-00"
                   [minLength]="18"
                   type="tel">
            <mat-error *ngIf="f.relativePhone.errors?.required" translate>validation.required</mat-error>
            <mat-error *ngIf="f.relativePhone.errors?.pattern" translate>validation.phone</mat-error>
          </mat-form-field>
        </div>

      </div>
      <p class="text-center">Все добре! Залишилось додати карту!</p><br>
      <div class="form-footer">
        <button type="submit"
                [disabled]="isDisabled"
                (click)="onSubmit()"
                class="btn">{{ 'application.addCard' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
<app-add-card hidden #addCardComponent></app-add-card>
