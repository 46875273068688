export enum Education {

    MIDDLE_SCHOOL = 'MIDDLE_SCHOOL',
    HIGH_SCHOOL = 'HIGH_SCHOOL',
    SPECIALIZED_HIGH_SCHOOL = 'SPECIALIZED_HIGH_SCHOOL',
    INCOMPLETE_HIGHER_EDUCATION = 'INCOMPLETE_HIGHER_EDUCATION',
    HIGHER_EDUCATION = 'HIGHER_EDUCATION',
    OTHER = 'OTHER'

}
