import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {TranslateModule} from '@ngx-translate/core';
import {SmsCodeWidgetComponent} from "./sms-code-widget.component";

@NgModule({
  declarations: [SmsCodeWidgetComponent,],
  imports: [CommonModule, TranslateModule,],
  exports: [SmsCodeWidgetComponent]
})
export class SmsCodeWidgetModule {
}
