<div class="mobile" [ngClass]="{'mobile_active': active}">
  <ul class="mobile-menu">
    <li class="mobile-menu__item" *ngFor="let item of items"
        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="mobile-menu__link" matRipple [routerLink]="item.route"
         (click)="itemClickEvent.emit()">
        {{ item.label | translate }}
      </a>
    </li>
  </ul>
</div>
