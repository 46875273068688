import {NgModule} from '@angular/core';
import {DialogComponent} from './dialog.component';
import {CommonModule} from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
  declarations: [DialogComponent],
  imports: [CommonModule, MatDialogModule],
  exports: [DialogComponent]
})
export class DialogModule {
}
